import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { ProductService } from 'src/app/_services/product.service';
import { CategoryListRjxService } from 'src/app/_services/rjx/category-list-rjx.service';
import { GeneralSettingRjxService } from 'src/app/_services/rjx/general-setting-rjx.service';
import { SettingService } from 'src/app/_services/setting.service';
import { Utils } from 'src/app/_shared/Uitls';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit{

  currency:any = currency

  productFeatured:any = []
  productBestSeller:any = []

  //lodade
  productBestSellerLodade:boolean = false
  categoryList: any = [];
  dynamicHtml: SafeHtml | undefined;
  min_order: any = "499";
  slider: any = [];
  hideBlog:boolean = false


  constructor(
    private productService:ProductService,
    private categoryListRjx:CategoryListRjxService,
    private generalSetting:GeneralSettingRjxService,
    private settingService:SettingService,
    private sanitizer: DomSanitizer,
  ){}


  ngOnInit(): void {
    this.getSlider()
    this.generalSetting.generalSetting.subscribe((data:any) => {
      this.dynamicHtml = this.sanitizer.bypassSecurityTrustHtml(data?.siteInfo);
    })
    this.generalSetting.settingsData.subscribe((data:any) => {
      if(data!=null){
        data.forEach((element:any) => {
          if(element?.var=="min_order"){
            this.min_order = element?.value
          }
        });
      }
    })
    this.categoryListRjx.categoryList.subscribe((data:any) => {
      //console.log("dafjhakhfjhajhsfjkahs ",data);

      this.categoryList = [];

      data.forEach((item:any,i:any) => {
        // if(item?.subSubCategories.data.length>1){
          item?.subSubCategories.data.forEach((element:any) => {
            this.categoryList.push({
              locationId:i,
              id:element.id,
              name:element.name,
              total_products:element.total_products,
              link:element.links.products,
              navigate:"/"+this.slug_modify(item.name)+"/"+this.slug_modify(element?.name)+'-'+element?.id})
          });
        // }else{
          this.categoryList.push({
            locationId:i,
            id:item.id,name:item.name,
            link:item.links.products,
            total_products:item.total_products,
            navigate:"/"+this.slug_modify(item.name)+"-"+item.id})
        // }
      });
      this.scrollToTop()

    })

    this.productService.getProducts('products/featured',null).subscribe((data:any) => {
      this.productFeatured = data?.data

    })
    this.productService.getProducts('products/best-seller',null).subscribe((data:any) => {
      this.productBestSeller = data?.data
      this.productBestSellerLodade = true

    })

  }
  getSlider(){
    this.settingService.getSlider().subscribe((data:any) => {
      this.slider = data?.data
    })
  }
  filterBannerLink(link:any){
    if(link.indexOf('http')==0) return {isLink:true};
    return this.queryToJson(link);

  }
  queryToJson(query:any):any{
    var keyValuePairs:any = query.split("&");
    var jsonObject:any = {};
    keyValuePairs.forEach(function(pair:any) {
      var parts = pair.split("=");
      jsonObject[parts[0]] = parts[1];
    });
    jsonObject['isLink'] = false;
    return JSON.parse(JSON.stringify(jsonObject))
  }
  productImage(url:any){
		return ImageUrlService.getImageUrl(url);
	}
  slug_modify(text:any){
    return Utils.slug_modify(text);
  }
  scrollToTop() {
    if (typeof window !== 'undefined'){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
	}
  getSliderLocation(data:any,key:any = 'footer'){
    if(data?.location!=undefined){
      if(data?.location==key){
        return true;
      }
    }
    return false;
  }


}
