<mat-nav-list>
    <h2 class="ml-3">Sort By</h2>
    <mat-divider></mat-divider>
    <mat-selection-list #option [multiple]="false" (selectionChange)="openLink($event)">
        <ng-container *ngFor="let item of sortType">
            <mat-list-option [value]="item?.key" [selected]="item?.key==selectedSortType">{{item?.value}}</mat-list-option>
        </ng-container>
      </mat-selection-list>
      
      
  </mat-nav-list>
  