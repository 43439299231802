<div>
  <mat-card class="mb-2 p-3">
    <mat-card-title class="c-p"
      (click)="(isAddresedFromClosed==true)? isAddresedFromClosed=false:isAddresedFromClosed=true">
      <div class="d-flex">
        <span>ADD A NEW ADDRESS</span> 
        <ion-icon *ngIf="isAddresedFromClosed" class="mt-1 ml-3" name="add-circle"></ion-icon>
        <ion-icon *ngIf="!isAddresedFromClosed" class="mt-1 ml-3" name="arrow-up"></ion-icon>
      </div>
    </mat-card-title>
    <mat-card-content class="mt-3" [@fadeInOut]="isAddresedFromClosed==false ? 'up' : 'down'" *ngIf="isAddresedFromClosed==false">
      <app-address-form [showForm]="true" (close)="closeAddressForm($event)" (data)="getAddressData($event)"></app-address-form>
    </mat-card-content>
  </mat-card>
  <mat-card >
    <mat-card-content *ngIf="isDataLoaded">
      <div *ngIf="addedItems.length === 0">
        <img src="/assets/no_data_found.gif" width="auto" height="auto" class="n-d-f">
      </div>
    </mat-card-content >
    <mat-card-content *ngIf="!isDataLoaded">
      <div >
        <mat-card-content>
          <div class=" d-f mx-3 my-3">
            <div class="w-100">
              <h4 class="m-0 p-0 skeleton skeleton-dim"> <span ></span></h4>
              <p class="m-0 p-0 mt-1 "><span class="skeleton skeleton-dim"></span> &nbsp; <span class="skeleton skeleton-dim"></span></p>
    
              <address class="m-0 p-0 skeleton skeleton-address"></address>
            </div>
            <div>
    
              <span class="skeleton">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
          </div>
        </mat-card-content>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngFor="let item of addedItems" class="custom-card">
    <mat-card-content>
      <div class="d-f mx-3 my-3">
        <div class="w-100">
          <h4 class="m-0 p-0"> <span class="badge bg-light">HOME</span></h4>
          <p class="m-0 p-0 mt-1">{{item?.name}} <span>{{item?.phone}}</span></p>

          <address class="m-0 p-0">b{{item?.address}} {{item?.address2}}, {{item?.state}}, - {{item?.postal_code}} {{item?.country}}</address>
        </div>
        <div>

          <button mat-button [matMenuTriggerFor]="menu"><mat-icon class="c-p">more_vert</mat-icon></button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="EditAddressDilog(item)"><mat-icon>edit</mat-icon> Edit</button>
            <button mat-menu-item (click)="deleteAddress(item)"><mat-icon>delete_outline</mat-icon> delete</button>
          </mat-menu>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
