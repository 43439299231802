import { Component } from '@angular/core';

@Component({
  selector: 'ion-icon',
  templateUrl: './ionicon.component.html',
  styleUrls: ['./ionicon.component.css']
})
export class IoniconComponent {

}
