 <!--
    - NOTIFICATION TOAST
  -->
    <ng-container *ngIf="productData?.name">

    <div class="notification-toast"  [class.notification-toast-hide]="!showNotifications"  >
      <button class="toast-close-btn" data-toast-close (click)="close()">
        <mat-icon class="mat-18">close</mat-icon>
      </button>
      <div class="toast-detail">

        <p class="toast-message">
          Someone in new just bought
        </p>

        <a class="toast-title" [routerLink]="['/products/'+productData?.slug]" routerLinkActive="router-link-active">
          {{productData?.name}}
        </a>

        <p class="toast-meta">
          <time datetime="PT2M">{{timeAgo(productData?.time)}}</time> ago
        </p>

      </div>
    </div>
  </ng-container>
<div class="notification-toast" data-toast *ngIf="!closeModal">

  <button class="toast-close-btn" data-toast-close (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="toast-banner">
    <img src="./assets/images/products/jewellery-1.jpg" alt="Rose Gold Earrings" width="80" height="70">
  </div>

  <div class="toast-detail">

    <p class="toast-message">
      Someone in new just bought
    </p>

    <p class="toast-title">
      Rose Gold Earrings
    </p>

    <p class="toast-meta">
      <time datetime="PT2M">2 Minutes</time> ago
    </p>

  </div>

</div>
