import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from 'src/app/_services/cart.service';
import { currency } from 'src/app/_services/constant';
import { OrderService } from 'src/app/_services/order.service';
import { StorageService } from 'src/app/_services/storage.service';
import { WindowRefService } from 'src/app/_services/window-ref.service';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { Utils } from 'src/app/_shared/Uitls';
import Swal from 'sweetalert2';
import { CartRjxService } from 'src/app/_services/rjx/cart.service';
import { RzpService } from 'src/app/_services/rzp.service';
import { UserService } from 'src/app/_services/user.service';
import { CurrentUserRjxService } from 'src/app/_services/rjx/current-user-rjx.service';
import { SettingService } from 'src/app/_services/setting.service';
import { ProcessingComponent } from '../loader/processing/processing.component';
import { GeneralSettingRjxService } from 'src/app/_services/rjx/general-setting-rjx.service';
import { PromocodeActionService } from 'src/app/_services/rjx/promocode-action.service';

@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.css'],
})
export class PriceDetailsComponent implements OnInit, OnChanges {
  
  currency: any = currency;
  @Input('option') option: any;
  totalPrice: number = 0;
  totalDiscountPrice: number = 0;
  isProgress:any = 0;
  itemCount: any = 0;
  Deliverycharge: number = 0;
  orderReview: boolean = true;
  @Output() validation: EventEmitter<any> = new EventEmitter<any>();

  @Input('selectedAddress') selectedAddress: any = [];
  @Input('totalWlletBalance') totalWlletBalance: any = -1;
  @Input('isProductPresent') isProductPresent: boolean = false;
  @Input('isPlaceOrderValid') isPlaceOrderValid: boolean = false;
  @Output('disableSelection') disableSelection: EventEmitter<boolean> =
    new EventEmitter<boolean>(false);

  addedItems: any = [];
  @Input('isPlaceHoderOrReviewOrder') isPlaceHoderOrReviewOrder: boolean = true; //true for placehoser and false for to review the order
  placeOrderObj: any = {};
  paymenttype: any = '';
  orderId: any = '';
  coupanCode: any = "";
  ApplyCouponError:any = {};
  pid: any;
  OrderRzpId: any;
  rzpOrderAmount: any;
  currentUser: any;
  payNow: any = "Pay Now ";
  rzpOrderId: any;
  addressStatus: any = true;
  $processionDilog: any;
  promoceAppling: number = 0;
  min_order: any;

  constructor(
    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    public storage: StorageService,
    private router: Router,
    private settingCounter:SettingService,
    private cartCountRjx:CartRjxService,
    private cartService: CartService,
    private order: OrderService,
    private rzpService: RzpService,
    private userService:UserService,
    private currentUserRjx:CurrentUserRjxService,
    private generalSetting:GeneralSettingRjxService,
    private promocodeActionService:PromocodeActionService,
    public dialog: MatDialog,
    private windowRefService: WindowRefService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if(!this.isProductPresent){
      this.removePromocode()
    }
    if(this.isPlaceHoderOrReviewOrder){
      this.applyPromocodeFromLocal();
    }
  }
  ngOnInit(): void {
    this.currentUserRjx.refresh.subscribe((data) =>{
      if(data!=null){
        if(data==true){
          this.getUser()
        }
      }
    })
    this.generalSetting.settingsData.subscribe((data:any) => {
      if(data!=null){
        data.forEach((element:any) => {
          if(element?.var=="min_order"){
            this.min_order = element?.value
          }
        });
      }
    })
    this.currentUserRjx.user.subscribe((data:any) => {
      this.currentUser = data;
    })
    var promocode = this.storage.getPrmocode();
    if(promocode?.coupon_discount){
      this.ApplyCouponError = this.storage.getPrmocode()
      this.coupanCode = this.ApplyCouponError?.code
      this.isProgress = 2;
      
    }else{
      this.ApplyCouponError.coupon_discount = 0;
    }
    this.promocodeActionService.promocodeUpdate.subscribe((data:any) => {
      if(data!=null){
        if(data?.reset){
          this.removePromocode()
        }
      }
    })
  }
  
  processing(){
    this.$processionDilog = this.dialog.open(ProcessingComponent, {
      maxWidth: '100vw',
      data:{
        title:"PROCESSING YOUR payment"
      }
    });
  }

  placeOrder() {
    // if (this.option.selectedOptions.hasValue()) {
    //   this.paymenttype = this.option.selectedOptions.selected[0].value;
    // }
    if (this.option!="") {
      this.paymenttype = this.option
    }
    this.disableSelection.emit(true);
    if (this.paymenttype == 'cod' || this.paymenttype == 'wallet') {
      this.processing()
      this.isPlaceOrderValid = false;
      this.cartService.sendOtp().subscribe((data: any) => {
        var dialogRef = this.dialog.open(OtpVerificationComponent, {
          disableClose: true,
          data: {
            title:
              'OTP se sent to over ' +
              Utils.maskEmail(this.storage.getUser()?.email),
            data: this.storage.getUser(),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.isPlaceOrderValid = true;
          if (result?.type == 'verified') {
            this.placeOrderObj = {
              shippingAddress: this.selectedAddress,
              paymentType: this.paymenttype,
              paymentStatus: 'unpaid',
              grandTotal: this.totalPrice,
              couponDiscount: 0,
              orderFrom: 'web',
              notes: null,
              couponCode: '',
            };
            this.order.placeOrder(this.placeOrderObj).subscribe((data: any) => {
              if(data?.success==false){
                Swal.fire({
                  icon: "error",
                  title: data?.message,
                  showConfirmButton: true,
                });
              }else{
                this.orderId = data?.order_id;
                this.afterSuccessPayment()
              }
            });
          }
        });
      });
    }else{

      var createRzpOrder = {
        "amount": (this.totalPrice-this.ApplyCouponError.coupon_discount)*100,
        "currency": "INR",
        "receipt": "testing APIS",
        "notes": {
          "order_from": "website or web",
          "name": this.currentUser?.name,
          "email": this.currentUser?.email,
        }
      }
      this.rzpService.createRzpOrder(createRzpOrder).subscribe((data:any) => {
        this.payNow = "Processing your order "
        this.isPlaceOrderValid = false;
        this.OrderRzpId = JSON.parse(data?.rzp_order_details)?.id
        this.rzpOrderAmount = JSON.parse(data?.rzp_order_details)?.amount
        this.rzpOrderId = data?.id;

        this.makeOrder(data?.id);
      })
    }
  }
  makeOrder(rzp_order_id:any = null) {
    this.payNow = "Createing your order "
    this.processing();
    this.placeOrderObj = {
      shippingAddress: this.selectedAddress,
      paymentType: this.paymenttype,
      paymentStatus: 'unpaid',
      grandTotal: this.totalPrice,
      orderFrom: 'web',
      rzp_order_id: rzp_order_id,
      notes: null,
      couponDiscount: this.ApplyCouponError.coupon_discount,
      couponCode: this.ApplyCouponError?.code,
    };
    this.order.placeOrder(this.placeOrderObj).subscribe((data: any) => {
      this.orderId = data?.order_id;
      this.storage.removePrmocode()
      this.goToPaymentPage();
    },(error=>{
      this.$processionDilog.close()
    }));
  }
  goToPaymentPage() {
    this.payNow = "Payment processing "
    var $this = this;

    var options = {
      key: Utils.rzpKey(), // Enter the Key ID generated from the Dashboard
      amount: this.rzpOrderAmount,
      currency: 'INR',
      description: 'Maniyar Bangles',
      order_id: this.OrderRzpId,
      image: 'https://s3.amazonaws.com/rzp-mobile/images/rzp.jpg',
      notes: {
        "order_id": this.orderId
      },
      prefill: {
        email: this.currentUser?.email,
        contact: this.selectedAddress?.phone,
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: 'All payment methods',
              instruments: [
                {
                  method: this.option,
                },
              ],
            },
          },
          sequence: ['block.banks'],
          preferences: {
            show_default_blocks:
              this.option== 'upi' || this.option== 'cod'
                ? true
                : false,
          },
        },
      },

      handler: function (response: { razorpay_payment_id: any }) {
        $this.afterSuccessPayment(response.razorpay_payment_id);
      },
      modal: {
        ondismiss: function () {
          Swal.fire({
            title: "We regret to inform you that we need to cancel your order. Please hold while we process the cancellation.",
            width: 600,
            padding: "3em",
            color: "#716add",
            allowOutsideClick:false,
            showConfirmButton:false,
            background: "#fff url(/assets/trees.png)",
            backdrop: `
              rgba(0,0,123,0.4)
              url("/assets/nyan-cat.gif")
              left top
              no-repeat
            `
          });
          $this.order.refreshLastOrder($this.orderId).subscribe((data1:any) => {
            Swal.close()
            $this.router.navigate(['order-confirmation',$this.orderId])
            $this.$processionDilog.close()

            
          })
        },
      },
    };
    let rzp = new this.windowRefService.nativeWindow.Razorpay(options);
    rzp.open();
  }
  gotoPromocode(){
    this.scrollToTop()
    this.router.navigate(['/account/my-coupan/list'],{ queryParams: { from: 'cart' } })
  }
  afterSuccessPayment(razorpay_payment_id: any = null) {
    this.payNow = "Payment done of "
    var dataToServcer = {
      id:this.orderId,
      paymentStatus:"paid",
      rzpOrderId:this.rzpOrderId,
      paymentDetails:JSON.stringify({
        rzp_payment_id:razorpay_payment_id
      }),
    }
    this.order.updatePaymentStatus(dataToServcer).subscribe((data: any) => {
      if(this.storage.isLoggedIn()){
        this.settingCounter.counter().subscribe((data:any) => {
          this.cartCountRjx.setCartCount(data?.cartItem)
        })
      }
      this.$processionDilog.close()
      this.router.navigate(['order-confirmation/' + this.orderId]);
      
    });
    // alert(razorpay_payment_id)
  }

  gotoReviewPage() {
    
    this.storage.addAddress(this.selectedAddress);
    this.router.navigate(['/order-review']);
  }
  public setAddress(getSelectedAddress: any) {
    this.selectedAddress = getSelectedAddress;
  }
  setItemCount(itemCount: any) {
    this.itemCount = itemCount;
  }
  setTotalPrice(tp: any) {
    this.totalPrice = tp;
  }
  setTotalDiscountPrice(tp: any) {
    this.totalDiscountPrice = tp;
  }
  setDeliverycharge(tp: any) {
    this.Deliverycharge = tp;
  }
  setOrderReview(tp: any) {
    this.orderReview = tp;
    
  }
  setAddedItems(tp: any) {
    this.addedItems = tp;
    
  }
  setProductIds(pid:any) {
    this.pid = pid;
    this.applyPromocodeFromLocal();
  }
  applyPromocodeFromLocal() {
    if(localStorage.getItem("promocodeCopied")!=undefined){
      this.promoceAppling = 1;
    }
    setTimeout(() => {
      if(localStorage.getItem("promocodeCopied")!=undefined){
      this.promoceAppling = 0;
        this.coupanCode =  localStorage.getItem("promocodeCopied");
        this.ApplyCoupon()
        // localStorage.removeItem("promocodeCopied")
      }
    }, 2000);
  }

  createOrder() {
    const data = {
      shippingAddress: this.selectedAddress,
      paymentType: this.option,
      paymentStatus: 'paid',
      grandTotal: this.totalPrice + this.Deliverycharge-this.ApplyCouponError.coupon_discount,
      deliveryCharge: this.Deliverycharge,
      couponDiscount: this.ApplyCouponError.coupon_discount,
      notes: 'string',
      couponCode: this.ApplyCouponError?.code,
    };
    this.order.placeOrder(data).subscribe((data: any) => {
      //console.log(data);
    });
  }
  remove(){
    // this.ApplyCouponError = {
    //   coupons_id:1,
    // }
    Swal.fire({
      title: "Are you sure want to remove ?",
      confirmButtonText: "Yes",
      showCancelButton:true,
      icon: "warning",
    }).then((result) => {
      if(result?.isConfirmed){
        this.removePromocode()
        localStorage.removeItem("promocodeCopied")
      }
    });
  }
  removePromocode(){
    if(this.ApplyCouponError?.coupons_id){
      this.order.removePromocode(this.ApplyCouponError.coupons_id).subscribe((data:any) => {
          this.ApplyCouponError = {}
          this.ApplyCouponError.coupon_discount = 0;
          this.coupanCode =  "";
          this.isProgress = 0;
          this.storage.removePrmocode()
      })
    }else{
      this.ApplyCouponError = {}
      this.ApplyCouponError.coupon_discount = 0;
      this.coupanCode =  "";
      this.isProgress = 0;
      this.storage.removePrmocode()
    }
  }
  ApplyCoupon(){
    if(this.coupanCode.length>3){
      this.isProgress = 1
      this.order.promocode(this.coupanCode,btoa(this.pid),(this.totalPrice+this.Deliverycharge)).subscribe((data:any) => {
        this.isProgress = 0;
        if(data?.coupon_code_is_valid==false){
          this.ApplyCouponError = {
            error:true,
            message:"Minimum amount should be "+data?.coupon_details?.min_buy
          }
          this.ApplyCouponError.coupon_discount = 0;
          // this.removePromocode();
          localStorage.removeItem("promocodeCopied")

        }else if(data?.coupon_code_is_valid==true){
          this.isProgress = 2;
          this.ApplyCouponError = {
            error:false,
            code:this.coupanCode,
            coupon_discount:data?.coupon_discount,
            coupons_id:data?.coupons_id,
            totalDiscount:data?.discount+""+data?.discount_type,
            discount_type:data?.discount_type,
            message:"Promocode successfully applied "
          }
          if(this.ApplyCouponError?.coupons_id){
            this.storage.setPromocde(this.ApplyCouponError)
          }
        }else{
          this.ApplyCouponError = data;
          this.ApplyCouponError.coupon_discount = 0;

        }
      }, (error:any) => {
        this.isProgress = 0;
        
        this.ApplyCouponError = {
          error:error?.error?.status,
          message:error?.error?.message
        }
        this.ApplyCouponError.coupon_discount = 0;

      })
    }else{
      this.ApplyCouponError = {
        message:"Enter valid coupon",
        error:true
      };
    }
  }

  scrollToTop() {
    // const targetDiv = this.elementRef.nativeElement.querySelector('.addressSelection');

    // targetDiv.classList.add('shake-div');
    this.validation.emit({"status":this.addressStatus})
    this.addressStatus = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add custom class to the div
  }
  getUser(){
    this.userService.getCurrentUser().subscribe((data:any) => {
      
      this.currentUserRjx.setUser(data.data[0])
      
    })
  }
}
