<div class="container">
  <div class="row my-3">
    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <mat-vertical-stepper [linear]="true" #stepper [animationDuration]="steperDuration">
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Confirm delivery address</ng-template>
            <mat-card-content *ngIf="getSelectedAddress!=null">
              <div class="w-100 d-f">
                <div>
                  <h4 class="m-0 p-0">
                    <span class="badge bg-light">{{getSelectedAddress?.work_type || 'Home'}}</span>
                  </h4>
                  <p class="m-0 p-0 mt-1">
                    {{getSelectedAddress?.name}} <span>{{getSelectedAddress?.phone}}</span>
                  </p>

                  <address class="m-0 p-0">
                    {{ getSelectedAddress.address }} {{ getSelectedAddress.address2 }}
                    , {{ getSelectedAddress.city }} ,
                    {{ getSelectedAddress.state }} - {{ getSelectedAddress.country }}
                  </address>
                </div>
              </div>
            </mat-card-content>
            <div>
              <button mat-button matStepperNext *ngIf="getSelectedAddress!=null"
                (click)="isOptional = !isOptional">Done</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [editable]="isOptional">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Review products</ng-template>
            <mat-card *ngFor="let cartItem of addedItems" class="custom-card">
              <mat-card-content>
                <div class="row row-phone">
                  <div class="col-md-2 img-div">
                    <img [src]="getImage(cartItem?.product.thumbnail_image)" width="150px" height="auto" />
                  </div>
                  <div class="col-md-10 contant-div pr-1">
                    <div class="d-flex">
                      <div class="mt-1 w-full p-details p-4">
                        <h3 class="text-info text-wrap m-0 p-0">
                          {{cartItem?.product?.name}}
                        </h3>
                        <P class="text-danger m-0 p-0 pr-3" *ngIf="cartItem?.qty<1">Out Of Stock</P>
                        <P class="text-danger m-0 p-0 pr-3">{{cartItem?.variant}}</P>
                        <div class="price-box d-flex mt-1">
                          <p class="price">{{currency}}{{cartItem?.product?.base_discounted_price}}</p>
                          <del class="ml-1">{{currency}}{{cartItem?.product?.base_price}}</del>
                        </div>
                        <P class="text-danger m-0 p-0 pr-3">Quantiy: {{cartItem?.qty}}</P>
                        <!-- <h4>Rs.{{cartItem?.product?.base_discounted_price}}/-</h4> -->
                      </div>
                      <div class="quantity-container mt-2">
                        <button class="quantity-btn d-none">
                          <mat-icon class="icon">cancel</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <div>
              <button mat-button matStepperPrevious (click)="scrollToTop()">Back</button>
              <button mat-button matStepperNext (click)="scrollToTop()">Done</button>
            </div>
          </form>
        </mat-step>
        <mat-step [editable]="isOptional">
          <ng-template matStepLabel>Payment option</ng-template>

          <div class="category mt-3">
            <div class="row">
              <div class="col-sm-4" *ngFor="let item of paymentOption">
                <label for="AMEX" class="amexMethod" (click)="selectPayment(item.value)">
                  <div class="imgName">
                    <div class="imgContainer AMEX">
                      <img [src]="item?.logo" alt="">
                    </div>
                    <span class="name">{{ item.name }}</span>
                  </div>
                  <span class="check" *ngIf="selectdOptions==item?.value && selectdOptions!='wallet'"><mat-icon>check</mat-icon></span>
                  <span class="check" *ngIf="selectdOptions==item?.value && selectdOptions=='wallet' && totalWlletBalance>=0"><mat-icon>check</mat-icon></span>
                  <span class="check" *ngIf="selectdOptions==item?.value && selectdOptions=='wallet' && totalWlletBalance<1"><mat-spinner diameter="24"></mat-spinner></span>
                </label>
              </div>
            </div>
          </div>
          <div class="box" *ngIf="selectdOptions=='wallet' && totalWlletBalance>=0">
            <p class="text-success"><mat-icon>check</mat-icon></p>
            <div>
                <p>Mb Pay</p>
                <p>{{currency}} {{totalWlletBalance}}</p>
            </div>
        </div>
          <mat-selection-list #option [multiple]="false" class="d-none" (selectionChange)="paymentOptionChnage($event)">
            <mat-list-option *ngFor="let item of paymentOption" [value]="item.value" style="font-size: 16px;">
              {{ item.name }}<br>
            </mat-list-option>
          </mat-selection-list>
        </mat-step>
      </mat-vertical-stepper>
    </div>

    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 ">

      <app-price-details #priceDetails [totalWlletBalance]="totalWlletBalance" [option]="selectdOptions" (disableSelection)="disableSelection($event)"
        [isPlaceHoderOrReviewOrder]="true" [isPlaceOrderValid]="selectdOptions!=''"></app-price-details>

    </div>
  </div>
</div>