import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { addressRjx } from './address-rjx.service';

@Injectable({
  providedIn: 'root'
})
export class ProductFilterRjxService {

  private messageSource = new BehaviorSubject<any>(null);
  filter = this.messageSource.asObservable();

  constructor() { }

  setFilter(filter: any) {
    this.messageSource.next(filter);
  }
  
}
