import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingRjxService {

  private generalSettingSub = new BehaviorSubject<any>(null);
  private generalSettingSeo = new BehaviorSubject<any>(null);
  private businessSettings = new BehaviorSubject<any>(null);
  private settings = new BehaviorSubject<any>(null);
  generalSetting = this.generalSettingSub.asObservable();
  seo = this.generalSettingSeo.asObservable();
  businessSettingsData = this.businessSettings.asObservable();
  settingsData = this.settings.asObservable();

  constructor() { }

  setGeneralSetting(list: any) {
    this.generalSettingSub.next(list);
  }
  setSeo(list: any) {
    this.generalSettingSeo.next(list);
  }
  setBusinessSettings(list: any) {
    this.businessSettings.next(list);
  }
  setSettings(list: any) {
    this.settings.next(list);
  }
}
