import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { GeneralSettingRjxService } from 'src/app/_services/rjx/general-setting-rjx.service';
import { Common } from 'src/app/_shared/Common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  description: any = "";
  keyword: any = "";
  constructor(private titleService: Title,
     private metaService: Meta,
     private generalSetting:GeneralSettingRjxService,
     private common:Common,
     ) { }

  ngOnInit(): void {
    this.common.SitemapInit()
    // Dynamically set title
    this.generalSetting.seo.subscribe((data:any) => {
      if(data!=null){
        this.description = data[0]?.description
        this.keyword = data[0]?.keyword
        this.titleService.setTitle('Get Quality Handmade Bangle Designs At Very Affordable Prices');

        // Dynamically set meta tags
        this.metaService.addTag({ name: 'description', content: this.description });
        this.metaService.addTag({ name: 'keywords', content: this.keyword });
      }
    })

  }
}
