import { Component, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/_services/cart.service';
import { ProductService } from 'src/app/_services/product.service';
import { StorageService } from 'src/app/_services/storage.service';
import Swal from 'sweetalert2';
import { SnackbarLayoutComponent } from '../../snackbar-layout/snackbar-layout.component';
import { currency } from 'src/app/_services/constant';
import { Variant } from '../../product-box/product-view/product-view.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipOption } from '@angular/material/chips';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { Router } from '@angular/router';
import { CartRjxService } from 'src/app/_services/rjx/cart.service';
import { Title, Meta } from '@angular/platform-browser';
import { OrderService } from 'src/app/_services/order.service';
import { SettingService } from 'src/app/_services/setting.service';
import { JsonLdService } from 'src/app/_services/rjx/json-ld.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent {

  currency = currency
  sizeListForm!:FormGroup
  size: any = [];
  colors: any = [];
  selectedsize: any;
  variant: Variant | undefined;
  validData: boolean = false;
  isOutOfStock: number = -1;
  progress: boolean = false;
  alreadyAdded: boolean = false;
  // sizeListData = new FormControl("")

  checkDeliveryLocation!: FormGroup

  @Output() dataEvent = new EventEmitter<any>(undefined);
  @Output() closeDilogEvent = new EventEmitter<boolean>(false);

  price: any = "";
  @Input("isDeliveryCheck") isDeliveryCheck: boolean = false;
  @Input("close") close: boolean = false;
  @Input("data") data:any;
  @ViewChild("chipSizeAttr", { static: false }) chipSizeAttr: MatChipOption | undefined
  isImageLoaded: boolean = false;
  selectedOptions: any;

  myThumbnail = "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  myFullresImage = "https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";
  producstImage: any = [
    "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg",
    "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg",
    "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg"
  ]
  totalCartItem: number = 0;
  lastOrderNotProcessed: boolean = false;
  postUrl:any;
  postTitle:any;
  postImg:any;
  deliveryMessage: any = null;
  deliveryLocationProgress: boolean = false;

  constructor(
    private storage: StorageService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private cartService:CartService,
    private settingCounter:SettingService,
    private orderService:OrderService,
    private cartCount:CartRjxService,
    private _snackBar: MatSnackBar,
    private titleService: Title,
     private metaService: Meta,
    private router:Router,
    private jsonLdService: JsonLdService, private renderer: Renderer2
  ) {
  }

  goto(arg0: string) {
    this.router.navigate([arg0])
    this.closeDilog()
    this.scrollToTop()
  }
  closeDilog(){
    this.closeDilogEvent.emit(true)
  }

  cartCounter(){
    if(this.storage.isLoggedIn()){
      this.settingCounter.counter().subscribe((data:any) => {
        this.cartCount.setCartCount(data?.cartItem)
      })
    }
  }
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  ngOnInit(): void {

    this.cartCount.cart.subscribe((data:any) => {
      if(data!=null){
        this.totalCartItem = data
      }
    })

    this.size = JSON.parse(this.data.data?.choice_options)[0]?.values
    this.colors = JSON.parse(this.data.data?.colors)
    this.price = this.data.data.base_discounted_price


    this.checkAvailableQty(this.size[0])

    this.checkDeliveryLocation = this.formBuilder.group({
      pincode: [''] // Define your form control(s) here
    });

    this.myThumbnail=ImageUrlService.getImageUrl(this.data.data?.thumbnail_image);
    this.myFullresImage=this.myThumbnail;

    this.producstImage = this.data.data?.photos.map((url: string) => ImageUrlService.getImageUrl(url));

    this.titleService.setTitle(this.data.data?.seo?.meta_title);
    this.postTitle = this.data.data?.seo?.meta_title
    if (typeof window !== 'undefined') {
      this.postUrl = window.location.origin+"/products/"+this.data.data?.slug
    }


    // Dynamically set meta tags
    this.metaService.addTag({ name: 'description', content: this.data.data?.seo?.meta_description });
    this.metaService.addTag({ name: 'keywords', content: this.data.data?.seo?.tags });
    this.metaService.addTag({ property: 'og:image', content: ImageUrlService.getImageUrl(this.data.data?.seo?.meta_img) }); // Add meta tag for image
    this.postImg = ImageUrlService.getImageUrl(this.data.data?.seo?.meta_img);

    this.sizeListForm = this.formBuilder.group({
      sizeListData: [''] // You can initialize with a default value if needed
    });
    this.sizeListForm.patchValue({
      sizeListData: this.size[0] // Set your desired value here
    });

    const productJsonLd = this.jsonLdService.getProductJsonLd(this.postUrl,this.data,this.postImg,this.data.data?.seo?.meta_description);
    this.addJsonLdToHead(productJsonLd);
  }

  private addJsonLdToHead(jsonLdData: any): void {
    if (typeof window !== 'undefined') {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(jsonLdData);
      this.renderer.appendChild(document.head, script);
    }

  }


  socalShare(type:any){

  }


  deliveryLocationLength: any;

  deliveryLocation(event: any) {
    this.deliveryLocationLength = event.target.value.length;
    this.deliveryLocationProgress = true;
    if (this.deliveryLocationLength >= 6) {
      this.orderService.deliveryPinstatus(event.target.value).subscribe((data:any) => {
        this.deliveryMessage = data?.message
        this.deliveryLocationProgress = false;
      },(error:any) => {
        this.deliveryLocationProgress = false;

        this.deliveryMessage = "Pin code should be 6 digits and not start with 0"

      })
    }else{
      this.deliveryMessage = null
      this.deliveryLocationProgress = false;

    }
  }

  onImageLoad(evt: any) {
    if (evt && evt.target) {
      const width = evt.target.naturalWidth;
      const height = evt.target.naturalHeight;
      const portrait = height > width ? true : false;
      this.isImageLoaded = true;
    } else {
      this.isImageLoaded = false;
    }
  }

  getImage(data: any) {
    this.myThumbnail = data;
  }

  patchFooBar(event: any): void {
    if(event?.source){
      const option = event?.source?.value;
      if (event.source.selected) {
        this.checkAvailableQty(option);
      }
    }
  }
  checkAvailableQty(option: any) {
    this.selectedOptions = option;
    var res = {
      "id": this.data.data.id,
      "color": this.colors[0],
      "choice": [{
        "name": option
      }]
    };
    this.validData = false;
    this.progress = false;
    this.dataEvent.emit({progress:false})
    // this.pro
    this.productService.variantPrice(res).subscribe((data: Variant) => {
      this.variant = data;
      if (data?.qty > 0) {
        this.validData = true;
        this.isOutOfStock = data?.qty;
        this.price = data?.price
      } else {
        this.isOutOfStock = 0;
      }
      this.progress = true;
      this.dataEvent.emit({progress:true})
      this.checkAlreadyAdded()
    }, (error: any) => {
      this.progress = true;
      this.dataEvent.emit({progress:true})

    })
  }


  checkAlreadyAdded(){
    const obj = { id: this.variant?.product_id, variant: this.variant?.variant,qty:1,product:this.data.data };
    if (this.storage.checkCartIsExist(obj)) {
      this.validData = false;
      this.alreadyAdded = true;
    }else{
      this.alreadyAdded = false;
    }
  }
  checkPrevioursOrder(obj:any,action:any){
    this.validData = false;
    this.orderService.checkLastOrder().subscribe((data:any) => {
      if(data.length>0){
        this.lastOrderNotProcessed = true;

        Swal.fire({
          title: "Your last order under processed",
          confirmButtonText: "Refresh previous",
          showCancelButton:true,
          cancelButtonText:"Wait / cancel",
          showDenyButton:true,
          denyButtonText:"View order status",
          denyButtonColor:"#FFAA01",
          icon: "warning",
        }).then((result) => {
          if(result?.isDenied){
            this.router.navigate(['order-confirmation',data[0]?.orderId])
          }
          if(result?.isConfirmed){
            Swal.fire({
              title: "Hold on we are checking your order status and payment status",
              width: 600,
              padding: "3em",
              color: "#716add",
              allowOutsideClick:false,
              showConfirmButton:false,
              background: "#fff url(/assets/trees.png)",
              backdrop: `
                rgba(0,0,123,0.4)
                url("/assets/nyan-cat.gif")
                left top
                no-repeat
              `
            });
            setTimeout(() => {
              Swal.close()
            }, 2000);
            this.orderService.refreshLastOrder(data[0]?.orderId).subscribe((data1:any) => {
              this.router.navigate(['order-confirmation',data[0]?.orderId])

            })
          }

        });
      }else{
        this.addCartToServer(obj,action);
      }
    })
  }
  addCart(action:any = 'buy') {
    this.processToAddCart(action);
  }
  addCartToServer(obj:any,action:any){
    var data = {
      "productId": obj?.id,
      "variant": obj.variant,
      "qty": obj.qty
    }
    this.progress = false;
    this.dataEvent.emit({progress:false})
    this.cartService.addTocartCateorys(data).subscribe((data:any) => {
    this.validData = true;
      this.progress = true;
      this.dataEvent.emit({progress:true})
      if(data?.limitedQtyUser==true){
        Swal.fire({
          title: data?.message,
          confirmButtonText: "Ok",
          icon: "warning",
        }).then((result) => {

        });
      }else{
        if(data?.status==true){
          this.snackBar("Product addedd to cart successfully !","success")
          if(action=="buy"){
            this.router.navigate(['/cart'])
          }
          if(data?.already==false){
            this.cartCount.setCartCount(++this.totalCartItem)
          }
        }else{
          Swal.fire({
            title: data?.message,
            confirmButtonText: "Ok",
            icon: "error",
          }).then((result) => {

          });
        }
      }

      if (this.variant) {
        this.decreaseQty(this.variant, 1);
        if(this.variant?.qty==0){
          this.validData = false;
        }
      }

    })
  }
  processToAddCart(action:any) {
    const obj = { id: this.variant?.product_id, variant: this.variant?.variant,qty:1 ,product:this.data.data};
    if (!obj.id || !obj.variant) {
      console.error('Product ID or variant is missing.');
      return;
    }

    if (this.storage.isLoggedIn()) {
      //TODO:: get data from server # do not delete this comment
      this.checkPrevioursOrder(obj,action);

    } else {
      if (!this.storage.checkCartIsExist(obj)) {
        if (this.variant) {
          this.storage.addCart(obj);
          this.decreaseQty(this.variant, 1);
          this.snackBar("Product addedd to cart successfully !","success")
          if(action=="buy"){
            this.router.navigate(['/cart'])
          }
          this.cartCount.setCartCount(++this.totalCartItem)
        } else {
          console.error('Variant is missing.');
        }
      } else {
        if (this.variant) {
          if(this.variant?.qty==0){
            this.validData = false;
            this.snackBar("Product quantity not available !","warning")
          }else{
            this.decreaseQty(this.variant, 1);
            this.storage.updateQty(obj)
            this.snackBar("Product update to cart successfully !","success")
            if(action=="buy"){
              this.router.navigate(['/cart'])
            }

          }

        } else {
          console.error('Variant is missing.');
        }

      }
    }
  }

  decreaseQty(variant: Variant, amount: number): void {
    if (variant && variant.qty && variant.qty >= amount) {
      variant.qty -= amount;
    } else {
    }

  }
  isSelected(s: any) {
    return s === this.selectedOptions;
  }
  snackBar(message: any, status: any) {
		this._snackBar.openFromComponent(SnackbarLayoutComponent, {
			duration: 3 * 1000,
			data: {
				message: message,
				status: status
			}
		});
	}





}
