<div *ngIf="skeleton">
    <!-- skeleton -->
    <div class=" w-100">
        <div class="showcase skeleton" *ngFor="let item of skeletonData" >
    
            <a href="#" class="showcase-img-box skeleton-p " style="width: 60px; height: 60px;">
                
            </a>

            <div class="showcase-content">

                <a href="#">
                    <h4 class="showcase-title skeleton-p w-100" style="height: 20px"></h4>
                </a>

                <a href="#" class="showcase-category skeleton-p" style="width: 200px; height: 20px;"></a>

                <div class="price-box skeleton-p" style="width: 20px; height: 10px;">
                    
                </div>

            </div>

        </div>
    </div>
    <div class=" d-none showcase-wrapper  has-scrollbar skeleton">
        <div class="showcase-container ">
            <div class="showcase">
                <a href="#" class="showcase-img-box skeleton">
                    
                </a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!skeleton">
    <!-- skeleton non -->
    <h2 class="title">{{title}}</h2>

    <div class="showcase-wrapper  has-scrollbar">
    
        <div class="showcase-container">
    
            <div class="showcase" *ngFor="let item of product">
    
                <a [routerLink]="['/products',item?.slug]" (click)="scrollToTop()" class="showcase-img-box">
                    <img [src]="productImage(item?.thumbnail_image)" alt="{{item?.name}}"
                        class="showcase-img" width="70" height="auto">
                </a>
    
                <div class="showcase-content">
    
                    <a [routerLink]="['/products',item?.slug]" (click)="scrollToTop()" routerLinkActive="router-link-active" >
                        <h4 class="showcase-title">{{item?.name}}</h4>
                    </a>
    
                    <a [routerLink]="[item?.categoryUrl]" (click)="scrollToTop()"  routerLinkActive="router-link-active" class="showcase-category c-p">{{item?.mainCategory}}</a>
    
                    <div class="price-box">
                        <p class="price">{{currency}}{{item?.base_discounted_price}} &nbsp;<del>{{currency}}{{item?.base_price}}</del></p>
                        
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
</div>
