import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDynamicAspectRatio]'
})
export class DynamicAspectRatioDirective {
  private aspectRatio: string | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('load')
  onLoad() {
    const img: HTMLImageElement = this.el.nativeElement;
    const srcset = img?.srcset;
    if(srcset){
      // Extract the last image URL with the maximum width (3840w)
      const srcsetArray = srcset.split(',').map(entry => entry.trim());
      // console.log("fsdhfsjhdfkhsdlkhfksjhdkfjsd " , srcset);
    }

    const aspectRatio = img.naturalWidth / img.naturalHeight;
    // this.setAspectRatioClass(aspectRatio.toString())
    img.style.aspectRatio = aspectRatio.toString();
    // this.renderer.setStyle(img, 'aspect-ratio', aspectRatio);
    // this.renderer.addClass(img, 'dynamic-aspect-ratio');
  }

  @HostListener('error')
  onError() {
    // Fallback logic in case of error
  }

  private setAspectRatioClass(aspectRatio: any) {
    let number = Math.random();
    let numberString = number.toString();
    let integerPartString = numberString.split('.')[1]; // Gets the part after the decimal point
    let integerPart = parseInt(integerPartString, 10); // Converts it to an integer
    // Create a unique class name
    const className = `aspect-ratio-${integerPart}`;

    // Add class to the element
    this.renderer.addClass(this.el.nativeElement, className);

    // Apply style for this class
    const style = document.createElement('style');
    style.textContent = `
      .${className} {
        aspect-ratio: ${aspectRatio};
      }
    `;
    document.head.appendChild(style);
  }
}
