import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { Version } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class RzpService {
  
  service: string = "rzp/";
  constructor(private http: HttpClient,
    private storage:StorageService,
    ) { }

  createRzpOrder(body:any = null){
    return this.http.post<any>(environment.apiUrl + Version.V2 + this.service+"create-order",body);
  }
}
