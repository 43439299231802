<div class="container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="header-search-container">

                    <div class="input-group mt-2">
                        <button class="search-btn" *ngIf="searchValue.value.length<1">
                            <mat-icon class="search-icon">search</mat-icon>
                        </button>

                        <button class="search-btn" *ngIf="searchValue.value.length>1">
                            <mat-icon class="search-icon" (click)="crossbtn()">clear</mat-icon>
                        </button>


                        <div class="form-floating w-100">
                            <input type="search" noWhiteSpace [(ngModel)]="searchTrm" #searchValue name="search"
                                (keyup)="searchKeyUp($event)" autocomplete="off" (keyup.enter)="search($event)"
                                class="search-field"
                                placeholder="Enter order id.">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="noTrackOrderFound" class="no-order">
              <img src="/assets/images/svg/track-your-order.svg" class="w-50"/>
            </div>
            <div class="col-sm-12"  *ngIf="isSkeleton && orderTrackDetails?.id==null">
                <div class="skeleton w-100 mt-3 skeleton-cart-head" style="height: 40px;"></div>
                <div class=" pb-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <table class="details-table table table-borderless">
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>

                            </table>
                        </div>
                        <div class="col-lg-6">
                            <table class="details-table table table-borderless">
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="skeleton w-100 mt-3 skeleton-cart-head" style="height: 40px;"></div>
                <div class=" pb-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <table class="details-table table table-borderless">
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>

                            </table>
                        </div>
                        <div class="col-lg-6">
                            <table class="details-table table table-borderless">
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                                <tr >
                                    <td colspan="2">
                                        <p class="skeleton td-h w-100"></p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-sm-12" *ngIf="orderTrackDetails?.id">

                <div class="card mt-4">
                    <div class="card-header py-2 px-3 heading-6 strong-600 clearfix">
                        <div class="float-left">Order Summary</div>
                    </div>
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="details-table table">
                                    <tr>
                                        <td class="w-50 strong-600">Order Code:</td>
                                        <td>{{orderTrackDetails?.code}}</td>
                                    </tr>
                                    <tr>
                                        <td class="w-50 strong-600">Customer:</td>
                                        <td>{{getShippingAddress(orderTrackDetails?.shipping_address)?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="w-50 strong-600">Email:</td>
                                        <td><a href="javascript:void(0)" >{{orderTrackDetails?.user?.email}}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="w-50 strong-600">Shipping address:</td>
                                        <td>{{getShippingAddress(orderTrackDetails?.shipping_address)?.address}}, {{getShippingAddress(orderTrackDetails?.shipping_address)?.city}}, {{getShippingAddress(orderTrackDetails?.shipping_address)?.country}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-lg-6">
                                <table class="details-table table">
                                    <tr>
                                        <td class="w-50 strong-600">Order date:</td>
                                        <td>{{orderTrackDetails?.created_at}}</td>
                                    </tr>
                                    <tr>
                                        <td class="w-50 strong-600">Total order amount:</td>
                                        <td>{{currancy}} {{orderTrackDetails?.grand_total}}</td>
                                    </tr>
                                    <tr>
                                        <td class="w-50 strong-600">Shipping method:</td>
                                        <td>Flat shipping rate</td>
                                    </tr>
                                    <tr>
                                        <td class="w-50 strong-600">Payment method:</td>
                                        <td>{{orderTrackDetails?.payment_type}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card mt-4" *ngFor="let item of orderTrackDetails?.order_details">
                    <div class="card-header py-2 px-3 heading-6 strong-600 clearfix">
                        <ul class="process-steps clearfix" *ngIf="item?.delivery_status!='cancel'">
                            <li style="width: 25% !important; " [ngClass]="getClassForDeliveryStatus(item?.delivery_status,'pending')">
                                <div class="icon " [ngClass]="{'active_icon': getClassForDeliveryStatusIcon(item?.delivery_status,['pending','on_review','on_delivery','delivered'])}">1</div>
                                <div class="title border-bottom-0 m-1 p-1">Order placed</div>
                            </li>
                            <li style="width: 25% !important;" [ngClass]="getClassForDeliveryStatus(item?.delivery_status,'on_review')">
                                <div class="icon " [ngClass]="{'active_icon': getClassForDeliveryStatusIcon(item?.delivery_status,['on_review','on_delivery','delivered'])}">2</div>
                                <div class="title border-bottom-0 m-1 p-1">On review</div>
                            </li>
                            <li style="width: 25% !important;" [ngClass]="getClassForDeliveryStatus(item?.delivery_status,'on_delivery')">
                                <div class="icon " [ngClass]="{'active_icon': getClassForDeliveryStatusIcon(item?.delivery_status,['on_delivery','delivered'])}">3</div>
                                <div class="title border-bottom-0 m-1 p-1">On delivery</div>
                            </li>
                            <li style="width: 25% !important;" [ngClass]="getClassForDeliveryStatus(item?.delivery_status,'delivered')">
                                <div class="icon " [ngClass]="{'active_icon': getClassForDeliveryStatusIcon(item?.delivery_status,['delivered'])}">4</div>
                                <div class="title border-bottom-0 m-1 p-1">Delivered</div>
                            </li>
                        </ul>
                        <ul class="process-steps process-steps-cancel clearfix" *ngIf="item?.delivery_status=='cancel'">
                            <li style="width: 25% !important; " class="done" >
                                <div class="icon active_icon_cancel">1</div>
                                <div class="title border-bottom-0 m-1 p-1">Order placed</div>
                            </li>
                            <li style="width: 25% !important;" class="done">
                                <div class="icon active_icon_cancel">2</div>
                                <div class="title border-bottom-0 m-1 p-1">Cancel</div>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body p-4">
                        <div class="col-6">
                            <table class="details-table table table-borderless">
                                <tr>
                                    <td class="w-50 strong-600">Product Name:</td>
                                    <td>{{item?.product?.name}}</td>
                                </tr>
                                <tr>
                                    <td class="w-50 strong-600">Quantity:</td>
                                    <td>{{item?.quantity}}</td>
                                </tr>
                                <tr>
                                    <td class="w-50 strong-600">Shipped By:</td>
                                    <td>{{getShippingAddress(orderTrackDetails?.shipping_address)?.name}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
        </div>












    </div>
</div>
