import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { CommonRxjsService } from './common-rxjs.service';

export enum Version {
  V1 = "v1/",
  V2 = "v2/"
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {



  version: any = Version.V1;
  imageUrl: string = "";
  constructor(private http: HttpClient,
    private storage:StorageService,
    private rxjs:CommonRxjsService,
    ) { }


    getFilterQuery(dataToFilter:any){
      var filter = "";
      if(dataToFilter!=null){
        filter = "?color="+dataToFilter.color+"&"+dataToFilter.price+"&sort="+dataToFilter.sortType
        
      }
      return filter;
    }
  getProducts(route:any,nextPage:any = 1,dataToFilter:any = null): Observable<any> {
    
    if(nextPage==null){
      return this.http.get<any>(environment.apiUrl + this.version + route+this.getFilterQuery(dataToFilter));
    }else{
      return this.http.get<any>(environment.apiUrl + this.version + route+"/"+nextPage+this.getFilterQuery(dataToFilter));
    }
  }
  getProductByUrl(route:any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.version + route);
  }
  getProductsPaginate(route:any,dataToFilter:any = null) {
    
    return this.http.get<any>(environment.apiUrl + this.version + route+this.getFilterQuery(dataToFilter));
  }
  getProductBySlug(slug:any) {
    return this.http.post<any>(environment.apiUrl + Version.V2 + "products/by-slug/"+slug,null);
  }
  searchProduct(data:any) {
    return this.http.post<any>(environment.apiUrl + Version.V2 + "products/search",data);
  }
  getProductByCatrgory(catId:any) {
    return this.http.get<any>(environment.apiUrl + Version.V1 + "products/category/"+catId);
  }
  todaysDealNg(){
    return this.http.get<any>(environment.apiUrl + this.version + "products/todays-deal");
  }
  flashDeal(){
    return this.http.get<any>(environment.apiUrl + this.version + "products/flash-deal");
  }
  variantPrice(body:any){
    return this.http.post<any>(environment.apiUrl + Version.V2 + "products/variant/price",body);
  }







  wishlistsAdd(productId:any){
    return this.http.post<any>(environment.apiUrl + Version.V2 + "wishlists/store/"+productId,null);
  }
  wishlistsDelete(wishlistId:any){
    return this.http.delete<any>(environment.apiUrl + Version.V2 + "wishlists/delete/"+wishlistId)
  }
  wishlistsDeleteByProducts(wishlistId: any) {
    this.http.delete<any>(environment.apiUrl + Version.V2 + "wishlists/wishlists-delete/"+wishlistId).subscribe((data:any) => {

    })
  }
  wishlistsGet(){
    return this.http.post<any>(environment.apiUrl + Version.V2 + "wishlists/get",null).subscribe((data:any) => {
      this.storage.addWishList(data.data)
      this.rxjs.setWichListCount(data.data.length)
    })
  }
  loadImage(url:any) {
    // const url = 'https://example.com/path/to/your/image.jpg'; // Replace this with your actual image URL
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Convert the blob to a base64 string and set it as the image source
        this.imageUrl = reader.result as string;
      };
      reader.readAsDataURL(data);
    });
    return this.imageUrl;
  }
  imageProcessing(formData:any){
    return this.http.post<any>(environment.apiUrl + Version.V1 + "image-processing/1",formData,{ observe: 'events', reportProgress: true });
  }


}
