<div class="  ">
  <!-- ACCOUNT SETTING -->
  <div style="color: black">
    <mat-accordion *ngIf="isLogin">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
        <mat-expansion-panel-header>
          <mat-panel-description style="color: black">
            <mat-icon>shopping_cart</mat-icon>
            <h3 class="mx-2 mt-3">My orders</h3>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <h4>
            <div
              mat-menu-item
              class="menu-btn"
              (click)="Goto('/account/profile/order/order-list/1',0,0)"
            >
              <mat-icon
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 0 ? true : false) : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 0 ? true : false) : false
                }"
              >
                All order
              </button>
            </div>
            <div
              mat-menu-item
              class="menu-btn"
              (click)="Goto('/account/profile/order/pending-order-list/1',0,1)"
            >
              <mat-icon
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 1 ? true : false) : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 1 ? true : false) : false
                }"
              >
                Pending order
              </button>
            </div>
            <div
              mat-menu-item
              class="menu-btn"
              (click)="Goto('/account/profile/order/complete-order-list/1',0,2)"
            >
              <mat-icon
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 2 ? true : false) : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 2 ? true : false) : false
                }"
              >
                Complete order
              </button>
            </div>
            <div
              mat-menu-item
              class="menu-btn"
              (click)="Goto('/account/profile/order/cancel-order-list/1',0,3)"
            >
              <mat-icon
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 3 ? true : false) : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 3 ? true : false) : false
                }"
              >
                Cancel order
              </button>
            </div>
            <div
              mat-menu-item
              class="menu-btn"
              (click)="Goto('/account/profile/order/on-delivery-order-list/1',0,4)"
            >
              <mat-icon
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 4 ? true : false) : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active: subStep >= 0 ? (subStep == 4 ? true : false) : false
                }"
              >
                On-delivery order
              </button>
            </div>
          </h4>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion *ngIf="isLogin">
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
        <mat-expansion-panel-header>
          <mat-panel-description style="color: black">
            <mat-icon>perm_identity</mat-icon>
            <h3 class="mx-2 mt-3">ACCOUNT SETTING</h3>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <h4>
            <div mat-menu-item class="menu-btn" (click)="Goto('/account/profile/details',1,0)">
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 0 && step === 1
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 0 && step === 1
                        ? true
                        : false
                      : false
                }"
              >
                Profile Information
              </button>
            </div>
            <div
              mat-menu-item
              class="menu-btn"
              (click)="Goto('/account/profile/address',1,1)"
            >
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 1
                      ? subStep == 1 && step === 1
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active:
                    subStep >= 1
                      ? subStep == 1 && step === 1
                        ? true
                        : false
                      : false
                }"
              >
                Manage Addresses
              </button>
            </div>
            <div
              mat-menu-item
              class="menu-btn d-none"
              (click)="Goto('/account/profile/address',1,2)"
            >
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 2
                      ? subStep == 2 && step === 1
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active:
                    subStep >= 2
                      ? subStep == 2 && step === 1
                        ? true
                        : false
                      : false
                }"
              >
                PAN Card Information
              </button>
            </div>
          </h4>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion *ngIf="isLogin" >
      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
        <mat-expansion-panel-header>
          <mat-panel-description style="color: black">
            <mat-icon>payment</mat-icon>
            <h3 class="mx-2 mt-3">Wallet</h3>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-nav-list class="d-none">
          <h4>
            <button mat-menu-item>MB Pay</button>
            <button mat-menu-item>MB Wallet</button>
          </h4>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
        <mat-expansion-panel-header>
          <mat-panel-description style="color: black">
            <mat-icon>folder</mat-icon>
            <h3 class="mx-2 mt-3">MY STUFF</h3>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <h4>
            <div mat-menu-item class="menu-btn" (click)="Goto('/account/my-coupan/list',3,0)" *ngIf="isLogin">
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 0 && step === 3
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                routerLink="/account/my-coupan/list"
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 0 && step === 3
                        ? true
                        : false
                      : false
                }"
              >
                My Coupan
              </button>
            </div>
            <div mat-menu-item class="menu-btn d-none" *ngIf="isLogin">
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 1 && step === 3
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                routerLink="/account/profile/wishlist"
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 1 && step === 3
                        ? true
                        : false
                      : false
                }"
              >
                My Review & Rating
              </button>
            </div>
            <div mat-menu-item class="menu-btn d-none" *ngIf="isLogin" >
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 2 && step === 3
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                routerLink="/account/profile/wishlist"
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 2 && step === 3
                        ? true
                        : false
                      : false
                }"
              >
                All Notification
              </button>
            </div>
            <div mat-menu-item class="menu-btn" (click)="Goto('/account/profile/wishlist',3,3)">
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 3 && step === 3
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 3 && step === 3
                        ? true
                        : false
                      : false
                }"
              >
                My Wishlist
              </button>
            </div>
            <div mat-menu-item class="menu-btn" *ngIf="isLogin" (click)="Goto('/account/profile/saveforlatter',3,4)">
              <mat-icon
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 4 && step === 3
                        ? true
                        : false
                      : false
                }"
                >keyboard_arrow_right</mat-icon
              >
              <button
                [ngClass]="{
                  active:
                    subStep >= 0
                      ? subStep == 4 && step === 3
                        ? true
                        : false
                      : false
                }"
              >
                Save For Latter
              </button>
            </div>
          </h4>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
