import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-croper',
  templateUrl: './image-croper.component.html',
  styleUrls: ['./image-croper.component.css']
})
export class ImageCroperComponent {
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl  = '';
    imageBase64: string|undefined;
    @Output() output = new EventEmitter<any>();
  img: Blob | null | undefined;
  base64Image: any;


  
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImageCroperComponent>,

  ) {
    this.imageBase64 = data?.image
  }

  fileChangeEvent(event: Event): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.img = event?.blob
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    // Convert Blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(this.img!);
    reader.onloadend = () => {
      this.base64Image = reader.result;
    };
  }
  cropImage(){
    this.dialogRef.close({
      image:this.base64Image
    })
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  // convertToBase64() {
  //   this.fetchBlob("this.croppedImage").then(blob => {
  //     this.blobToBase64(blob).then(base64 => {
  //       this.base64Image = base64;
  //       //console.log('Base64 Image:', this.base64Image);
  //     });
  //   });
  // }

  // fetchBlob(url: string): Promise<Blob> {
  //   return fetch(url).then(response => response.blob());
  // }

  // blobToBase64(blob: Blob): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       resolve(reader.result as string);
  //     };
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // }
}
