<!-- Navbar-->
<header class="header">
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container">
      <!-- Navbar Brand -->
      <a routerLink="/" class="navbar-brand">
        <img src="/assets/images/logo/logo.webp" alt="logo" width="150">
      </a>
    </div>
  </nav>
</header>


<div class="container">
  <div class="row py-5  align-items-center">
    <div class="col-md-5 ">
      <img src="/assets/images/svg/sign-up-form.svg" alt="" style="margin-top: -27%;"
        class="img-fluid mb-3 d-none d-md-block">
      <h1>Welcome to Maniyar Bangles </h1>
      <p class="text-muted mb-1">Unlock the world of exquisite, hand-crafted bangles by creating your
        account today. It's quick, easy, and gives you access to:</p>
      <ul class="text-deco">
        <li><span class="font-weight-bold">Exclusive Designs:</span> Be the first to explore our latest collections.</li>
        <li><span class="font-weight-bold">Personalized Recommendations:</span> Get suggestions tailored to your unique style. </li>
        <li><span class="font-weight-bold">Order Tracking:</span> Keep an eye on your orders from purchase to delivery.</li>
        <li><span class="font-weight-bold">Seamless Checkout:</span> Save your details for faster purchases.</li>
        <li><span class="font-weight-bold">Special Offers:</span> Enjoy early access to sales and promotions.</li>
      </ul>
    </div>

    <!-- Registeration Form -->
    <div class="col-md-7 col-lg-6 ml-auto">
      <form action="#" [formGroup]="signUpForm">
        <div class="row">
          <!-- First Name -->
          <div class="input-group col-lg-6 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>First Name</mat-label>
              <input matInput id="firstName" type="text" formControlName="firstFormControl" autocomplete="off" noSpace
                tbSingleSpace name="firstname" placeholder="First Name">
              <mat-icon matPrefix>person_pin</mat-icon>
              <mat-error *ngIf="signUpForm.get('firstFormControl').hasError('firstFormControl')">
                Please enter your first name.
              </mat-error>
              <mat-error
                *ngIf="signUpForm.get('firstFormControl').hasError('required') && signUpForm.get('firstFormControl').touched">
                First name is required
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Last Name -->
          <div class="input-group col-lg-6 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Last Name</mat-label>
              <input matInput id="lastName" type="text" autocomplete="off" formControlName="lastFormControl" noSpace
                tbSingleSpace name="lastname" placeholder="Last Name">
              <mat-icon matPrefix>person_pin</mat-icon>
              <mat-error *ngIf="signUpForm.get('lastFormControl').hasError('lastFormControl')">
                Please enter your last name.
              </mat-error>
              <mat-error
                *ngIf="signUpForm.get('lastFormControl').hasError('required') && signUpForm.get('lastFormControl').touched">
                Last is required
              </mat-error>
            </mat-form-field>

          </div>

          <!-- Email Address -->
          <div class="col-lg-12 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput id="email" autocomplete="off" formControlName="email" noSpace tbSingleSpace type="email"
                name="email" placeholder="Email Address">
              <mat-icon matPrefix>email</mat-icon>
              <mat-error *ngIf="signUpForm.get('email').hasError('email')">
                Please enter valid email.
              </mat-error>
              <mat-error *ngIf="signUpForm.get('email').hasError('required') && signUpForm.get('email').touched">
                Email is required
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Phone Number -->
          <div class="input-group col-lg-12 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Phone Number</mat-label>
              <span matPrefix>&nbsp;&nbsp; +91 &nbsp;</span>
              <input matInput id="phoneNumber" type="text" name="phone" placeholder="Phone Number"
                [autocomplete]="false" formControlName="phoneFormControl" noSpace #phoneNumber tbOnlyNumber
                tbSingleSpace maxlength="10"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
              <!-- <mat-hint align="end">{{phoneNumber.value.length}} / 10</mat-hint> -->
              <mat-error
                *ngIf="signUpForm.get('phoneFormControl').hasError('required') && signUpForm.get('phoneFormControl').touched">
                Phone number is required
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Password -->
          <div class="col-lg-6 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Password</mat-label>
              <input matInput id="password" type="password" name="password" placeholder="Password" autocomplete="off"
                formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" noSpace tbSingleSpace>
              <mat-icon matPrefix>lock</mat-icon>
              <mat-icon matSuffix class="c-p" (click)="togglePasswordVisibility()"
                *ngIf="!isPasswordVisible">visibility_off</mat-icon>
              <mat-icon matSuffix class="c-p" (click)="togglePasswordVisibility()"
                *ngIf="isPasswordVisible">visibility</mat-icon>
              <mat-error *ngIf="signUpForm.get('password').hasError('required') && signUpForm.get('password').touched">
                Password is required
              </mat-error>
              <mat-error *ngIf="signUpForm.get('password').errors?.minlength">
                Password must be at least 7 characters.
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Password Confirmation -->
          <div class=" col-lg-6 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Confirm Password</mat-label>
              <input matInput id="passwordConfirmation" type="text" name="passwordConfirmation" autocomplete="off"
                [type]="isPasswordCnfVisible ? 'text' : 'password'" formControlName="passwordConfirmation" noSpace
                tbSingleSpace placeholder="Confirm Password">
              <mat-icon matPrefix>lock</mat-icon>
              <mat-icon matSuffix class="c-p" (click)="togglePasswordCnfVisibility()"
                *ngIf="!isPasswordCnfVisible">visibility_off</mat-icon>
              <mat-icon matSuffix class="c-p" (click)="togglePasswordCnfVisibility()"
                *ngIf="isPasswordCnfVisible">visibility</mat-icon>
              <mat-error
                *ngIf="signUpForm.get('passwordConfirmation').hasError('required') && signUpForm.get('passwordConfirmation').touched">
                Confirm Password is required.
              </mat-error>
              <mat-error
                *ngIf="signUpForm.hasError('passwordMismatch') && signUpForm.get('passwordConfirmation').touched">
                Passwords do not match.
              </mat-error>
            </mat-form-field>

          </div>

          <!-- Submit Button -->
          <div class="form-group col-lg-12 mx-auto mb-0">
            <button class="btn btn-primary btn-block p-2" *ngIf="!signupBtnDisabled" (click)="onSignUp()"
              [disabled]="!signUpForm.valid">
              <span class="font-weight-bold text-light">Sign Up</span>
            </button>
            <button class="btn btn-primary btn-block p-2" *ngIf="signupBtnDisabled" [disabled]="signupBtnDisabled">
              <mat-spinner [diameter]="20" *ngIf="signupBtnDisabled"></mat-spinner></button>
          </div>

          <!-- Divider Text -->
          <div class="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
            <div class="border-bottom w-100 ml-5"></div>
            <span class="px-2 small text-muted font-weight-bold text-muted">OR</span>
            <div class="border-bottom w-100 mr-5"></div>
          </div>

          <!-- Social Login -->
          <div class="row w-100 mx-auto social-login">
            <!-- <div class="col-lg-6 col-md-12 col-sm-12 mb-1">
                <button class="btn btn-primary btn-block btn-facebook p-3">
                    <i class="fa fa-facebook"></i>
                    <span class="font-weight-bold">Continue with Facebook</span>
                </button>
            </div> -->
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button class="btn btn-block">
                <asl-google-signin-button type="standard" size="large"></asl-google-signin-button>
              </button>
            </div>
          </div>

          <!-- Already Registered -->
          <div class="text-center w-100 mt-2">
            <p class="text-muted font-weight-bold b-tag">Already Registered? <button (click)="gotoSignUp()"
                class="text-primary ml-2">Login</button></p>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>