<footer class="position">

  <div class="footer-category">

    <div class="container">

      <h2 class="footer-category-title">Category directory</h2>
      <div class="footer-category-box">

        <h3 class="category-box-title">All Category :</h3>
        <ng-container *ngFor="let sub of catList">
          <a [routerLink]="[sub.url]" routerLinkActive="router-link-active"  class="footer-category-link" >{{sub.name}}</a>
        </ng-container>

      </div>

      <div class="footer-category-box" *ngFor="let item of categoryList">

        <ng-container *ngIf="item?.subSubCategories?.data.length>0">
          <h3 class="category-box-title">{{item.name}} :</h3>
          <ng-container *ngFor="let sub of item?.subSubCategories?.data">
            <a [routerLink]="['/',slug_modify(item.name),slug_modify(item?.name)+'-'+sub?.id]" routerLinkActive="router-link-active"  class="footer-category-link" >{{sub?.name}}</a>
          </ng-container>
        </ng-container>

      </div>

    </div>

  </div>



  <div class="footer-nav">

    <div class="container">

      <ul class="footer-nav-list">

        <li class="footer-nav-item">
          <h2 class="nav-title">CONTACT INFO</h2>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['About-us']" (click)="scrollToTop()" routerLinkActive="router-link-active" class="footer-nav-link">About Us</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['Contact-us']" (click)="scrollToTop()" routerLinkActive="router-link-active"  class="footer-nav-link">Contact Us</a>
        </li>

        <li class="footer-nav-item d-none">
          <a href="https://play.google.com/store/apps/details?id=com.maniyarbangles.app"  target="_blank" class="footer-nav-link play-store">
            <img src="/assets/images/icons/google-play-badge.svg" alt="Maniyar Bangles app">
          </a>
        </li>

      </ul>

      <ul class="footer-nav-list">

        <li class="footer-nav-item">
          <h2 class="nav-title">POLICY</h2>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['privacypolicy']" (click)="scrollToTop()" routerLinkActive="router-link-active"  class="footer-nav-link">Privacy Policy</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['terms']" (click)="scrollToTop()" routerLinkActive="router-link-active"  class="footer-nav-link">Terms</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['sellerpolicy']" (click)="scrollToTop()" routerLinkActive="router-link-active"  class="footer-nav-link">Seller Policy</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['supportpolicy']" (click)="scrollToTop()" routerLinkActive="router-link-active"  class="footer-nav-link">Support Policy</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['returnpolicy']" (click)="scrollToTop()" routerLinkActive="router-link-active"   class="footer-nav-link">Return Policy</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['withdrawal-policy']" (click)="scrollToTop()" routerLinkActive="router-link-active" class="footer-nav-link">Withdrawal Policy</a>
        </li>

      </ul>

      <ul class="footer-nav-list">

        <li class="footer-nav-item">
          <h2 class="nav-title">MY ACCOUNT</h2>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['login']" (click)="scrollToTop()" routerLinkActive="router-link-active"  *ngIf="!isLogin" class="footer-nav-link">Login</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['/account/profile/order/order-list/1']" (click)="scrollToTop()" routerLinkActive="router-link-active"  *ngIf="isLogin" class="footer-nav-link">Order History</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['account/profile/wishlist']" (click)="scrollToTop()" routerLinkActive="router-link-active"  *ngIf="isLogin" class="footer-nav-link">My Wishlist</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['track-your-order']" (click)="scrollToTop()" routerLinkActive="router-link-active"  class="footer-nav-link">Track Order</a>
        </li>

        <li class="footer-nav-item">
          <a [routerLink]="['account/profile/details']" (click)="scrollToTop()" routerLinkActive="router-link-active"  *ngIf="isLogin" class="footer-nav-link">My Profile</a>
        </li>

      </ul>

      <ul class="footer-nav-list">

        <li class="footer-nav-item">
          <h2 class="nav-title">Follow Us</h2>
        </li>

        <li>
          <ul class="social-link">

            <li class="footer-nav-item">

                <a href="https://www.facebook.com/barketali.maniyar/" class="facebook footer-nav-link" target="_blank"  data-original-title="Facebook">
                  <ion-icon name="logo-facebook"></ion-icon>
                </a>

            </li>

            <li class="footer-nav-item">
              <a href="https://www.linkedin.com/company/maniyar-bangles" target="_blank" class="footer-nav-link">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>
            </li>

            <li class="footer-nav-item">
              <a href="https://www.instagram.com/maniyar__bangles" target="_blank" class="footer-nav-link">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

          </ul>
        </li>

      </ul>

    </div>

  </div>

  <div class="footer-bottom">

    <div class="container">
      <!-- TODO:Will work on payment payment options -->
      <!-- <div class="d-flex f-payement-options d-none">
        <img src="/assets/images/payment/mastercard.png" alt=" mastercard payment method">
        <img src="/assets/images/payment/cod.png" alt="cod payment method">
        <img src="/assets/images/payment/netbanking.png" alt="pnetbanking ayment method">
        <img src="/assets/images/payment/upi.png" alt="upi payment method">
        <img src="/assets/images/payment/visa.png" alt="visa payment method">
        <img src="/assets/images/payment/wallet.png" alt="wallet payment method">
      </div> -->

      <!-- <img src="./assets/images/payment.png" height="auto" width="auto" alt="payment method" class="payment-img"> -->


      <p class="copyright">
        © 2018 Maniyar Bangles Online Shopping Website
        <br>
          <a [routerLink]="['terms']" (click)="scrollToTop()" routerLinkActive="router-link-active"  >Terms</a>&nbsp;


          <a [routerLink]="['privacypolicy']" (click)="scrollToTop()" routerLinkActive="router-link-active" >Privacy policy</a>

      </p>


    </div>

  </div>

</footer>
