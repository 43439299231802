import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { ProductService } from './product.service';

interface Meta{
  status:boolean,
  data?:any,
  byproducts?:boolean,
  permission: "add" | "remove" | "list";
}
export interface SycnType{
  wishlist: Meta
}
@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  private sync = new BehaviorSubject<SycnType | null>(null);
  syncMeta = this.sync.asObservable();
    
  constructor(private http: HttpClient,
    private product:ProductService,
    private localStorage: StorageService) { }
    
  
  
    syncData(type: SycnType) {
      if(type.wishlist.status==true){
        this.sync.next(type);
      }
    }

    setWichListCount(wichListCount: any) {
      this.sync.next(wichListCount);
    }
}
