import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageProcessingProductService {

  private imageProcessing = new BehaviorSubject<any>([]);
  imageProduct = this.imageProcessing.asObservable();

  constructor() { }

  setImageProcess(list: any) {
    this.imageProcessing.next(list);
  }
}
