import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-color-list',
  templateUrl: './color-list.component.html',
  styleUrls: ['./color-list.component.css']
})
export class ColorListComponent {
  colorList:any = []
  title:any = ""
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    this.colorList = data?.color
    this.title = data?.title
    // //console.log(this.colorList);
    
  }
}
