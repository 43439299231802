import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { ProductService } from 'src/app/_services/product.service';
import { Utils } from 'src/app/_shared/Uitls';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {



  @Input("title") title:any;
  @Input("skeleton") skeleton:boolean = false;
  @Input("product") product:any = [null];
  skeletonData:any = [1,2,3]
  currency:any = currency;

  constructor(
    private productService:ProductService,
    private router:Router
  ){}
  ngOnInit(): void {

  }

  gotoCatrgory(arg0: any) {
    this.router.navigate([arg0])
    this.scrollToTop()
  }
  slug_modify(text:any){
    return Utils.slug_modify(text)
  }

  productImage(url:any){
    return ImageUrlService.getImageUrl(url);
  }
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

}
