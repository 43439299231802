import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[tbSingleSpace]'
})
export class SingleSpaceDirective {

  timeout: any = null;
  constructor(private ngControl: NgControl) {}
  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement) {
    let value = target.value;
    value = value.replace(/\s+/g, ' ');

    clearTimeout(this.timeout);
    this.ngControl.control?.setValue(value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    const inputValue = this.ngControl.control?.value;
    const selectionStart = event.target['selectionStart'];

    if (event.key === ' ' && selectionStart === 0) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event.target'])
  onBlur(target: HTMLInputElement) {
    let value = target.value;
    value = value.trim();
    clearTimeout(this.timeout);
    this.ngControl.control?.setValue(value);
  }
}
