import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingService } from 'src/app/_services/setting.service';
import { StorageService } from 'src/app/_services/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  closeModal: boolean = false;
  email: any = "";
  newslaterForm!: FormGroup<any>;

  constructor(private storage:StorageService,
    private setting:SettingService) {

  }

  close(){
    this.closeModal = true;
    this.storage.setModal(JSON.stringify({
      hide:true,
      time:new Date()
    }))
  }
  ngOnInit(): void {
    this.newslaterForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
    this.closeModal = this.storage.isModalNeed();
  }
  Subscribe(){
    
    if(this.newslaterForm.valid){
      this.setting.subscribe(this.newslaterForm.value).subscribe((data:any) => {
        Swal.fire({
          icon: "success",
          title: data?.data,
          allowOutsideClick:false,
          showConfirmButton: true,
        }).then((result) => {
              if(result.isConfirmed){
                this.close()
              }
        });
      },(error:any) => {
        Swal.fire({
          icon: "warning",
          title: "You have already subscribe with us",
          allowOutsideClick:false,
          showConfirmButton: true,
        }).then((result) => {
              if(result.isConfirmed){
                // this.close()
              }
        });
      })
    }
  }
}
