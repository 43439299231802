import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { AddressRjxService } from 'src/app/_services/rjx/address-rjx.service';
import { UserService } from 'src/app/_services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent {
  addressForm!: FormGroup;
  @Input("showForm") showForm: boolean = false;
  @Input("dataField") dataField:any;
  forSave:boolean = false;

  @Output('close') close = new EventEmitter<any>;
  @Output('data') data = new EventEmitter<any>;

  constructor(private authService: AuthService,
    private addressRjx : AddressRjxService,
    private userService: UserService,
    private router :Router,
    private formBuilder: FormBuilder,) {

      this.addressForm = this.formBuilder.group({
        name: ['', [Validators.required,Validators.minLength(3)]],
        phone: ['', [Validators.required,Validators.minLength(10)]],
        postal_code: ['', [Validators.required,Validators.minLength(6)]],
        locality: ['', [Validators.required]],
        address: ['', [Validators.required,Validators.minLength(3)]],
        country: ['India', [Validators.required]],
        city: ['', [Validators.required,Validators.minLength(2)]],
        state: ['', [Validators.required,Validators.minLength(2)]],
        landmark: [''],
        alternateNUmber: [''],
        work_type: ['1',[Validators.required]],
      });
     }

  ngOnInit(): void {
    if(this.dataField){
      this.addressForm.patchValue({
        name: this.dataField?.name,
        phone: this.dataField?.phone,
        postal_code: this.dataField?.postal_code,
        locality: this.dataField?.address,
        address: this.dataField?.address,
        country: this.dataField?.country,
        city: this.dataField?.city,
        state: this.dataField?.state,
        landmark: "",
        alternateNUmber: "",
      })
    }
    
    

  }


  Cancel() {
    this.showForm = false
    this.close.emit(true)
  }
  Save() {
    if(this.addressForm.valid){
      this.data.emit(this.addressForm.value)
      this.forSave = true;
      this.addressRjx.changeMessage({addingData:true})
      

      this.userService.createUserAddresss(this.addressForm.value).subscribe((data:any) => {
          this.addressRjx.changeMessage({data:data?.data,addingData:false})
          this.forSave = false;
          
      })
    }else{
      Swal.fire({
        icon: "warning",
        title: "Fill all mandatory field *",
        showConfirmButton: false,
        timer: 1500
      });
    }
    
  }
}
