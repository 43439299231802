<!-- Navbar-->
<header class="header">
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container">
      <!-- Navbar Brand -->
      <a routerLink="/" class="navbar-brand">
        <img src="/assets/images/logo/logo.webp" alt="logo" width="150">
      </a>
    </div>
  </nav>
</header>


<div class="container">
  <div class="row align-items-center">
    <!-- For Demo Purpose -->
    <div class="col-md-5 pr-lg-5 mb-5 mb-md-0">
      <img src="/assets/images/svg/palm-recognition.svg" alt=""
        class="img-fluid mb-3 d-none d-md-block">
      <h1>Welcome Back to Maniyar Bangles</h1>
      <p class="text-muted mb-0">Access your account to explore our latest hand-crafted bangle collections, track your orders, and enjoy personalized offers. </p>
    </div>

    <!-- Registeration Form -->
    <div class="col-md-7 col-lg-6 ml-auto">
      <form action="#" [formGroup]="loginForm">
        <div class="row">

          <!-- Email Address -->
          <div class="col-lg-12 mb-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput id="email" autocomplete="off" formControlName="email" noSpace tbSingleSpace type="email" name="email"
                placeholder="Email Address">
              <mat-icon matPrefix>email</mat-icon>
              <mat-error *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched">
                Email is required
              </mat-error>
              <mat-error *ngIf="loginForm.get('email').hasError('email') && loginForm.get('email').touched">
                Please enter a valid email.
              </mat-error>
            </mat-form-field>
          </div>


          <!-- Password -->
          <div class="col-lg-12 ">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Password</mat-label>
              <input matInput id="password" [type]="isPasswordVisible ? 'text' : 'password'" name="password"
                placeholder="Password" formControlName="password" noSpace tbSingleSpace>
              <mat-icon matPrefix>lock</mat-icon>
              <mat-icon matSuffix class="c-p" (click)="togglePasswordVisibility()" *ngIf="!isPasswordVisible">visibility_off</mat-icon>
              <mat-icon matSuffix class="c-p" (click)="togglePasswordVisibility()" *ngIf="isPasswordVisible">visibility</mat-icon>
              <mat-error *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
                Password is required
              </mat-error>
              <mat-error *ngIf="loginForm.get('password').errors?.minlength">
                Password must be at least 6 characters.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="text-right w-100 pr-2">
            <p class="text-success c-p font-weight-bold b-tag" (click)="gotoForgotPasssword()">Forgot Password?</p>
          </div>


          <!-- Submit Button -->
          <div class="form-group col-lg-12 mx-auto mb-0" *ngIf="progressStatus==0">
            <button class="btn btn-primary btn-block py-2" [disabled]="!loginForm.valid" (click)="LoginFrom()">
              <span class="font-weight-bold">Login you account</span>
            </button>
          </div>
          <div class="form-group col-lg-12 mx-auto mb-0" *ngIf="progressStatus==1">
            <button class="btn btn-primary btn-block py-2 center">
              <mat-spinner color="white" class="mat-spinner" [diameter]="20"></mat-spinner>
            </button>
          </div>
          <div class="form-group col-lg-12 mx-auto mb-0" *ngIf="progressStatus==2">
            <button class="btn btn-primary btn-block py-2">
              <mat-icon>check</mat-icon> Check email
            </button>
          </div>

          <!-- Divider Text -->
          <div class="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
            <div class="border-bottom w-100 ml-5"></div>
            <span class="px-2 small text-muted font-weight-bold text-muted">OR</span>
            <div class="border-bottom w-100 mr-5"></div>
          </div>

          <!-- Social Login -->
          <div class="row w-100 mx-auto social-login">
            <!-- <div class="col-lg-6 col-md-12 col-sm-12 mb-1">
                <button (click)="loginWithFB()" class="btn btn-primary btn-block btn-facebook p-3">
                    <i class="fa fa-facebook"></i>
                    <span class="font-weight-bold">Continue with Facebook</span>
                </button>
            </div> -->
            <div class="col-lg-12 col-md-12 col-sm-12">
                <button class="btn btn-block">
                    <asl-google-signin-button type="standard" size="large"></asl-google-signin-button>
                </button>
            </div>
        </div>
          <!-- Already Registered -->
          <div class="text-center w-100 pt-3">
            <p class="text-muted font-weight-bold b-tag">Don't Have an Account? <button (click)="gotoSignUp()"
                class="text-primary ml-2">Sign Up Now</button></p>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
