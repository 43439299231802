import { Injectable } from '@angular/core';
import { AddressService } from './localstorage/address.service';

@Injectable({
  providedIn: 'root'
})
export class CartStorageService extends AddressService{
  addedItems: any = (JSON.parse(this.getCartData())==undefined) ? [] :JSON.parse(this.getCartData());

  constructor() {
    super();
  }

  getCartData(): any {
    if (typeof window !== 'undefined') {
      return localStorage.getItem("cart")
    }else{
      return JSON.stringify({})
    }

  }
  getCartDataList(): any {
    var cart = localStorage.getItem("cart")
    if(cart){
      return JSON.parse(cart);
    }
    return [];
  }
  setItem(data: any) {
    localStorage.setItem("cart", data);
  }
  addCart(item: any) {
    if (this.getCartData() != null || undefined) {
      var data = JSON.parse(this.getCartData());
      this.setItem(JSON.stringify([item, ...data]))
    } else {
      // //console.log("itemssssssss", item);

      this.setItem(JSON.stringify([item]))
    }
  }
  updateQty(item: any,order:any = "asc") {
    // Get the cart from localStorage
    let cart = JSON.parse(this.getCartData());
    const idToCheck = item.id; // ID to check
    let newQty ;
    const itemToUpdate = cart.find((item: { id: any; }) => item.id === idToCheck);
    if (itemToUpdate) {
      if(order=="desc"){
        newQty = itemToUpdate.qty - 1;
      }else{
        newQty = itemToUpdate.qty + 1;
      }
      itemToUpdate.qty = newQty;
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }
  checkCartIsExist(data: any) {
    if (typeof window !== 'undefined') {
      if (this.getCartData() == null || undefined) {
        return false;
      }
      // Check if object a and b are equal
      return JSON.parse(this.getCartData())
        .some((obj: any) => this.objectsAreEqual(obj, data));
    }else{
      return false;
    }

  }
  objectsAreEqual(obj1: any, obj2: any) {

    return obj1.id == obj2.id && obj1.variant == obj2.variant ;
  }

  removeItem(cartItem: any) {
    // Given array arr1 and object obje2
    var arr1 = this.getCartDataList();

    // Filter out the object from arr1 that matches obje2
    arr1 = arr1.filter(function(item: { id: any; variant: any; }) {
      return item.id !== cartItem.id || item.variant !== cartItem.variant
    });

    // //console.log(arr1);

    // const index = this.addedItems.findIndex((item: any) => item.id === cartItem.id && item.variant === cartItem.variant);
    // if (index !== -1) {
    //   this.addedItems.splice(index, 1);
    //   this.setItem(JSON.stringify(arr1));
    // }
    this.setItem(JSON.stringify(arr1));

  }


}
