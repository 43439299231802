import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { StorageService } from '../_services/storage.service';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import Swal from 'sweetalert2';
import { LoadingService } from '../_helpers/loading.service';
import { CartService } from '../_services/cart.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {


  loginForm: any = FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  isprogress: boolean = false;
  roles: string[] = [];
  progressStatus: number = 0;
  isPasswordVisible = false;



  googleSignin!: string;
  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private cartService:CartService,
    public loadingService: LoadingService,
    private socialAuthService: SocialAuthService
  ) { }

  ngOnInit(): void {
    this.loadingService.loading$.subscribe((data: any) => {
      this.isprogress = data
    })
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      // this.roles = this.storageService.getUser().roles;
    }
    this.storageService.getUser();

    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
    this.socialAuthService.authState.subscribe((result) => {
      const userData = {
        "plateform": "web",
        "provider": result?.id,
        "providerName": result?.provider,
        "personPhoto": result?.photoUrl,
        "device_token": "website"
      };
      var resToServer = { ...userData, ...result }

      this.authService.socialAuth(resToServer).subscribe((res: any) => {
        if (res?.success == false) {
          Swal.fire({
            position: "top-start",
            icon: "warning",
            title: res?.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
        if (res?.error == false) {
          this.storageService.saveUser(res)
          Swal.fire({
            position: "top-start",
            icon: "success",
            title: res?.message,
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['/']);
          this.addTocartServer()
        }

      });
    });


  }
  addTocartServer(){
    this.storageService.getCartDataList().forEach((e:any) => {
      var data = {
        "productId": e.product?.id,
        "variant": e.variant,
        "qty": e.qty
      }
      this.cartService.addTocartCateorys(data).subscribe((data:any) => {
        this.storageService.removeItem(e);
        if(this.storageService.getCartDataList().length==0){
          localStorage.removeItem("cart")
        }
      })
    })

  }

  LoginFrom() {
    if (this.loginForm.valid) {
      this.progressStatus = 1;

      this.authService
        .login(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe((res) => {
          if (res?.success == false) {
            this.progressStatus = 0;
            Swal.fire({
              position: "center",
              icon: "warning",
              title: res?.message,
              showConfirmButton: false,
              timer: 1500
            });
          }
          if (res?.error == false) {
            this.storageService.saveUser(res)
            this.progressStatus = 2;
            Swal.fire({
              position: "top-start",
              icon: "success",
              title: res?.message,
              showConfirmButton: false,
              timer: 1500
            });
            this.router.navigate(['/']);
            this.addTocartServer()
          }
        });
    } else {

      Swal.fire({
        position: "top-start",
        icon: "warning",
        title: "please enter all field",
        showConfirmButton: false,
        timer: 1200
      });
    }
  }
  onSubmit(): void {
    // this.authService.login(username, password).subscribe({
    //   next: data => {
    //     this.storageService.saveUser(data);
    //     this.isLoginFailed = false;
    //     this.isLoggedIn = true;
    //     this.roles = this.storageService.getUser().roles;
    //     this.router.navigate(['/dashboard'])
    //   },
    //   error: err => {
    //     this.errorMessage = err.error.message;
    //     this.isLoginFailed = true;
    //   }
    // });
  }


  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  reloadPage(): void {
    window.location.reload();
  }

  gotoSignUp() {
    this.router.navigate(['register']);
  }
  gotoForgotPasssword() {
    this.router.navigate(['forgotPassword']);
  }



  gotoHome() {
    this.router.navigate(['/']);
  }
  loginWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);

  }
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  // styleUrls: ['./snackbar.component.css']
})
export class Snackbar { }
