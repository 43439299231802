import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/_helpers/loading.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonRxjsService } from 'src/app/_services/common-rxjs.service';
import { GeneralSettingRjxService } from 'src/app/_services/rjx/general-setting-rjx.service';
import { SettingService } from 'src/app/_services/setting.service';
import { StorageService } from 'src/app/_services/storage.service';
import { EventBusService } from 'src/app/_shared/event-bus.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent {
  overlay:boolean = false;
  isprogress:boolean = false;

  constructor(
    public storageService: StorageService,
    public authService: AuthService,
    private eventBusService: EventBusService,
    public loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private _du:CommonRxjsService,
    private setting:SettingService,
    private generalSetting:GeneralSettingRjxService,
    private route:ActivatedRoute,
    private r:Router
  ) {

  }
  res:any;

  ngOnInit(): void {

    this.route.queryParams.subscribe((data:any) => {
      if(data){
        if(data['token']!=null){
          var accessData = JSON.parse(atob(data['token']))
          if(accessData?.v2Data?.tokenType){
            this.storageService.saveUser(accessData);
            setTimeout(() => {
              this.r.navigate(['/'])
            }, 1000);
          }

          // this.storageService.saveUser( atob(data['token']))
        }
      }
    })


    this.loadingService.loading$.subscribe((data:any) => {
      this.isprogress = data
      this.cdr.detectChanges();
    })
    this._du.currentMessage.subscribe(message =>{
      if(message?.type=="productFilter"){
        this.overlay = message?.status
      }else{
        this.overlay = message?.status
      }

    });
    this.scrollToTop()

    this.generalSetting.generalSetting.subscribe((data:any) => {
      if(data==null){
        this.setting.getGeneralSetting().subscribe((data:any) => {
          this.generalSetting.setGeneralSetting(data)
        })
        this.setting.getGeneralSetting("seo").subscribe((data:any) => {
          this.generalSetting.setSeo(data)
        })
        this.setting.getGeneralSetting("businessSettings").subscribe((data:any) => {
          this.generalSetting.setBusinessSettings(data)
        })
        this.setting.getGeneralSetting("settings").subscribe((data:any) => {
          this.generalSetting.setSettings(data)
        })
      }
    })

  }
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  onActivateComponent($event: any) {
    if (typeof window !== 'undefined') {
      const loadingElement = document.getElementById('tb-loading-spinner');
      loadingElement?.remove();
    }
  }
}
