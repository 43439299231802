import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/_services/storage.service';
import { OrderUtils } from 'src/app/module/profile/order/order-list/order-utils';

@Component({
  selector: 'app-profile-sidebar-layout',
  templateUrl: './profile-sidebar-layout.component.html',
  styleUrls: ['./profile-sidebar-layout.component.css']
})
export class ProfileSidebarLayoutComponent {

  @Output() output = new EventEmitter<any>(false);

  tempStep:any ;
  tempSubStep:any ;
  status:OrderUtils = Object.values(OrderUtils)[0];
  status_cancel:any = OrderUtils.cancel;
  isLogin:boolean = false;
  constructor(
    protected router:Router,
    private storage:StorageService
  ) {

  }
  @Input("step") step = 0;
  @Input("subStep") subStep = -1;

  ngOnInit(): void {
    this.isLogin = this.storage.isLoggedIn()
    this.tempStep = this.step;
    this.tempSubStep = this.subStep;
  }
  gotoWallet(){
    if(window.location.origin.indexOf('localhost')!=-1){
      return "http://localhost:4210/auth?token="+this.storage.getRootToken()
    }else{
      return "https://wallet.maniyarbangles.com/auth?token="+this.storage.getRootToken()
    }
  }
  setStep(index: number) {
    if(index==2){
      window.open(this.gotoWallet(), '_blank');
      return;
    }
    this.step = index;
    this.subStep = -1;
    if(index==this.tempStep){
      this.subStep = this.tempSubStep
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  Goto(page:any,step:any,subStep:any){
    this.subStep = subStep;
    this.step = step;
    this.router.navigate([page])
    this.scrollToTop()
    this.output.emit(true)
  }
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
