import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit{

  @Input() rating: number = 0;
  @Input() maxRating: number = 5;
  starsArray: number[] = [];


  constructor() {
    

  }
  ngOnInit(): void {
    if(this.rating==null){
      this.rating = 1;
    }
    if(this.rating>this.maxRating){
      this.rating = 4;
    }
    let integerPart: number = Math.floor(this.rating);
    let decimalPart: number = this.rating - integerPart;
    for (let i = 0; i < integerPart; i++) {
      this.starsArray.push(1); // Assuming 1 represents a full star
    }
    if (decimalPart > 0) {
      this.starsArray.push(0.5); // Assuming 0.5 represents a half star
    }
    var totalStartLeft = this.starsArray.length-this.maxRating
    for (let index = 0; index < Math.abs(totalStartLeft); index++) {
      this.starsArray.push(0);
    }
  }
}
