<div class="product-featured">

    <h2 class="title">{{title}}</h2>

    <div class="showcase-wrapper has-scrollbar">

      <div class="showcase-container" *ngFor="let item of toDayDealsList">

        <div class="showcase">

          <div class="showcase-banner">
            <img [src]="productImage(item?.featured_image)" height="auto" width="auto" alt="{{item?.name}}" class="showcase-img">
          </div>

          <div class="showcase-content">

            <app-rating [rating]="item?.rating" [maxRating]="5"></app-rating>

            <a [routerLink]="['/products',item?.slug]" routerLinkActive="router-link-active" (click)="scrollToTop()" >
              <h3 class="showcase-title">{{item?.name}}</h3>
            </a>

            <p class="showcase-desc d-none">
              Lorem ipsum dolor sit amet consectetur Lorem ipsum
              dolor dolor sit amet consectetur Lorem ipsum dolor
            </p>

            <div class="price-box">
              <p class="price">{{currency}} {{item?.base_discounted_price}}</p>

              <del>{{currency}}{{item?.base_price}}</del>
            </div>

            <button class="add-cart-btn" (click)="viewProducts(item,'dml')">add to cart</button>

            <div class="row mt-2 d-none">
              <div class="col-md-2 sm-height mt-1">
                <h2 class="font">Size</h2>
              </div>
              <div class="col-md-10 sm-p-b">
                <mat-chip-listbox aria-label="Fish selection">
                  <mat-chip-option *ngFor="let item of obj(item?.choice_options)">{{item}}</mat-chip-option>
                  </mat-chip-listbox>
              </div>
            </div>

            <div class="showcase-status">
              <div class="wrapper">
                <p>
                  already sold: <b>{{item?.total_sell_qty}}</b>
                </p>

                <p>
                  available: <b>{{item?.total_qty}}</b>
                </p>
              </div>
              <!-- {{((item?.total_sell_qty / item?.total_qty) * 100)}} -->


              <!-- <div class="showcase-status-bar" data-width="20%"></div> -->
            </div>

            <div class="countdown-box ">

              <p class="countdown-desc">
                Hurry Up! Offer ends in:
              </p>

              <div class="countdown">

                <div class="countdown-content">

                  <p class="display-number">{{countDown?.days}}</p>

                  <p class="display-text">Days</p>

                </div>

                <div class="countdown-content">
                  <p class="display-number">{{countDown?.hours}}</p>
                  <p class="display-text">Hours</p>
                </div>

                <div class="countdown-content">
                  <p class="display-number">{{countDown?.minutes}}</p>
                  <p class="display-text">Min</p>
                </div>

                <div class="countdown-content">
                  <p class="display-number">{{countDown?.seconds}}</p>
                  <p class="display-text">Sec</p>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>


    </div>

  </div>
