import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryListRjxService } from 'src/app/_services/rjx/category-list-rjx.service';
import { StorageService } from 'src/app/_services/storage.service';
import { Utils } from 'src/app/_shared/Uitls';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit{

  isLogin:boolean = false;
  categoryList: any = [];
  catList: any = [];
  constructor(private router: Router,
    private categoryListRjx:CategoryListRjxService,
    private storage: StorageService) {}
  ngOnInit(): void {
    this.isLogin = this.storage.isLoggedIn()

    this.categoryListRjx.categoryList.subscribe((data:any) => {
      this.categoryList = data
      data.forEach((element:any) => {
        this.catList.push({
          name:element.name,
          url:'/'+this.slug_modify(element.name)+"-"+element.id
        })
        element?.subSubCategories?.data.forEach((element2:any) => {
          this.catList.push({
            name:element2.name,
            url:'/'+this.slug_modify(element.name)+"/"+this.slug_modify(element?.name)+'-'+element2?.id
          })

        });
        this.catList = this.catList.filter((item: { name: any; }, index: any, self: any[]) =>
            index === self.findIndex((t) => t.name === item.name)
        );

      });



    })
  }

dynamicpage(page:any= null) {

  if(page != null){
    this.router.navigate([page])
    this.scrollToTop()
  }

}
scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
slug_modify(text:any){
  return Utils.slug_modify(text);
}

}


