import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export enum Version {
  V1 = "v1/",
  V2 = "v2/"
}

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  label:any = "wallet/"
  constructor(
    private http: HttpClient,
  ) { }

  getBalance(type:any = "ALL_BALANCE_LIST"){
    return this.http.post(environment.apiUrl+this.label+"balance/"+type,null);
  }
  getTranslation(type:"MB_PAY"|"SHOPPING_WALLET" = "SHOPPING_WALLET",pageIndex:any=0,pageSize:any=10){
    return this.http.get(environment.apiUrl+this.label+"translation/"+type+"/desc/"+pageIndex+"/"+pageSize);
  }
  addMoney(type:"MB_PAY"|"SHOPPING_WALLET" = "SHOPPING_WALLET",rzpPayId:any){
    return this.http.post(environment.apiUrl+this.label+"add-money-to-wallet/"+type+"/"+rzpPayId,null);
  }
}
