<body class="container-fluid bg-body-tertiary d-block">
    <div class="row justify-content-center">
      <div class="col-12">
        <p class="float-right mt-3 close c-p" (click)="close()"><mat-icon>close</mat-icon></p>
          <div class="card bg-white mb-5 mt-2 border-0" style="box-shadow: 0 12px 15px rgba(0, 0, 0, 0.02);">
            <div class="card-body p-5 text-center">
              <h1><b>Verify OTP</b></h1>
              <p>{{data?.title}}</p>

              <ng-otp-input  #ngOtpInput (onInputChange)="onOtpChange($event)"  [config]="otpConfig"></ng-otp-input>
              Resent otp: {{ countdownMinutes }}:{{ countdownSeconds }}
              
              <br/>
              <button class="btn btn-primary mb-3 mt-3" (click)="verifyOtp()" [disabled]="desableBtn">
                Verify
              </button>
              
              <p class="resend text-muted mb-0"  >
                Didn't receive code? <button class="resentBtn mt-3" [disabled]="desableRecentBtn" (click)="resend()">Resend</button>
              </p>
            </div>
          </div>
        </div>
      </div>


  </body>

