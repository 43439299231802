import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import Swal from 'sweetalert2';

export function PasswordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (!control || !matchingControl) {
      return null;
    }

    if (matchingControl.errors && !matchingControl.errors['passwordMismatch']) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  passwordForm!: FormGroup;
  token: any;
  showPassword: boolean = false;
  showretypePassword: boolean = false;

  progressStatus:number = 0;

  constructor(private formBuilder: FormBuilder, 
    private route:ActivatedRoute,
    private _router:Router,
    private authService: AuthService) { 
      
    }


    togglePasswordVisibility(type:any): void {
      if(type == 'newPassword'){
      this.showPassword = !this.showPassword;
      }else if(type == 'retypePassword'){
        this.showretypePassword = !this.showretypePassword;
      }
    }
    
    ngOnInit(): void {
      
      this.route.params.subscribe((data:any) => {
        this.token = data['token']
      })
    
      this.passwordForm = this.formBuilder.group({
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        retypePassword: ['', Validators.required]
      }, {
        validators: PasswordMatchValidator('newPassword', 'retypePassword')
      });

  }
  
  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get('newPassword')?.value;
   const retypePassword = formGroup.get('retypePassword')?.value; 
  return newPassword === retypePassword ? null : { passwordMismatch: true };
  }
   

   
  resetPassword() {
    if (this.passwordForm.valid) {
      this.progressStatus = 1;
      this.authService.updateResetPassword(this.passwordForm.controls['newPassword'].value,this.token).subscribe(
        () => {
        this.progressStatus = 2;

          Swal.fire({
            icon: 'success',
            title: 'Password reset successfully',
            confirmButtonText: 'OK',
            allowOutsideClick:false
          }).then(data => {
            if(data?.isConfirmed){
              this._router.navigate(['login'])
            }
          })
        },
        (error: any) => {
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Password field error',
        confirmButtonText: 'OK'
      });
    }
  }
     
 
}
function togglePasswordVisibility() {
  throw new Error('Function not implemented.');
}

