import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { currency } from 'src/app/_services/constant';
import { OrderService } from 'src/app/_services/order.service';
import { ProductRatingComponent } from 'src/app/layout/rating/product-rating/product-rating.component';

@Component({
  selector: 'app-track-your-order',
  templateUrl: './track-your-order.component.html',
  styleUrls: ['./track-your-order.component.css']
})
export class TrackYourOrderComponent implements OnInit {

  currancy:any = currency

  isActiveAutocomplete:boolean = false;
  searchTrm: any = "";
  isSkeleton:boolean = false;

  value = 'Clear me';

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: '',
  });
  isOptional = false;
  orderTrackDetails: any = {};
  orderId: any = null;
  productIdCollection: any = [];
  noTrackOrderFound: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private route:ActivatedRoute,
    private orderService:OrderService,
    private dialog: MatDialog

    ) {}
  ngOnInit(): void {
    this.route.params.subscribe((data:any) => {
      this.orderId = data['orderId']
      if(this.orderId!=null){
        this.searchTrm =this.orderId;
        this.getOrderTrack(this.orderId);
      }
    })


  }
  getFeedBackModal(){
    this.dialog.open(ProductRatingComponent,{
      disableClose: true,
      data:{
        productIdCollection:this.productIdCollection,
        orderId:this.orderId
      }
    });
  }
  getOrderTrack(orderId:any){
    this.isSkeleton = true;
    this.orderTrackDetails = {};
    this.orderService.track(orderId).subscribe((data:any) => {
      if(data!=null){
        this.noTrackOrderFound = false;
        this.orderTrackDetails = data;
        data?.order_details.forEach((element:any) => {
          this.productIdCollection.push(element?.product_id)
          if(element?.delivery_status=="delivered"){
            if(data.reviews==null){
              this.getFeedBackModal()
            }
          }
        });

      }else{
        this.noTrackOrderFound = true;
        this.isSkeleton = false;
      }

    })
  }


  search(event: any) {
    var data = event.target.value;
    if (data.length > 1) {
      this.isActiveAutocomplete = false;
    }
  }
  timeout: any = null;

  searchKeyUp(event: any) {
    clearTimeout(this.timeout);
    this.isSkeleton = false;
    var data = event.target.value;
    var $this = this;
    if (data.length > 0) {
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.getOrderTrack(data);
        }
      }, 1000);

      this.isActiveAutocomplete = true;
    }else{
      this.isActiveAutocomplete = false;
    }
  }
  getShippingAddress(shipping_address:any){
    return JSON.parse(shipping_address)
  }
  getClassForDeliveryStatus(deliveryStatus: string,target:string): any {
    if(deliveryStatus=="delivered"){
      return {'done':true,'active':false}
    }
    return {
      'done': deliveryStatus !== target,
      'active': deliveryStatus === target,
    };
  }
  getClassForDeliveryStatusIcon(deliveryStatus: string,target:string[]): any {
    var res = false;
    target.forEach(element => {
      if(deliveryStatus==element){
        res = true;
      }
    });
    return res;

  }


  crossbtn(): any {

    this.searchTrm ="";
    this.orderTrackDetails = {}
    this.isSkeleton = false
    this.isActiveAutocomplete = false;

  }
}
