import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class CategoryListRjxService {

  private categorySub = new BehaviorSubject<any>([]);
  categoryList = this.categorySub.asObservable();

  constructor() { }

  setFilter(list: any) {
    this.categorySub.next(list);
  }
}
