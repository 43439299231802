<div class="p-d-p" *ngIf="itemCount==0">
  <mat-card>
    <mat-card-content>
      <img src="/assets/images/svg/empty-cart.svg" >
      <p class="empty-cart">Looks like you haven't added anything yet!</p>
    </mat-card-content>
  </mat-card>
</div>
<div class="p-d-p" *ngIf="itemCount>0">
    <mat-card>
        <mat-card-content class="p-3">
            <h3 class="title">
                PRICE DETAILS
            <del *ngIf="totalPrice>=min_order"><p class="text-danger p-0 m-0 fs-13px">Minimum order: {{currency}} {{min_order}}</p></del>
            <p class="text-danger p-0 m-0 fs-13px"  *ngIf="totalPrice<min_order" >Minimum order: {{currency}} {{min_order}}</p>
            </h3>
            <div class="title">
                <div class="d-flex justify-content-between">
                    <span>
                        <h4>Price({{itemCount}} item)</h4>
                    </span>
                    <span>
                        <h4>{{currency}}{{(totalPrice+totalDiscountPrice)}}</h4>
                    </span>
                </div>
                <div class="d-flex justify-content-between">
                    <span>
                        <h4>Discount</h4>
                    </span>
                    <span>
                        <h4 class="text-success">-{{currency}}{{totalDiscountPrice}}</h4>
                    </span>
                </div>
                <div class="d-flex justify-content-between" *ngIf="this.ApplyCouponError?.coupons_id">
                    <span>
                        <h4 class="d-flex">
                            <mat-icon class="green-icon"> local_offer</mat-icon>
                            <span class="ml-2">{{this.ApplyCouponError?.code}}</span>
                        </h4>
                    </span>
                    <span>
                        <h4 class="text-success">-{{currency}}{{this.ApplyCouponError?.coupon_discount}}</h4>
                    </span>
                </div>
                <div class="d-flex justify-content-between">
                    <span>
                        <h4>Delivery charge</h4>
                    </span>
                    <span *ngIf="Deliverycharge==0">
                        <h4 class="text-success">Free</h4>
                    </span>
                    <span *ngIf="Deliverycharge!=0">
                        <h4 class="text-success">{{currency}}{{Deliverycharge}}</h4>
                    </span>
                </div>
                <div *ngIf="isPlaceHoderOrReviewOrder">
                    <div class="d-flex justify-content-between" *ngIf="option!=''">
                        <span>
                            <h4>Option selected:</h4>
                        </span>
                        <span>
                            <h4 class="text-success">
                                {{
                                option!=''
                                ? option
                                : "None"
                                }}
                            </h4>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div class="d-flex justify-content-between">
                    <span>
                        <h2>Total Amount</h2>
                    </span>
                    <span *ngIf="totalPrice!=0">
                        <h2 [ngClass]="{'text-success': totalPrice>=min_order,'text-danger': totalPrice<min_order}">{{currency}}{{(totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount)}}</h2>
                    </span>
                </div>
            </div>


        </mat-card-content>
    </mat-card>
    <div class="mt-2" *ngIf="storage.isLoggedIn() && min_order!=null">
        <mat-card class="my-1" *ngIf="promoceAppling==1">
            <mat-card-content class="p-2 d-c-f-cart">
                <mat-spinner diameter="30"></mat-spinner><span class="ml-2">Appling promocode</span>
            </mat-card-content>
        </mat-card>
        <mat-card class="my-1" *ngIf="promoceAppling==0">
            <mat-card-content class="p-2">
                <div class="applied-coupons" *ngIf="isProgress==2">
                    <div class="d-flex">
                        <mat-icon class="green-icon"> local_offer</mat-icon>
                        <div class="ml-2 content">{{coupanCode}}</div>
                    </div>
                    <div>
                        <span class="remove" (click)="remove()">Remove</span>
                    </div>
                </div>
                <div class="pincode d-flex  " *ngIf="isProgress!=2">
                    <!-- <input type="text" placeholder="Enter Coupan" class="p-1" [(ngModel)]="coupanCode" (keyup)="ApplyCouponError = {}" /> -->
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Enter Coupan</mat-label>
                        <input matInput type="text" [(ngModel)]="coupanCode" (keyup)="ApplyCouponError = {};ApplyCouponError.coupon_discount = 0;">
                        <button *ngIf="coupanCode" matSuffix mat-icon-button aria-label="Clear" (click)="coupanCode='';ApplyCouponError = {};ApplyCouponError.coupon_discount = 0;">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>

                    <button mat-stroked-button class=" mx-3" type="button" color="primary" *ngIf="isProgress==1">
                        <mat-spinner [diameter]="25" ></mat-spinner>
                    </button>
                    <button mat-stroked-button class="mt-1 mx-3" type="button" color="primary" *ngIf="isProgress==0" (click)="ApplyCoupon()">Apply</button>
                </div>
                <button mat-button color="primary" *ngIf="isProgress!=2" (click)="gotoPromocode()">Get Promocode</button>
                <div *ngIf="ApplyCouponError?.message">
                    <span *ngIf="ApplyCouponError?.error" class="text-danger">{{ ApplyCouponError?.message }}</span>
                    <span *ngIf="!ApplyCouponError?.error" class="text-success">{{ ApplyCouponError?.message }}</span>
                </div>
            </mat-card-content>
        </mat-card>
        <div *ngIf="isProductPresent">

            <button (click)="gotoReviewPage();scrollToTop();" *ngIf="!isPlaceHoderOrReviewOrder && selectedAddress.length>0"
                mat-raised-button class="track-button mb-3 order-review-btn"
                [disabled]="totalPrice<min_order">
                Goto review order
            <p class="text-danger p-0 m-0 fs-13px" *ngIf="totalPrice<min_order">Minimum order: {{currency}} {{min_order}}</p>

            </button>

            <button *ngIf="!isPlaceHoderOrReviewOrder && selectedAddress.length==0" mat-raised-button
                class="track-button mb-3 order-review-btn" (click)="scrollToTop()"
                 [disabled]="totalPrice<min_order">
                 Goto review order
            <p class="text-danger p-0 m-0 fs-13px" *ngIf="totalPrice<min_order">Minimum order: {{currency}} {{min_order}}</p>

                </button>

        </div>
        <p *ngIf="totalWlletBalance!=-1 && option=='wallet' && (totalWlletBalance==0 || totalWlletBalance<(totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount))" class="text-danger">You do not have sufficient balance to buy these bangles.</p>
        <div *ngIf="option=='wallet' && totalWlletBalance>0">
            <button (click)="placeOrder(); scrollToTop()" [disabled]="totalWlletBalance!=-1 && option=='wallet' && (totalWlletBalance==0 || totalWlletBalance<(totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount))" *ngIf="isPlaceHoderOrReviewOrder"
                mat-raised-button class="track-button mb-3">{{payNow}}<span *ngIf="totalPrice!=0">{{currency}}{{totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount}}</span></button>
        </div>
        <div *ngIf="option=='wallet' && totalWlletBalance<1">
            <button (click)="placeOrder(); scrollToTop()" [disabled]="true" *ngIf="isPlaceHoderOrReviewOrder"
                mat-raised-button class="track-button mb-3">{{payNow}}<span *ngIf="totalPrice!=0">{{currency}}{{totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount}}</span></button>
        </div>
        <div *ngIf="option!='wallet' && option!=''">
            <button (click)="placeOrder(); scrollToTop()" [disabled]="isPlaceOrderValid==false" *ngIf="isPlaceHoderOrReviewOrder"
                mat-raised-button class="track-button mb-3">{{payNow}}<span *ngIf="totalPrice!=0">{{currency}}{{totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount}}</span></button>
        </div>
        <div *ngIf="option=='' ">
            <button (click)=" scrollToTop()" [disabled]="true"
                mat-raised-button class="track-button mb-3">{{payNow}}<span *ngIf="totalPrice!=0">{{currency}}{{totalPrice+Deliverycharge-this.ApplyCouponError?.coupon_discount}}</span></button>
        </div>
    </div>
</div>
