// constants.ts
export const API_URL: string = 'https://api.example.com';
export const MAX_COUNT: number = 10;
export const COLORS: string[] = ['red', 'blue', 'green'];
// Add more constants as needed


// localStorage list
export const tokenKey:string = "token";
export const USER_KEY = 'auth-user';

export const currency = "₹"
