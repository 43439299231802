<app-no-internet></app-no-internet>
<mat-progress-bar mode="indeterminate" *ngIf="isprogress" class="progress-indicator"></mat-progress-bar>

<div class="overlay" [class.active]="overlay" data-overlay></div>
<div class="">

  <!--
  - MODAL
-->
  <app-modal></app-modal>




  <!--
  - NOTIFICATION TOAST
-->

  <app-notification-tost></app-notification-tost>





  <!--
  - HEADER
-->

  <app-header></app-header>





  <!--
  - MAIN
-->
  <router-outlet (activate)="onActivateComponent($event)"></router-outlet>







  <!--
  - FOOTER
-->

  <app-footer></app-footer>





</div>
