import { Injectable } from '@angular/core';
import { PromocodeService } from './promocode.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends PromocodeService{
  field:any = "address";
  constructor() { super() }

  addAddress(data:any){
    localStorage.setItem(this.field,JSON.stringify(data))
  }
  getAddress(){
    const address = localStorage.getItem(this.field)
    if(address){
      return JSON.parse(address)
    }
    return {};
  }
}
