import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

type ConsoleMethods = 'log' | 'warn' | 'error' | 'info' | 'debug';

function disableConsoleMethods(methods: ConsoleMethods[]): void {
  if (typeof window !== 'undefined') {
    if (window.location.hostname != 'localhost') {
      methods.forEach(method => {
        (console as any)[method] = () => {};
      });
    }
  }

}

disableConsoleMethods(['log', 'warn', 'error', 'info', 'debug']);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
