import { Router } from '@angular/router';
import { Component, HostListener, ViewChild } from '@angular/core';
import { CommonRxjsService } from 'src/app/_services/common-rxjs.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { CategoryService } from 'src/app/_services/category.service';
import { Utils } from 'src/app/_shared/Uitls';
import { StorageService } from 'src/app/_services/storage.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { currency } from 'src/app/_services/constant';
import { ProductService } from 'src/app/_services/product.service';
import { ProductBoxComponent } from '../product-box/product-box.component';
import { ColorRjxService } from 'src/app/_services/rjx/color-rjx.service';
import { ProductFilterRjxService } from 'src/app/_services/rjx/product-filter-rjx.service';
import { CategoryFilterRjxService } from 'src/app/_services/rjx/category-filter-rjx.service';
import { GeneralSettingRjxService } from 'src/app/_services/rjx/general-setting-rjx.service';
import { CartRjxService } from 'src/app/_services/rjx/cart.service';
import { SettingService } from 'src/app/_services/setting.service';
import { CategoryListRjxService } from 'src/app/_services/rjx/category-list-rjx.service';
import { ImageProcessingSearchComponent } from './image-processing-search/image-processing-search.component';





@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {


  max = 2000;
  min = 99;
  value = this.min;
  maxValue=this.max
  colors: any = [];
  selectedColor: any = "";
  filterObject:any = []



  cartCount: any = 0;
  cartCountItem: any = 0;
  isSkeleton:boolean = false;
  isSkeletonData:any = [1,2,3,4]

  isActiveAutocomplete:boolean = false;
  searchData:any = [1,2,3,4]
  isLoginUser: boolean = false;
  currency: any = currency;
  isOpenProductFilter:boolean = true;
  searchTrm: any = "";
  min_order: any = "499";

  constructor(
    private _du: CommonRxjsService,
    private storage: StorageService,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private productService:ProductService,
    private productFilterRjx:ProductFilterRjxService,
    private generalSetting:GeneralSettingRjxService,
    private settingCounter:SettingService,
    private colorRjx:ColorRjxService,
    private cartCountRjx:CartRjxService,
    private router: Router,
    private category: CategoryService,
    public dialog: MatDialog,
    private rxjs: CommonRxjsService,
    private categoryFilterRjxService:CategoryFilterRjxService,
  private categoryListRjx:CategoryListRjxService,

  ) {}
  openSideMenuForMobileVersion: boolean = true;
  openSideMenuForMobileVersionAccount: boolean = true;

  product: any = ['mobile', 'laptop', 'clothing'];
  categoryList: any = [];
  mainCategoriesList: any = [];
  @ViewChild('productBox', { static: false }) productBox: ProductBoxComponent | undefined;


  ngOnInit(): void {
    if(this.storage.isLoggedIn()){
      this.settingCounter.counter().subscribe((data:any) => {
        this.cartCountRjx.setCartCount(data?.cartItem)
      })
    }
    this.cartCountRjx.cart.subscribe((data:any) => {
      if(data!=null){
        this.cartCountItem = data
      }
    })
    this.generalSetting.settingsData.subscribe((data:any) => {
      if(data!=null){
        data.forEach((element:any) => {
          if(element?.var=="min_order"){
            this.min_order = element?.value
          }
        });
      }
    })
    this.colorRjx.colorList.subscribe((data:any) => {
      this.colors = data
    })
    setTimeout(() => {
      this.productBox?.getProductByUrl(5)
    }, 1000);
    this.getCategory();
    if (typeof window !== 'undefined') {
      if(localStorage.getItem('wishlist')){
        if(localStorage.getItem('wishlist')){
          if(localStorage.getItem('wishlist')!=undefined){
            this.cartCount = JSON.parse(localStorage.getItem('wishlist')!).length;
          }else{
            this.cartCount = 0;
          }

        }else{
          this.cartCount = 0;
        }
      }
    }

    this.rxjs.currentMessage.subscribe((res) => {
      if(res){
        if(typeof res == "object"){

        }else{
          this.cartCount = res
        }

      }
    });
    this._du.currentMessage.subscribe((data:any) => {
      // //console.log("fsdhjlfjhjdshfjshdfjhd ",data);

      if(data?.type=="sidebar"){
        if(data?.status==true){
          this.openSideMenuForMobileVersion = false;
        } else{
          this.openSideMenuForMobileVersion = true;

        }
      }else if(data?.type=="productFilter"){
        if(data?.status==true){
          this.isOpenProductFilter = false;
        } else{
          this.isOpenProductFilter = true;

        }
      }
    })
    this.isLogin();
  }

  gotoWallet(){
    if (typeof window !== 'undefined') {
      if(window.location.origin.indexOf('localhost')!=-1){
        return "http://localhost:4210/auth?token="+this.storage.getRootToken()
      }else{
        return "https://wallet.maniyarbangles.com/auth?token="+this.storage.getRootToken()
      }
    }
    return "";
  }
  openWallet(){
    window.open(this.gotoWallet(), '_blank');
  }
  openDialog() {
    this.router.navigate(['/'])
    const dialogRef = this.dialog.open(ImageProcessingSearchComponent,{
      maxWidth:"80vw"
    });

    dialogRef.afterClosed().subscribe(result => {
      // //console.log(`Dialog result: ${result}`);
    });
  }


  isLogin(){
    this.isLoginUser =  this.storage.isLoggedIn()
  }
  hideProductFilter(){
    if(this.isOpenProductFilter){
      this._du.changeMessage({ type: 'productFilter', status: true });
    }else{
      this._du.changeMessage({ type: 'productFilter', status: false });
    }
  }
  OpenMenu() {
    if (this.openSideMenuForMobileVersion) {
      this.openSideMenuForMobileVersion = false;
      this._du.changeMessage({ type: 'sidebar', status: true });
    } else {
      this.openSideMenuForMobileVersion = true;
      this._du.changeMessage({ type: 'sidebar', status: false });
    }
  }
  OpenMenuAccount() {
    if (this.openSideMenuForMobileVersionAccount) {
      this.openSideMenuForMobileVersionAccount = false;
    } else {
      this.openSideMenuForMobileVersionAccount = true;
    }
  }

  GotoLogin() {
    this.router.navigate(['/login']);

  }


  search(event: any) {
    var data = event.target.value;
    if (data.length > 1) {
      this.router.navigate(['/search/', data]);
      this.isActiveAutocomplete = false;
    }
  }


  crossbtn(): any {

    this.searchTrm ="";

    this.isActiveAutocomplete = false;

  }



  timeout: any = null;

  searchKeyUp(event: any) {
    clearTimeout(this.timeout);
    this.isSkeleton = false;
    var data = event.target.value;
    var $this = this;
    if (data.length > 0) {
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          var dateTosearch = {
            "key": data,
            "scope": "price_low_to_high"
          }
          $this.productService.searchProduct(dateTosearch).subscribe((data:any)=>{
            $this.isSkeleton = true
            $this.searchData = data?.data
          })
        }
      }, 1000);

      this.isActiveAutocomplete = true;
    }else{
      this.isActiveAutocomplete = false;
    }
  }

  getCategory() {
    this.category.getMainCategories().subscribe((data: any) => {
      this.mainCategoriesList = data?.data;
    });
    this.category.getCateorys().subscribe((data: any) => {
      this.categoryList = data?.data;
      this.categoryListRjx.setFilter(data?.data)
    });
  }
  GotoProductPage(item: any,subsubcat:any) {
    if(subsubcat==null){
      this.router.navigate([this.slug_modify(item.name)+"-"+item.id ])
    }else{
      this.router.navigate(['/',this.slug_modify(item.name),this.slug_modify(subsubcat?.name)+'-'+subsubcat?.id]);
    }
  }
  logout(){
    this.storage.logout();
    this.socialAuthService.signOut()
    this.authService.logout().subscribe((data:any) =>{
      this.router.navigate(['/login'])

    })

  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.isActiveAutocomplete = false;
    }
  }
  slug_modify(text:any){
    return Utils.slug_modify(text);
  }

  ViewProduct(item: any) {
    this.router.navigate(['products/',item?.slug])
    this.isActiveAutocomplete = false
  }
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }


  onSliderChange($event: Event,poss:any) {
    // Iterate over the filterObject array
    this.updatePrice()

    this.scrollToTop()
  }
  updatePrice() {
    let found = false;
    for (let obj of this.filterObject) {
        // Check if the price object already exists in the array
        if (obj.price) {
            // Update high and low values
            obj.price.high = this.maxValue;
            obj.price.low = this.value;
            found = true;
            break; // Exit loop since the object has been found and updated
        }
    }

    // If the price object doesn't exist, push a new object to the array
    if (!found) {
        this.filterObject.push({ price: { high: this.value, low: this.maxValue } });
    }
  }

  colorModify(color:any){
    return color.replace("#",'')
  }
  selectColor(_t44: any) {
    if(this.selectedColor == _t44){
      this.selectedColor = ""
    }else{
      this.selectedColor = _t44.replace("#",'')
    }
    let found = false;
    for (let obj of this.filterObject) {
        // Check if the price object already exists in the array
        if (obj.color) {
            // Update high and low values
            obj.color = this.selectedColor;
            found = true;
            break; // Exit loop since the object has been found and updated
        }
    }

    // If the price object doesn't exist, push a new object to the array
    if (!found) {
        this.filterObject.push({ color:this.selectedColor });
    }
    // this.productFilterRjx.setFilter({price:{high:this.value,low:this.maxValue},color:this.selectedColor})

    this.scrollToTop()
  }
  selectedCategory(event:any){
    let found = false;
    for (let obj of this.filterObject) {
        // Check if the price object already exists in the array
        if (obj.category) {
            // Update high and low values
            obj.category = event;
            found = true;
            break; // Exit loop since the object has been found and updated
        }
    }

    // If the price object doesn't exist, push a new object to the array
    if (!found) {
        this.filterObject.push({ category:event });
    }

  }

  apply(){
    this.updatePrice()
    this.categoryFilterRjxService.setFilter(this.filterObject)
    this.hideProductFilter()
  }

}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'login-dialog.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
  ],
})
export class DialogContentExampleDialog {
  form!: FormGroup;
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      emailFormControl: ['', [Validators.email, Validators.required]],
      firstFormControl: ['', [Validators.required]],
      lastFormControl: ['', [Validators.required]],
      phoneFormControl: ['', [Validators.required]],
      passwordFormControl: ['', [Validators.required]],
    });
  }
  onSubmit(): void {}
  gotoSignUp() {
    this.router.navigate(['login']);
  }
  gotoHome() {
    this.router.navigate(['/']);
  }





}
