<!--MODAL-->

<div class="modal" data-modal *ngIf="!closeModal">

  <div class="modal-close-overlay" data-modal-overlay></div>

  <div class="modal-content">

    <button class="modal-close-btn" data-modal-close (click)="close()">
      <mat-icon>close</mat-icon>
    </button>

    <div class="newsletter-img">
      <!-- <img src="./assets/images/newsletter.png" alt="subscribe newsletter" width="400" height="400"> -->
    </div>

    <div class="newsletter">

      <form action="#" [formGroup]="newslaterForm">

        <div class="newsletter-header">

          <h3 class="newsletter-title">Subscribe Newsletter.</h3>

          <p class="newsletter-desc">
            Subscribe the <b>Maniyar Bnagles</b> to get latest products and discount update.
          </p>

        </div>

        <input type="email" name="email" class="email-field"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
        formControlName="email" noSpace placeholder="Email Address"  required>
        <div *ngIf="!newslaterForm.get('email')?.valid && newslaterForm.get('email')?.touched" class="email-sms">
            <small *ngIf="newslaterForm.get('email')?.errors?.['required']">
                <span class="text-danger mt-1">Email is required.</span>
            </small>
            <small *ngIf="newslaterForm.get('email')?.errors?.['pattern']">
                <span class="text-danger mt-1">Invalid email format.</span>
            </small>
        </div>

        <button type="submit" class="btn-newsletter" [disabled]="!newslaterForm.valid" (click)="Subscribe()">Subscribe</button>

      </form>

    </div>

  </div>

</div>
