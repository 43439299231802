import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonRxjsService } from 'src/app/_services/common-rxjs.service';
import { currency } from 'src/app/_services/constant';
import { ProductService } from 'src/app/_services/product.service';
import { Snackbar } from 'src/app/login/login.component';
import { SnackbarLayoutComponent } from '../snackbar-layout/snackbar-layout.component';
import { BackgroundService } from 'src/app/_services/background.service';
import { Utils } from 'src/app/_shared/Uitls';
import { StorageService } from 'src/app/_services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductViewComponent, layoutPermition } from './product-view/product-view.component';
import { ProductFilterRjxService } from 'src/app/_services/rjx/product-filter-rjx.service';
import { ColorRjxService } from 'src/app/_services/rjx/color-rjx.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { CommonModule, NgFor } from '@angular/common';
import { CategoryFilterRjxService } from 'src/app/_services/rjx/category-filter-rjx.service';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-product-box',
	templateUrl: './product-box.component.html',
	styleUrls: ['./product-box.component.css'],
})
export class ProductBoxComponent implements OnInit {



	throttle = 20;
	distance = 2000;
	stopScroll: boolean = false;
	skeleton:boolean = false
	skeletonData:any = []
	showFiller = false;

	@Input("route") route: any = 'products/recent';
	@Input("page") page: any = null;
	@Input("isSort") isSort: boolean = false;
	@Input("getProdyctByUrl") getProdyctByUrl: boolean = false;
	@Input("key") key: any;
	@Input("offerPrice") offerPrice: any;
	@Input("type") type: any;
	nextPage: any = 1;
	readyToNextPage: boolean = true;
	// price_low_to_high,price_high_to_low ,new_arrival ,popularity ,top_rated ,category ,brand ,shop
	sortType:any = "popularity"
	productListUrl: any = [];
	nextRoute: any;
	WishlistData: any;
	modalPermition: layoutPermition | undefined;
	searchKey: any;
	max: any = 50;
	min: any = 500000;
	color:any = null;
	dataToFilter: any = null;
	query: any = null;
	rate: any = 3.5;

	constructor(private _serv: ProductService,
		private _snackBar: MatSnackBar,
		private backgroundService: BackgroundService,
		private productFilterRjx:ProductFilterRjxService,
		private storage: StorageService,
		public dialog: MatDialog,
		private _route:Router,
		private _bottomSheet: MatBottomSheet,
		private colorRjx: ColorRjxService,
		private categoryFilterRjxService:CategoryFilterRjxService,
		private rxjs: CommonRxjsService) {
			this.skeletonData.length = 10
		 }
	currency: any = currency;
	productList: any = [];
	wishlist: any = [];

	goto(arg0: string) {
		this.scrollToTop()
		this._route.navigate([arg0])
	}
	ngOnInit(): void {
		this.scrollToTop()
		this.categoryFilterRjxService.categoryFilterData.subscribe((data:any) => {
			data.forEach((element:any) => {
				if(element?.price){
					this.max = element.price.high
					this.min = element.price.low
				}
				if(element?.color){
					this.color = element?.color;
				}
				if(this.type!="search"){
					this.productList = []
					this.skeleton = false
					this.getProducts()
				}else{
					this.productList = []
					this.skeleton = false
					this.searchProduct(this.key)

				}

			});

		})
		this.productFilterRjx.filter.subscribe((data:any) => {
			this.max = data?.price?.low;
			this.min = data?.price?.high;
			this.color = data?.color;
			this.page = 1;

			if(this.type!="search"){
				this.getProducts()
			}else{
				this.productList = []
				this.skeleton = false
				this.searchProduct(this.key)

			}
		})
		// if(this.type!="search"){
		// 	this.getProducts()
		// }else{
		// 	// this.searchProduct(this.key)
		// }
    if (typeof window !== 'undefined') {
      this.WishlistData = JSON.parse(localStorage.getItem('wishlist')!)
    }


	}

	openFilter(){
		this.rxjs.changeMessage({ type: 'productFilter', status: true });
	}
	filter(sortBy: string) {
		this.sortType = sortBy
		if(this.type!="search"){
			this.productList = []
			this.skeleton = false
			this.getProducts()
		}else{
			this.searchProduct(this.key)
		}
	}
	sortFilter(){
		var sortVar = this._bottomSheet.open(ProdictFilterSortBy,{
			data:{
				sortType:this.sortType
			}
		});
		sortVar.afterDismissed().subscribe((data:any) => {
			this.sortType = data?.sortType
			this.filter(this.sortType)
		})
	}
	alreadyWishlist(obj: any) {
    if (typeof window == 'undefined') {return false;}
		var stWil = localStorage.getItem('wishlist');
		if (stWil) {
			var arr = JSON.parse(stWil);
			if (arr.some((item: any) => item.product.id == obj.id)) {
				return true;
			}
		}
		return false;
	}
	removeWishList(o: any) {
    if (typeof window == 'undefined') {return;}
		this.storage.getWishlist().forEach((element: any) => {
			if (element?.type != "local" && o.id == element.product.id) {
				this.backgroundService.syncData({ wishlist: { status: true, data: o, permission: "remove", byproducts: true } })
			}
		});
		var obj = {
			id: Utils.generateRandomNumber(5),
			product: o
		}
		const filteredArray1 = JSON.parse(localStorage.getItem('wishlist')!).filter((item: any) => item.product.id !== obj.product.id);
		this.WishlistData = filteredArray1
		localStorage.setItem("wishlist", JSON.stringify(filteredArray1))
		let data = localStorage.getItem('wishlist');
		if (data) {
			if (JSON.parse(data).length == 0) {
				this.rxjs.setWichListCount("0")
			} else {
				this.rxjs.setWichListCount(JSON.parse(data).length)
			}
			this.snackBar("Wishlist removed successfully", "success")
		} else {
			this.rxjs.setWichListCount("0")
			this.snackBar("Wishlist updated successfully", "success")
		}
	}
	addOnWishlist(o: any) {
    if (typeof window == 'undefined') {return;}
		this.backgroundService.syncData({ wishlist: { status: true, data: o, permission: "add" } })
		var obj = {
			id: Utils.generateRandomNumber(5),
			product: o,
			type: "local"
		}
		var stWil = localStorage.getItem('wishlist');
		if (stWil) {
			var arr = JSON.parse(stWil);
			if (arr.some((item: any) => item.product.id == obj.product.id)) {
				this.snackBar("Wishlist already added", "error")
			}
			else {

				arr.push(obj);
				localStorage.setItem('wishlist', JSON.stringify(arr));
				let data = localStorage.getItem('wishlist');
				if (data) {
					this.rxjs.setWichListCount(JSON.parse(data).length)
					this.snackBar("Wishlist successfully added", "success")
				}
			}

		} else {
			this.wishlist.push(obj);
			localStorage.setItem('wishlist', JSON.stringify(this.wishlist));
			let data = localStorage.getItem('wishlist');
			if (data) {
				this.rxjs.setWichListCount(JSON.parse(data).length)
				this.snackBar("Wishlist successfully added", "success")
			}
		}
	}
	// https://javascript.plainenglish.io/infinite-scrolling-in-angular-a-hassle-free-implementation-f1fef11e44d1
	public getProducts(routeLink: any = null,type:any = null) {
		if (routeLink != null) {
			this.route = routeLink
			this.page = 1;
		}
		if(this.route=="products/recent"){
			this.page = null;
		}
		if(this.color!=null){
			this.dataToFilter = {
				"color": this.color,
			}
			this.commonGetProduct()
		}else{
			this.commonGetProduct()
		}



	}
	public commonGetProduct(){
		if(this.max!=null || this.sortType!=null || this.color!=null){
			if(this.route!="products/recent"){
				this.dataToFilter = {
					"price": "min="+this.min+"&max="+this.max,
					"color": this.color,
					"sortType": this.sortType
				}
				//console.log(this.dataToFilter);

			}
		}
		if(this.getProdyctByUrl){
			this._serv.getProductByUrl(this.route).subscribe((data) => {
				this.productList = data.data;
				this.skeleton = true;
			});
		}else{
			this._serv.getProducts(this.route, this.page,this.dataToFilter).subscribe((data) => {
				this.productList = data.data;
				this.productListUrl = data?.links?.next?.split("api/v1/")
				this.readyToNextPage = true;
				this.stopScroll = false;
				this.skeleton = true;
				if(data?.all_colors){
					this.colorRjx.setColor(data?.all_colors)
				}
			});
		}
	}
	getProductByUrl(url:any) {
		this._serv.getProductByCatrgory(url).subscribe((data) => {
			this.productList = data.data;
			this.skeleton = true
		});
	}
	public searchProduct(key: any = null,offerPrice:any = null) {
		this.searchKey = key
		this.page = 1;
		var dataToSend = {
			"key":this.searchKey,
			"scope": this.sortType,
			"page":this.page,
			"max": this.max,
  			"min": this.min,
  			"color": this.color,
		}
		if(this.offerPrice!=null){
			dataToSend.max = this.offerPrice;
			dataToSend.min = 0;
		}
		this._serv.searchProduct(dataToSend).subscribe((data) => {
			this.productList = data.data;
			this.productListUrl = data?.links?.next?.split("api/v1/")
			this.readyToNextPage = true;
			this.stopScroll = false;
			this.skeleton = true;

			this.colorRjx.setColor(data?.all_colors)
		});
	}

	getPage(str: any) {
		return str.split("?")[1].split("=")[1]
	}


	someMethod(): void {
	}
	snackBar(message: any, status: any) {
		this._snackBar.openFromComponent(SnackbarLayoutComponent, {
			duration: 3 * 1000,
			data: {
				message: message,
				status: status
			}
		});
	}

	onScroll() {
		if (this.productListUrl == undefined) {
			return;
		}
		if (this.productListUrl.length > 0) {

			if (this.stopScroll == false && this.readyToNextPage) {
				this.readyToNextPage = false;
				var actuveRoute = this.productListUrl[1].replace("?page=", "/")
				if(this.type!="search"){

					this._serv.getProductsPaginate(actuveRoute,this.dataToFilter).subscribe((data) => {
						if (data.links.next == null) {
							this.stopScroll = true
						} else {
							this.productListUrl = data?.links?.next?.split("api/v1/")
							this.readyToNextPage = true;
							this.colorRjx.setColor(data?.all_colors)

							this.nextRoute = this.productListUrl[1].replace("?page=", "/")
						}

						this.productList.push(...data.data);
					});
				}else{
					var routeArr = this.productListUrl[1].replace("?page=", "/").split("&");
					this.page = routeArr[routeArr.length-1].split("=")[1]
					var dataToSend = {
						"key":this.searchKey,
						"scope": "price_low_to_high",
						"page":this.page,
						"max": this.max,
  						"min": this.min,
			  			"color": this.color,

					}
					this._serv.searchProduct(dataToSend).subscribe((data) => {
						if (data.links.next == null) {
							this.stopScroll = true
						} else {
							this.productListUrl = data?.links?.next?.split("api/v1/")
							this.readyToNextPage = true;
							// this.nextPage = this.getPage(data.links.next)

							this.nextRoute = this.productListUrl[1].replace("?page=", "/")
						}

						this.productList.push(...data.data);

					});
				}


			}
		}
	}
	viewProducts(data: any,permission:any) {

		const dialogRef = this.dialog.open(ProductViewComponent, {
			data: {
				data:data,
				permission:permission
			}
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}

	setDefaultImage(i:any) {
		i.target.src = Utils.NO_IMAGE;
	}
	productImage(url:any){
		return ImageUrlService.getImageUrl(url,true);
	}
	scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

}


@Component({
	selector: 'bottom-sheet-overview-example-sheet',
	templateUrl: './filter.html',
	standalone: true,
	imports: [MatListModule,CommonModule,NgFor],
  })
  export class ProdictFilterSortBy {
	selectedSortType:any = ""
	@ViewChild('option')
	option!: MatSelectionList;
	sortType:any = [
		{
			key:"popularity",
			value:"Popularity"
		},
		{
			key:"price_low_to_high",
			value:"Price - Low to High"
		},
		{
			key:"price_high_to_low",
			value:"Price - High to Low"
		},
		{
			key:"new_arrival",
			value:"Newest First"
		}]
	constructor(private _bottomSheetRef: MatBottomSheetRef<ProdictFilterSortBy>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
			this.selectedSortType = data?.sortType
		}

	openLink(event: any): void {

	  this._bottomSheetRef.dismiss({sortType:this.option.selectedOptions.selected[0].value});
	}
  }
