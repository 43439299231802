import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noWhiteSpace]'
})
export class NoWhiteSpaceDirective {

  constructor() { }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.trimLeft();
    const sanitizedValue = inputValue.replace(/\s+/g, ' '); // Replace consecutive white spaces with a single space
    event.target.value = sanitizedValue;
  }
}
