<!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
<h2>{{data?.title}}</h2>
<div class="container">
    <div class="row">
        <div class="col-sm-6"></div>
        <div class="col-sm-6"></div>
    </div>
</div>
<div class="d-flex w-100 cropper-lyt">
    <image-cropper
    class="cropper"
    [imageChangedEvent]="imageChangedEvent"
    [imageBase64]="imageBase64"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>

    
    <!-- <img [src]="croppedImage" class="w-50"/> -->
</div>
<button mat-fab color="primary" class="crop-btn" (click)="cropImage()" aria-label="Example icon button with a delete icon">
    <mat-icon>crop</mat-icon>
  </button>