import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/_services/product.service';
import { ImageProcessingProductService } from 'src/app/_services/rjx/image-processing-product.service';
import Swal from 'sweetalert2';
import { ImageCroperComponent } from '../image-croper/image-croper.component';

@Component({
  selector: 'app-image-processing-search',
  templateUrl: './image-processing-search.component.html',
  styleUrls: ['./image-processing-search.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ImageProcessingSearchComponent {

  @ViewChild('videoElement') videoElement!: ElementRef;
  @ViewChild('canvasElement') canvasElement!: ElementRef;
  isCameraStop:any = false;
  private stream!: MediaStream;
  isStreaming:boolean = false;
  cameraPoss:any = "user"
  progress: number = 0;
  isUploading:boolean = false;
  uploadFileLyt: boolean = true;
  isStream: boolean = false;
  permissionStatus: any;
  origin: any;
  image: any;


  constructor(private product: ProductService,
    private router:Router,
    public dialogRef: MatDialogRef<ImageProcessingSearchComponent>,
    private imageProcess:ImageProcessingProductService,
		public dialog: MatDialog,

  ) { }

  initCamera(deviceId: string) {
    this.origin = window.location.origin;
    this.cameraPoss = deviceId
    this.isStreaming = false;
    this.isStream = false;
    navigator.mediaDevices.getUserMedia({ video: { facingMode: deviceId ,frameRate: 15,
      height: { min: 480, ideal: 500, max: 500},
      aspectRatio: 1.7777777778} })
      .then((stream) => {
        this.videoElement.nativeElement.srcObject = stream;
        this.stream = stream;
        this.isCameraStop = false;
        this.isStreaming = true;
        this.isStream = true;
        this.permissionStatus = 'Granted';

      })
      .catch((error) => {
        console.error('Error accessing the camera: ', error);
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          this.permissionStatus = 'Denied';
          Swal.fire({
            title: "Please grand camera permission?",
            html:`
              <div style='text-align: justify;'>
                <p>Camera permission is required for this website to function properly.</p>
                <p>Please manage your browser settings to enable camera access.</p>
                <p>Instructions:</p>
                <ol>
                  <li>Open your browser settings.</li>
                  <li>Navigate to the site settings or permissions section.</li>
                  <li>Find and allow camera access for the website: <strong>`+this.origin +`</strong></li>
                </ol>
              </div>
            `,
            icon: "warning",
            confirmButtonText:"Grant",
            denyButtonText:"Denied",
            showDenyButton:true,
            cancelButtonText:"Open setting",
            showCancelButton:false
          }).then((res) => {
            if(res.isDismissed){
              // window.location.hash = '//settings/content/siteDetails?site='+this.origin
              const settingsUrl = `brave://settings/content/siteDetails?site=${encodeURIComponent(this.origin)}`;
              window.open(settingsUrl, '_blank');
            }
            if(res.isConfirmed){
              this.initCamera("environment")
            }
            if(res.isDenied){
              this.dialogRef.close()
            }
          })
        } else {
          this.permissionStatus = 'Error';
        }
      });
  }
  uploadFile() {
    this.uploadFileLyt = !this.uploadFileLyt;
    if(!this.uploadFileLyt){
      this.initCamera("environment");
    }else{
      this.closeCamera()
    }
  }
  changeCamera(deviceId:any) {
    this.closeCamera(); // Close existing stream
    this.initCamera(deviceId); // Initialize new camera stream
    this.isStreaming = false;
  }

  toggleCamera() {
    this.closeCamera(); // Close the current camera stream
    this.initCamera("user"); // Open the new camera stream
    this.isStreaming = false;

  }
  base64ImageUpload(base64:any){

    const base64Image = base64.split(',')[1];
    const binaryImage = atob(base64Image);

    // Convert binary data to Blob
    const array = new Uint8Array(binaryImage.length);
    for (let i = 0; i < binaryImage.length; i++) {
      array[i] = binaryImage.charCodeAt(i);
    }
    const blob = new Blob([array], { type: 'image/png' });

    // Create FormData object and append blob
    const formData = new FormData();
    formData.append('file', blob, 'image.png');

    // Upload to server using HttpClient
    this.product.imageProcessing(formData)
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * event.loaded) / event.total!);
          } else if (event.type === HttpEventType.Response) {
            //console.log('Image uploaded successfully:', event.body);
            this.isUploading = false;
            localStorage.setItem("setImageProcess",JSON.stringify({image:this.image,data:event.body}))
            this.imageProcess.setImageProcess({image:this.image,data:event.body})
            this.router.navigate(['image-processing-product'])
            this.dialogRef.close()

          }
        },
        (error) => {
          console.error('Error uploading image:', error);
        }
      );
  }
  // Method to capture image from camera
  capture() {
    this.closeCamera()
    this.isUploading = true
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;

    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas content to a data URL representing a PNG
    const dataURL = canvas.toDataURL('image/png');

    this.image = dataURL;
    const dialogRef = this.dialog.open(ImageCroperComponent,{
			data:{
				image:this.image,
				title:"Product matching colour"
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.base64ImageUpload(result?.image);
		});
    // this.base64ImageUpload(dataURL);
  }

  // Initialize camera stream when component initializes
  ngOnInit() {
    // this.initCamera("user");
  }
  closeCamera() {
    // Stop the media stream
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
      this.isCameraStop = true
      this.isStreaming = false;


    }
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    this.uploadFiles(files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.uploadFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  private uploadFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      
      // Create a new FileReader object
      const reader = new FileReader();
  
      // Define the onload event handler
      reader.onload = () => {
        // Once the file is read successfully, the result will be a base64 encoded string
        const base64String = reader.result as string;
        
        this.image = base64String;
        this.isUploading = true;
        const dialogRef = this.dialog.open(ImageCroperComponent,{
          data:{
            image:this.image,
            title:"Product matching colour"
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          this.base64ImageUpload(result?.image);
        });
        // this.base64ImageUpload(base64String);

      };
  
      // Read the file as a Data URL (base64 encoded)
      reader.readAsDataURL(file);
    }
  }
}
