import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

const AUTH_API = environment.apiUrl;;

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': 'true'
    }),
    withCredentials: true
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  

  version:any = "v1/";

  constructor(private http: HttpClient,
    private localStorage: StorageService) { }

  login(username: string, password: string): Observable<any> {
    var data = {
      "email": username,
      "password": password
    };
    return this.http.post(environment.apiUrl+this.version+"login",data,this.getHeader());
  }
  socialAuth(resToServer: any) {
    return this.http.post(environment.apiUrl+this.version+"social-login",resToServer,this.getHeader());
  }


  resetPassword(email: any) {
    return this.http.get(environment.apiUrl+'auth/'+"send-reset-password-link/"+email);
  }

  updateResetPassword(password: string, token: string) {
    return this.http.get(environment.apiUrl + 'auth/send-reset-password-link/' + token  + '/' + password);
  }


  register(username: string, email: string, password: number): Observable<any> {
    return this.http.get<any>(
      'assets/apis/signup.json',
      httpOptions
    );
  }
  verifyAccount(d:any) {
    var data = {
      "email": d
    };
    return this.http.post(environment.apiUrl+this.version+"verifyAccount",JSON.stringify(d),this.getHeader());
  }
  verifyOtp(otp:any) {
    return this.http.get(environment.apiUrl+"otp/verify/"+otp);
  }

  signup(email: any) {
    var data = {
      "email": email
    };
    return this.http.post(environment.apiUrl+this.version+"signup",data,this.getHeader());
  }

  logout(){
    return this.http.post(environment.apiUrl+this.version+"logout", null,httpOptions);
  }

  getHeader(){
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': 'true',

        }),
        withCredentials: true
    };
  }
}
