import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { Router } from '@angular/router';
import { CartService } from 'src/app/_services/cart.service';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { StorageService } from 'src/app/_services/storage.service';
import { WalletService } from 'src/app/_services/wallet.service';
import { WindowRefService } from 'src/app/_services/window-ref.service';
import { PriceDetailsComponent } from 'src/app/layout/price-details/price-details.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.css']
})
export class OrderReviewComponent implements OnInit{
  addedItems: any = [];
  currency = currency
  @ViewChild('option') option!: MatSelectionList;

  @ViewChild("priceDetails", { static: false }) priceDetails!: PriceDetailsComponent;

  getSelectedAddress:any = null

  totalPrice:any = ""
  totalDiscountPrice:any = ""
  Deliverycharge:any = ""
  orderReview:any = ""

  paymentOption: any = [
    {
      "name": "Payment with UPI",
      "value": "upi",
      "logo":"/assets/images/payment/upi.png"
    }, {
      "name": "Net banking",
      "value": "netbanking",
      "logo":"/assets/images/payment/netbanking.png"

    }, {
      "name": "Debit card and Credit card",
      "value": "card",
      "logo":"/assets/images/payment/visa.png"

    },{
      "name": "Cash on delivery (COD)",
      "value": "cod",
      "logo":"/assets/images/payment/cod.png"
    },
    {
      "name": "Wallet",
      "value": "wallet",
      "logo":"/assets/images/payment/wallet.png"
    }
  ];

  steperDuration = '1000';
  itemCount: number = 0;
  isOptional: boolean = false;
  selectdOptions: any = "";
  totalWlletBalance: any = -1;


  constructor(private _formBuilder: FormBuilder,
    private storage:StorageService,
    private router: Router,
    private cartService:CartService,
    private walletService:WalletService,
    private windowRefService: WindowRefService) { }
  ngOnInit(): void {
    this.getSelectedAddress = this.storage.getAddress()[0];
    this.getCartList();
    this.priceDetails.setAddress(this.getSelectedAddress)

  }
  firstFormGroup: FormGroup = this._formBuilder.group({ firstCtrl: [''] });
  secondFormGroup: FormGroup = this._formBuilder.group({ secondCtrl: [''] });

  getCartList(){
    this.cartService.getCartCateorys().subscribe((data:any) => {
      this.addedItems = data.data
      this.getCartPrice()
      this.priceDetails.setAddedItems(this.addedItems)
      if(this.addedItems.length==0){
        this.router.navigate(['/cart'])
      }
    })
    this.priceDetails.setDeliverycharge(0)
  }
  selectPayment(options:any){
    this.selectdOptions = options;
    if(options=="wallet"){
      this.walletService.getBalance().subscribe((data:any) => {
        this.totalWlletBalance = data[0]?.mb_pay_amount + data[0]?.shopping_wallets_amount;
        //console.log(data);

      })
    }
    if(options=="cod"){
      Swal.fire({
        title: "You have select Cash on Delivery (COD)",
        html: `
         <span style="color:'red'">Notice: Nominal Handling Fee of ${currency} 10 Applicable + Courier Charges ${currency} 60</span>
        `,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Continue with ${currency} 70`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.priceDetails.setDeliverycharge(70)

        } else {
          this.option.deselectAll()
          this.selectdOptions = ""
        }
      });
    }else{
      this.priceDetails.setDeliverycharge(0)
    }
  }

  paymentOptionChnage(event:any){
    if(event?.source._value[0]=="cod"){
      Swal.fire({
        title: "You have select Cash on Delivery (COD)",
        html: `
         <span style="color:'red'">Notice: Nominal Handling Fee of ${currency} 10 Applicable + Courier Charges ${currency} 60</span>
        `,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Continue with ${currency} 70`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.priceDetails.setDeliverycharge(70)

        } else {
          this.option.deselectAll()
        }
      });
    }else{
      this.priceDetails.setDeliverycharge(0)
    }

  }
  disableSelection(e:boolean){
    this.option.disabled = e;
  }
  getCartPrice() {
    this.totalPrice = 0;
    this.addedItems.forEach((item: any) => {
      this.totalPrice += item.qty * item.product.base_discounted_price;
    });
    this.addedItems.forEach((item: any) => {
      this.itemCount += item.qty;
    });
    this.priceDetails.setItemCount(this.itemCount)
    this.getCartDiscountPrice();
    this.priceDetails?.setTotalPrice(this.totalPrice)
    return this.totalPrice;
  }
  getCartDiscountPrice() {
    this.totalDiscountPrice = 0;
    this.addedItems.forEach((item: any) => {
      this.totalDiscountPrice += item.qty * item.product.base_price;
    });
    this.totalDiscountPrice -= this.totalPrice;
    this.priceDetails.setTotalDiscountPrice(this.totalDiscountPrice)
    return this.totalDiscountPrice;
  }
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  getImage(img:any){
    return ImageUrlService.getImageUrl(img);
  }

}

