import { Injectable } from '@angular/core';
import { tokenKey,USER_KEY } from './constant';
import { CartStorageService } from './cart-storage.service';
import { AddressService } from './localstorage/address.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends CartStorageService{
  constructor() {
    super()
  }

  clean(): void {
    if (typeof window !== 'undefined') {
      window.localStorage.clear();
    }
  }

  public saveUser(data: any): void {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(USER_KEY);
      const token = JSON.stringify({
        "old_token":data?.access_token,
        "jwt":data?.v2Data?.tokenType+" "+data?.v2Data?.token,
        "user":data?.user
      })
      window.localStorage.setItem(tokenKey, this.encrypt(token, "password"));
      window.localStorage.setItem(USER_KEY, JSON.stringify(data.data));
    }

  }
  public getRootToken(){
    if (typeof window !== 'undefined') {
      return this.encodeBase64(window.localStorage.getItem(tokenKey));
    }
    return null;
  }
  tokenModify(){
    if (typeof window !== 'undefined') {
      var user = window.localStorage.getItem(tokenKey);
      if(user){
        return JSON.parse(this.decrypt(user,"password"));
      }
    }
    return null;
  }
  public getUser(): any {
    if (typeof window !== 'undefined') {

      var user = window.localStorage.getItem(tokenKey);
      if(user){
        return this.tokenModify()?.user;
      }else{
        return null;
      }
    }
    return null;
  }
  public getToken(){
    return this.tokenModify()?.jwt;
  }
  public getOldToken(){
    return this.tokenModify()?.old_token;
  }
  public getRole():any{
    var roles = this.getUser()?.roles;
    var role = "";
    for(var i=0; i<roles.length; i++){
      role += roles[i].name.replace("ROLE_","")+" ";
    }
    return role;

  }

  public isLoggedIn(): boolean {
    if (typeof window !== 'undefined') {
      const user = window.localStorage.getItem(USER_KEY);
      if (user) {
        return true;
      }
    }


    return false;
  }

  public setModal(status:any = "true"){
    if (typeof window !== 'undefined') {
      localStorage.setItem("modal",status);
    }
  }
  public logout(){
    this.setModal("true")
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }


  }
  public isModalNeed(){
    if (typeof window !== 'undefined') {
      if(localStorage.getItem("modal")!=undefined){
        var modal = JSON.parse(localStorage.getItem("modal")!);
        if(modal.hide==true){
          const currentTime:any = new Date();
          const providedTime:any = new Date(modal.time);
          const timeDifference = providedTime - currentTime;
          const minutesFromNow = Math.abs(Math.floor(timeDifference / (1000 * 60)));
          if(minutesFromNow>180){
            return false;
          }else{
            return true;
          }
        }else{
          return false;
        }

      }
    }

    return false;
  }

  //wishlist
  getWishlist(){
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem('wishlist')!);
    }
    return JSON.stringify({})
  }
  addWishList(arr:any){
    if (typeof window !== 'undefined') {
      localStorage.setItem('wishlist', JSON.stringify(arr));
    }
  }
  encodeBase64(data:any) {
    return btoa(data);
  }



}
