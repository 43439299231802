import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { ProductService } from 'src/app/_services/product.service';
import { ProductViewComponent } from '../product-box/product-view/product-view.component';

@Component({
  selector: 'app-flash-deals',
  templateUrl: './flash-deals.component.html',
  styleUrls: ['./flash-deals.component.css']
})
export class FlashDealsComponent {
  title: any  = "";

  currency: any = currency
  toDayDealsList: any = [];
  end_date: any;
  countDown: { days: number; hours: number; minutes: number; seconds: number; } | undefined;

  constructor(
    private _serv: ProductService,
		public dialog: MatDialog,

  ) {

  }
  ngOnInit(): void {
    this._serv.flashDeal().subscribe((data) => {
      this.title = data?.data?.title
      this.end_date = data?.data?.end_date;
      this.toDayDealsList = data.data?.products?.data;
      if (typeof window !== 'undefined') {
        setInterval(() => {
          this.convertTimestamp()
        }, 1000);
      }

    });

  }
  obj(obj: any) {
    var data = JSON.parse(obj)

    return data[0].values;
  }

  productImage(url:any){
		return ImageUrlService.getImageUrl(url);
	}
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  convertTimestamp() {
      // Assuming start_date is the current timestamp (in seconds)
      const startTimestamp = Math.floor(Date.now() / 1000);

      // Assuming end_date is 1714156200 (in seconds)
      const endTimestamp = this.end_date;

      // Convert timestamps to JavaScript Date objects
      const startDate:any = new Date(startTimestamp * 1000); // Convert seconds to milliseconds
      const endDate:any = new Date(endTimestamp * 1000); // Convert seconds to milliseconds

      // Calculate the difference in milliseconds between the two dates
      const difference = endDate - startDate;

      // Convert the difference to days, hours, minutes, and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);


      this.countDown =  { days: days, hours: hours, minutes: minutes, seconds: seconds };

  }
  viewProducts(data: any,permission:any) {

		const dialogRef = this.dialog.open(ProductViewComponent, {
			data: {
				data:data,
				permission:permission
			}
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}

}
