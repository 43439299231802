import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  resetPasswordForm!: FormGroup;
  progressSatus: number = 0;

  constructor(private formBuilder: FormBuilder,
    private authservice: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }


  onSubmit() {
    if (this.resetPasswordForm.valid) {
      //  Assuming you have a service to handle password reset
      this.progressSatus = 1;
      this.authservice.resetPassword(this.resetPasswordForm.controls['email'].value).subscribe(
        () => {
          // Password reset successful, show SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'Password Reset Successful',
            text: 'Your password has been reset successfully.',
            confirmButtonText: 'OK'
          }).then((data) => {
            if(data.isConfirmed){
              this.router.navigate(['/login']);
            }
          });
          // Reset the form after successful submission
          this.progressSatus = 2;

          this.resetPasswordForm.disable();
        },
        (error: any) => {
          // Handle error if password reset fails
          console.error('Password reset failed:', error);
          // Show SweetAlert for error
          Swal.fire({
            icon: 'error',
            title: 'Password Reset Failed',
            text: 'An error occurred while resetting your password. Please try again later.',
            confirmButtonText: 'OK'
          })
          this.progressSatus = 0;
        }
      );
    }


  }

  backtoLogin() {
    this.router.navigate(['login']);
  }
}

