<!-- <h1 style="margin-bottom: 0;">login successfull</h1> -->
<!-- <mat-icon>check_circle_outline</mat-icon> -->
<span class="w-100 bg-danger p-2" *ngIf="data.status=='error'">
    <div class="snbar" >
        <p>{{data.message}}</p>
        <button (click)="remove()">OK</button>
    </div>
</span>
<span class="w-100 bg-success p-2" *ngIf="data.status=='success'">
    <div class="snbar" >
        <p>{{data.message}}</p>
        <button (click)="remove()">OK</button>
    </div>
</span>
<span class="w-100 bg-warning p-2" *ngIf="data.status=='warning'">
    <div class="snbar" >
        <p>{{data.message}}</p>
        <button (click)="remove()">OK</button>
    </div>
</span>