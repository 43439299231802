import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';

import { ModuleRoutingModule } from './module-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { RootComponent } from './root/root.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { InputLengthCounterDirective } from '../_shared/directive/input-length-counter.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { BottomSheetAddress, CartComponent } from './cart/cart.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { OrderReviewComponent } from './order-review/order-review.component';
import { MatStepperModule } from '@angular/material/stepper';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { TrackYourOrderComponent } from './track-your-order/track-your-order.component';
import { RouterOutlet } from '@angular/router';
import { NgOptimizedImage } from '@angular/common'

@NgModule({
  declarations: [
    RootComponent,
    HomeComponent,
    ProductsComponent,
    InputLengthCounterDirective,
    CartComponent,
    BottomSheetAddress,
    OrderReviewComponent,
    OrderConfirmationComponent,
    TrackYourOrderComponent,

  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    ModuleRoutingModule,
    LayoutModule,
    MatProgressBarModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatDividerModule,
    MatMenuModule,
    MatListModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxImageZoomModule,
  ],
})
export class ModuleModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    //console.log(`Running ${platform} with appId=${appId}`);
  }
 }
