import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { StorageService } from '../_services/storage.service';
import { EventBusService } from '../_shared/event-bus.service';
import { EventData } from '../_shared/event.class';
import { LoadingService } from './loading.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private storageService: StorageService,
     private eventBusService: EventBusService,
     private loadingService: LoadingService
     ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.show();
    req = req.clone({
      withCredentials: true,
    });
    if (!req.url.includes('login')) {
      // Clone the request and add the Authorization header
      req = req.clone({
        setHeaders: {
          Authorization: `${this.storageService.getToken()}`,
        },
      });
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.hide();
        console.clear();
        // //console.log("\x1b[1m\x1b[38;5;36mWelcome to maniyar bangles online shopping application.\x1b[0m");

      }),
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/signin') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );

//     return next.handle(req).pipe(
//       catchError((error) => {
//         // logout when token is expired
// /*
//         if (
//           error instanceof HttpErrorResponse &&
//           !req.url.includes('auth/signin') &&
//           error.status === 401
//         ) {
//           return this.handle401Error(req, next);
//         }
// */
//         return throwError(() => error);
//       })
//     );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.storageService.isLoggedIn()) {
        this.eventBusService.emit(new EventData('logout', null));
      }
    }

    return next.handle(request);
  }
}

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
