import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-layout',
  templateUrl: './snackbar-layout.component.html',
  styleUrls: ['./snackbar-layout.component.css']
})
export class SnackbarLayoutComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  private snackBarRef: MatSnackBarRef<SnackbarLayoutComponent>) { 
  }

  remove(){
    this.snackBarRef.dismiss()
  }

}
