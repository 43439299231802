import { Component } from '@angular/core';
import { Common } from 'src/app/_shared/Common';
import { Utils } from 'src/app/_shared/Uitls';

@Component({
  selector: 'app-notification-tost',
  templateUrl: './notification-tost.component.html',
  styleUrls: ['./notification-tost.component.css']
})
export class NotificationTostComponent {
  closeModal: boolean = true;
  showNotifications: boolean = false;
  defaultResetTime:number = 8000;
  productData: any;
  constructor(private common:Common,) {

  }
  ngOnInit(): void {
    if (typeof document !== 'undefined') {
      this.common.eventListener().subscribe((data:any) => {
        console.log("sajfhlajshfkja "  , data);
        if(data){
          var eventData = data;
          if(eventData?.type=="order_product"){
            this.productData = data?.data
            this.showNotifications = true
            this.defaultResetTime = 8000
            setInterval(() => {
              if(this.showNotifications){
                this.showNotifications = false
              }
            }, this.defaultResetTime);
          }
        }
      })

    }

  }

  close(){
    this.showNotifications = false
  }
  gotoProduct(slug:any){

  }
  timeAgo(timestamp: number){
    return Utils.timeAgo(timestamp)
  }
}
