import { Component, Input, OnInit } from '@angular/core';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { ProductService } from 'src/app/_services/product.service';

@Component({
  selector: 'app-horizontal-product-view',
  templateUrl: './horizontal-product-view.component.html',
  styleUrls: ['./horizontal-product-view.component.css']
})
export class HorizontalProductViewComponent implements OnInit{

	currency: any = currency;

	@Input("route") route: any = 'products/related/315';
  productList: any = [];

  constructor(private _serv: ProductService,) {
		 }


  ngOnInit(): void {
    // this.getProduct()
  }
}
