import { Component, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UpdateAddressForm } from '../profile/address-management/address-management.component';
import { StorageService } from 'src/app/_services/storage.service';
import { currency } from 'src/app/_services/constant';
import { CartService } from 'src/app/_services/cart.service';
import { UserService } from 'src/app/_services/user.service';
import { MatSelectionListChange } from '@angular/material/list';
import { SnackbarLayoutComponent } from 'src/app/layout/snackbar-layout/snackbar-layout.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PriceDetailsComponent } from 'src/app/layout/price-details/price-details.component';
import Swal from 'sweetalert2';
import { AddressRjxService, addressRjx } from 'src/app/_services/rjx/address-rjx.service';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { CartRjxService } from 'src/app/_services/rjx/cart.service';
import { PromocodeActionService } from 'src/app/_services/rjx/promocode-action.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent {

  currency = currency
  addedItems: any = []
  skeletonLen: any = [1,2,3,4,5]
  totalPrice: number = 0;
  totalDiscountPrice: number = 0;
  isSkeleton:boolean = false;
  Deliverycharge:number = 0;
  selectedAddress: any = [];
  totalCartItem: number = 0;

  @ViewChild("priceDetails", { static: false }) priceDetails!: PriceDetailsComponent;
  addressValid: boolean = false;;

  constructor(private _bottomSheet: MatBottomSheet,
    private cartService:CartService,
    private router:Router,
    private addressRjx : AddressRjxService,
    private _snackBar: MatSnackBar,
    private cartCount:CartRjxService,
    private promocodeActionService:PromocodeActionService,
    public storage: StorageService,) {

      this.addressRjx.observer.subscribe((data:addressRjx) =>{
        if(data){

          if(data?.addingData==false){
            this.selectedAddress = [data?.data];

          }
        }

      })

    }

  ngOnInit(): void {
    this.cartCount.cart.subscribe((data:any) => {
      if(data!=null){
        this.totalCartItem = data
      }
    })
    this.getCart()
    this.priceDetails?.setDeliverycharge(0)

  }
  updateItem(){
    var itemCount = 0;
    if(this.addedItems){
      this.addedItems.forEach((item: any) => {
        itemCount += item.qty;
      });
    }
    this.priceDetails?.setItemCount(itemCount)
  }
  gotoReviewPage() {
    this.storage.addAddress(this.selectedAddress)
    this.router.navigate(['/order-review'])
  }
  getCart() {
    if(this.storage.isLoggedIn()){
      //TODO:: get data from server # do not delete this comment
      this.getCartList();
    }else{
      this.getCartFromLocalStorage();
      this.getCartPrice();
    }
    this.priceDetails?.setAddedItems(this.addedItems)
  }
  getCartFromLocalStorage() {
    if(JSON.parse(this.storage.getCartData())!=null){
      this.addedItems = JSON.parse(this.storage.getCartData())
      this.totalCartItem = this.addedItems.length
      this.cartCount.setCartCount(this.totalCartItem)
    }
    this.isSkeleton = true
  }

  getCartList(){
    this.isSkeleton = false;
    this.cartService.getCartCateorys().subscribe((data:any) => {
      this.isSkeleton = true;
      this.addedItems = data.data
      var pid:any = []
      this.addedItems.forEach((element:any) => {
        pid.push(element?.product.id)
      });
      this.priceDetails.setProductIds(JSON.stringify(pid))


      this.getCartPrice();
    })
  }
  validation(event:any){
    if(this.addressValid){
      this.addressValid = false;

    }else{
      this.addressValid = true;
      setTimeout(() => {
        this.addressValid = false;
      }, 2000);
    }
  }

  removeCart(cartId:any){
    this.isSkeleton = false;
    this.cartService.removeTocartCateorys(cartId).subscribe((data:any) => {
      this.isSkeleton = true;
      this.snackBar(data.message,"success")
      this.cartCount.setCartCount(--this.totalCartItem)
      this.getCart()
    })

  }

  addTocartServer(){
    this.storage.getCartDataList().forEach((e:any) => {
      var data = {
        "productId": e.product?.id,
        "variant": e.variant,
        "qty": e.qty
      }
      this.cartService.addTocartCateorys(data).subscribe((data:any) => {
        this.isSkeleton = true;
        this.storage.removeItem(e);
        this.getCartList();
      })
    })

  }


  openBottomSheet(): void {
    var bottomSheetRef = this._bottomSheet.open(BottomSheetAddress);
    bottomSheetRef.afterDismissed().subscribe((data: any) => {
      if(data){

        this.selectedAddress = data;
        this.priceDetails?.setAddedItems(data)
        //console.log('Received data from bottom sheet:', data);
      }

      // Handle the received data here (e.g., pass it to another component)
    });
  }

  addQty(cartItem:any){
    this.addedItems.forEach((element: any) => {
      if(element.id==cartItem.id && element.variant==cartItem.variant){
        // Update the quantity
        if(cartItem?.product?.total_qty>element.qty){

          if(this.storage.isLoggedIn()){
            this.isSkeleton = false;
            //update qty to server
            var dataToServer = {
              id:cartItem?.id,
              quantity:element.qty+1
            }
            this.cartService.updateCartCategory(dataToServer).subscribe((data:any) => {
              this.isSkeleton = true;
              if(data?.status==true){
                element.qty++;
                this.snackBar(data?.message,"success")
                this.getCartPrice();
              }else{
                Swal.fire({
                  text: data?.message,
                  icon: "error"
                });
              }
            })
          }else{
            element.qty++;
            this.storage.updateQty(cartItem)
            this.getCartPrice();
          }

        }
      }
    });
  }
  removeQty(cartItem:any){
    this.addedItems.forEach((element: any) => {
      if(element.id==cartItem.id && element.variant==cartItem.variant){
        // Update the quantity
        if(element.qty>1){
          if(this.storage.isLoggedIn()){
            //update to server

            this.isSkeleton = false;
            //update qty to server
            var dataToServer = {
              id:cartItem?.id,
              quantity:element.qty-1
            }
            this.cartService.updateCartCategory(dataToServer).subscribe((data:any) => {
              this.isSkeleton = true;
              if(data?.status==true){
                this.promocodeActionService.updatePromocode({reset:true})
                element.qty--;
                this.snackBar(data?.message,"success")
                this.getCartPrice();
              }else{
                Swal.fire({
                  text: data?.message,
                  icon: "error"
                });
              }
            })
          }else{
            element.qty--;
            this.storage.updateQty(cartItem,'desc')
          }

          this.getCartPrice();
        }
      }
    });
  }
  delete(cartItem:any){


    if(this.storage.isLoggedIn()){
      this.addedItems = this.addedItems.filter((element: any) => {
          return !(element.id === cartItem.id && element.variant === cartItem.variant);
      });

      this.removeCart(cartItem?.id)
    }else{
      this.storage.removeItem(cartItem);
      this.cartCount.setCartCount(--this.totalCartItem)
      this.getCartFromLocalStorage();
    }
    this.getCartPrice();

  }

  getCartPrice() {
    this.totalPrice = 0;
    if(this.addedItems){
      this.addedItems.forEach((item: any) => {
        this.totalPrice += item.qty * item.product.base_discounted_price;
      });
    }
    this.getCartDiscountPrice();
    this.priceDetails?.setTotalPrice(this.totalPrice)
    this.updateItem()
    return this.totalPrice;
  }
  getCartDiscountPrice() {
    this.totalDiscountPrice = 0;
    this.addedItems.forEach((item: any) => {
      this.totalDiscountPrice += item.qty * item.product.base_price;
    });
    this.totalDiscountPrice -= this.totalPrice;
    this.priceDetails?.setTotalDiscountPrice(this.totalDiscountPrice)
    return this.totalDiscountPrice;
  }

  snackBar(message: any, status: any) {
		this._snackBar.openFromComponent(SnackbarLayoutComponent, {
			duration: 3 * 1000,
			data: {
				message: message,
				status: status
			}
		});
	}
  getImage(img:any){
    return ImageUrlService.getImageUrl(img);
  }


  saveFor(cartItem:any) {
    this.isSkeleton = false;
    this.cartService.saveForLater(cartItem.id).subscribe((data:any)=>{
      this.isSkeleton = true;
      this.cartCount.setCartCount(--this.totalCartItem)
      if(data.status = true){
        this.getCart()
        this.snackBar(data?.message,"success")
      }else{
        this.snackBar(data?.message,"error")
      }
    })

  }



}


@Component({
  selector: 'bottom-sheet-address',
  templateUrl: 'bottomSheet.component.html',
  styleUrls: ['./bottomSheet.component.css'],

})
export class BottomSheetAddress {

  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  GeoAddressPresent: boolean = false;
  GeoAddress: any;
  address: any = []
  selectedItem: any;
  selectedItemId: any;
  showAddAddress:boolean = true
  openGeoLocation() {
    this.GeoAddressPresent = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getUserLocation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetAddress>,
    private userService:UserService,
    private addressRjx : AddressRjxService,
    private _bottomSheet: MatBottomSheet) {
      this.addressRjx.observer.subscribe((data:addressRjx) =>{
        if(data){
          if(data?.addingData==true){
            this.GeoAddressPresent = false;

          }else{
            this.GeoAddressPresent = true;

          }
        }

    })
    }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  ngOnInit(): void {
    this.getUserAddresss();
  }
  onSelectionChange($event: MatSelectionListChange) {
    //console.log($event.source._value);
    this._bottomSheetRef.dismiss($event.source._value);

  }
  getUserAddresss(){
    this.GeoAddressPresent = true;
    this.userService.getUserAddresss().subscribe((data:any) => {
      this.address = data.data
      if(this.address.length>0){
        this.showAddAddress = false;
      }
      this.GeoAddressPresent = false;
    })
  }

  getAddressUsingPinCode(pincode:any){
    this.GeoAddressPresent = true;
    const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${pincode}`;

    // Make an HTTP request to the reverse geocoding API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if(data.length>0){
          var display_name = data[0]?.display_name
          var arr = display_name.split(",");
          this.GeoAddress = {
            country: arr[4],

            state: arr[3],
            city: arr[2],
            address: "",
            pincode: parseInt(arr[0])
          }
          this.updateAddress()
        }else{
          Swal.fire({
            text: "Address is not deliverable.",
            icon: "error"
          });
        }
        this.GeoAddressPresent = false;


      })
      .catch((error) => {
        console.error('Error fetching user location:', error);
        this.GeoAddressPresent = false;
      });
  }
  getUserLocation(latitude: number, longitude: number) {
    // Using a reverse geocoding service
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCwwij8DTqH8Z65p_82QDEU5K-RJcp_ziE&libraries`;

    // Make an HTTP request to the reverse geocoding API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.getAddress(data);

      })
      .catch((error) => {
        console.error('Error fetching user location:', error);
      });
  }

  getAddress(data: any) {
    const addressComponents = data.results[0].address_components;
    const country = addressComponents.find((component: { types: string | string[]; }) =>
      component.types.includes('country')
    ).long_name;
    const state = addressComponents.find((component: { types: string | string[]; }) =>
      component.types.includes('administrative_area_level_1')
    ).long_name;
    const city = addressComponents.find((component: { types: string | string[]; }) =>
      component.types.includes('locality')
    ).long_name;
    const address = data.results[0].formatted_address;
    const pincode = addressComponents.find((component: { types: string | string[]; }) =>
      component.types.includes('postal_code')
    ).long_name;

    this.GeoAddress = {
      country: country,

      state: state,
      city: city,
      address: address,
      pincode: pincode
    }
    this.GeoAddressPresent = false;
    this.updateAddress()
  }

  updateAddress() {
    this._bottomSheet.open(UpdateAddressForm, {
      data: {
        data:this.GeoAddress,
        pageTitle: "Add address"
      }
    });
  }
  AddAddress(){
    this._bottomSheet.open(UpdateAddressForm, {
      data: {
        data:this.GeoAddress,
        pageTitle: "Add address"
      }
    });
  }
}
