import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Version } from './product.service';

const API_URL = 'http://localhost:8080/api/test/';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  
  constructor(private http: HttpClient) {}

  getUserAddresss() {
    return this.http.post(environment.apiUrl+Version.V2 + 'user/get-address',null);
  }
  createUserAddresss(data:any) {
    return this.http.post(environment.apiUrl+Version.V2 + 'user/address/create',data);
  }

  getCurrentUser(): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2 + 'user/getCurrentUser',null);
  }
  
  deleteUserAddress(id:any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2 + 'user/address/delete/'+id, null);
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(API_URL + 'admin', { responseType: 'text' });
  }
  updateBasicInfo(body:any) {
    return this.http.post(environment.apiUrl+Version.V2 + 'user/update',body);
  }
}
  