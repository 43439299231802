<header class="header">
  <nav class="navbar navbar-expand-lg navbar-light py-3">
      <div class="container">
          <!-- Navbar Brand -->
          <a routerLink="/" class="navbar-brand">
              <img src="/assets/images/logo/logo.webp" alt="logo" width="150">
          </a>
      </div>
  </nav>
</header>

<div class="form-gap"></div>
<div class="container">
  <div class="row">
    <div class="col-md-4 col-md-offset-4"></div>
    <div class="col-md-4 col-md-offset-4">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="text-center">
            <h3><i class="fa fa-lock fa-4x"></i></h3>
            <h2 class="text-center">New Password?</h2>
            <p>you can change your password </p>
            <div class="panel-body">
              <form [formGroup]="passwordForm" (ngSubmit)="resetPassword()">

                <mat-form-field class="w-100">
                  <mat-label>New Password</mat-label>
                  <mat-icon matPrefix>lock</mat-icon>

                  <input matInput placeholder="New Password" 
                  formControlName="newPassword"
                  aria-label="New Password" 
                  type="{{ showPassword ? 'text' : 'password' }}">
                  <span matSuffix class="c-p p-3" (click)="togglePasswordVisibility('newPassword')">
                    <i class="fa" [ngClass]="{ 'fa-eye': !showPassword, 'fa-eye-slash': showPassword }"></i>
                  </span>
                  <mat-error *ngIf="passwordForm.get('newPassword')?.invalid && passwordForm.get('newPassword')?.touched">
                    <span *ngIf="passwordForm.get('newPassword')?.errors?.['minlength']">New Password must be at least 8
                      characters long.</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100 mt-2">
                  <mat-label>Confirm Password</mat-label>
                  <mat-icon matPrefix>lock</mat-icon>

                  <input matInput
                  formControlName="retypePassword"
                  placeholder="Confirm Password" aria-label="Confirm Password"
                  type="{{ showretypePassword ? 'text' : 'password' }}">
                  <span matSuffix class="c-p p-3" (click)="togglePasswordVisibility('retypePassword')">
                    <i class="fa" [ngClass]="{ 'fa-eye': !showretypePassword, 'fa-eye-slash': showretypePassword }"></i>
                  </span>
                  <mat-error *ngIf="passwordForm.get('retypePassword')?.invalid && passwordForm.get('retypePassword')?.touched">
                    <span *ngIf="passwordForm.get('retypePassword')?.errors?.['minlength']">New Password must be at least 8
                      characters long.</span>
                    <span *ngIf="passwordForm.hasError('passwordMismatch')">Passwords do not match.</span>

                  </mat-error>
                </mat-form-field>


                <div class="form-group">
                  <button type="submit" *ngIf="progressStatus==0" class="btn btn-lg btn-primary btn-block center" [disabled]="!passwordForm.valid">Reset</button>
                  <button type="button" *ngIf="progressStatus==1" class="btn btn-lg btn-primary btn-block center" [disabled]="true">
                      <mat-spinner color="white" class="mat-spinner" [diameter]="20" ></mat-spinner>
                  </button>
                  <button type="button" *ngIf="progressStatus==2" class="btn btn-lg btn-primary btn-block" [disabled]="true">
                    <mat-icon>check</mat-icon> Check email
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-md-offset-4"></div>

  </div>
</div>