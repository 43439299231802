import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Version } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  

  constructor(private http: HttpClient) { }

  promocode(cCOde:any,productId:any,totalAmount:any): Observable<any> {
    
    return this.http.post(environment.apiUrl+Version.V2+`coupon/add/${cCOde}/${productId}/${totalAmount}`,null);
  }
  deliveryPinstatus(pincode:any): Observable<any> {
    
    return this.http.get(environment.apiUrl+Version.V2+`delivery/pin-code-delivery-status/${pincode}`);
  }
  removePromocode(coupons_id:any): Observable<any> {
    
    return this.http.delete(environment.apiUrl+Version.V2+`coupon/remove/apply/coupon-code/${coupons_id}`);
  }
  cancelTheOrder(orderId:any){
    return this.http.post(environment.apiUrl+Version.V2+`order/cancel/${orderId}`,null);
  }
  placeOrder(body:any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/place",body);
  }
  checkLastOrder(body:any = null): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/check-last-order-status",body);
  }
  refreshLastOrder(orderId:any = null): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/check-last-order-status-refresh/"+orderId,null);
  }
  updatePaymentStatus(body:any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/update-payment-status",body);
  }
  orderHistory(path:any,body:any,page:any = 1): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/history/"+path+"/"+page,body);
  }
  orderSummary(orderID:any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/review/"+orderID,null);
  }
  rating(data:any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/rating",data);
  }
  track(orderId:any = "20240206-20304258") {
    return this.http.post(environment.apiUrl+Version.V2+"order/track/"+orderId,null);
    // throw new Error('Method not implemented.');track/20240206-20304258
  }
}
