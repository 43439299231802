import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromocodeActionService {

  private promocodeRjx = new BehaviorSubject<any>(null);
  promocodeUpdate = this.promocodeRjx.asObservable();

  constructor() { }

  updatePromocode(data: any) {
    this.promocodeRjx.next(data);
  }
}
