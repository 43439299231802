<form *ngIf="showForm" [formGroup]="addressForm">
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="w-100" >
        <mat-label>Full name</mat-label>
        <input matInput placeholder="Full name" formControlName="name">
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline"  class="w-100">
        <mat-label>Phone</mat-label>
        <input matInput placeholder="Phone" appNumericOnly formControlName="phone" maxlength="10" #phone>
        <mat-hint align="end">{{phone.value.length}} / 10</mat-hint>

      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="w-100" >
        <mat-label>Pincode</mat-label>
        <input matInput appNumericOnly #postalCode placeholder="Pincode" maxlength="6" formControlName="postal_code">
        <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline"  class="w-100">
        <mat-label>Locality</mat-label>
        <input matInput placeholder="Locality" formControlName="locality">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field appearance="outline" class="w-100" >
        <mat-label>Address</mat-label>
        <textarea matInput placeholder="Ex. 100 Main St"  formControlName="address"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="w-100" >
        <mat-label>City</mat-label>
        <input matInput placeholder="City"  formControlName="city">
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline"  class="w-100">
        <mat-label>State</mat-label>
        <input matInput placeholder="State" formControlName="state">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="w-100" >
        <mat-label>Landmark (Optional)</mat-label>
        <input matInput placeholder="Landmark (Optional)" formControlName="landmark">
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field appearance="outline"  class="w-100">
        <mat-label>Alternate phone number (Optional)</mat-label>
        <input matInput appNumericOnly #alternatePhone placeholder="Alternate phone number (Optional)" maxlength="10" formControlName="alternateNUmber">
        <mat-hint align="end">{{alternatePhone.value.length}} / 10</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-radio-group aria-label="Select an work type" formControlName="work_type">
        <mat-radio-button value="1" color="primary">Home</mat-radio-button>
        <mat-radio-button value="2" color="primary">Office</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <button mat-stroked-button color="primary" class="float-right mr-2" (click)="Cancel()">Cancel</button>
      <button mat-stroked-button color="primary" class="float-right mr-2" *ngIf="forSave" disabled="true" (click)="Save()"><mat-spinner [diameter]="20"></mat-spinner></button>
      <button mat-stroked-button color="primary" class="float-right mr-2" *ngIf="!forSave" [disabled]="!this.addressForm.valid" (click)="Save()">Save</button>
    </div>
  </div>
</form>
