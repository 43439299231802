import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Version } from './product.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  service: string = "setting/";
  constructor(private http: HttpClient,
    private storage:StorageService,
    ) { }

    getDynamicPageContent(pageName:any){
      return this.http.get<any>(environment.apiUrl + this.service + "get-page-content/"+pageName);
    }
    getPolicy(pageName:any){
      return this.http.get<any>(environment.apiUrl + this.service + "get-policy/"+pageName);
    }
    getGeneralSetting(type:any = "generalSettings"){
      return this.http.get<any>(environment.apiUrl + this.service + "get-general-setting/"+type);
    }
    setContactUsMessage(body:any = null){
      return this.http.post<any>(environment.apiUrl + Version.V2 + "contact-us/add",body);
    }
    counter(body:any = null){
      return this.http.get<any>(environment.apiUrl + this.service + "counter");
    }
    subscribe(body:any = null){
      return this.http.post<any>(environment.apiUrl + this.service + "subscribe",body);
    }
    getSlider(){
      return this.http.get<any>(environment.apiUrl + Version.V1 + "sliders");
    }

}
