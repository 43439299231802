<!--
      - CATEGORY
    -->

<div class="category" *ngIf="productDetailsData!=null">
	<app-product-details [data]="productDetailsData" [isDeliveryCheck]="true"></app-product-details>
</div>
<div class="category" *ngIf="productDetailsData==null">
	<div class="container-fluid p-2 skeleton-container">
		<div class="row">
			<div class="col-sm-6">
				<div class="d-flex-m">
					<div class="temp-img w-100 skeleton"></div>
					<div class="temp-img-list mt-2">
						<div class="skeleton w-100"></div>
						<div class="skeleton w-100"></div>
						<div class="skeleton w-100"></div>
						<div class="skeleton w-100"></div>
					</div>
					<div class="temp-img-list ">
						<div class="skeleton w-100"></div>
						<div class="skeleton w-100"></div>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
					<p class="skeleton skeleton-text w-100"></p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="product-container">

	<div class="container ">


		<!--
      - SIDEBAR
    -->

		<div class="sidebar  has-scrollbar" data-mobile-menu>
			<div>
				<p>Riview</p>
			</div>

			<app-category-vertical-layout></app-category-vertical-layout>

			<!-- <app-horizontal-products-layout [title]="'best sellers'"></app-horizontal-products-layout> -->

		</div>



		<div class="product-box">
			<div class="product-desc-tab bg-white">
				<div class="tabs tabs--style-2">
					<ul class="nav nav-tabs justify-content-center sticky-top desc bg-white">
						<li class="nav-item">
							<a href="javascript:void(0)" data-toggle="tab"
								class="nav-link text-uppercase strong-600" [ngClass]="{'active show': currentTab==0}" (click)="switchTab(0)">Description</a>
						</li>
						<li class="nav-item">
							<a href="javascript:void(0)" data-toggle="tab"
								class="nav-link text-uppercase strong-600 " [ngClass]="{'active show': currentTab==1}" (click)="switchTab(1)">Reviews</a>
						</li>
					</ul>
					<div class="tab-content pt-0">
						<div class="tab-pane p-2" [ngClass]="{'active show': currentTab==0}" *ngIf="currentTab==0" id="tab_default_1">
							<div [innerHTML]="dynamicHtml"></div>
							
						</div>
						
						<div class="tab-pane " [ngClass]="{'active show': currentTab==1}" id="tab_default_4" *ngIf="currentTab==1" >
							<div class="fluid-paragraph py-4">
								<div class="text-center">
									There have been no reviews for this product yet.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
          <!-- <app-product-box [route]="'products/recent'" [isSort]="false"></app-product-box> -->
			<div *ngIf="productDetailsData!=null">
				<app-horizontal-product-view [route]="'products/related/'+productDetailsData?.data?.id" [title]="'Releted Product'"></app-horizontal-product-view>
			</div>


			<!--
        - PRODUCT MINIMAL
      -->

			<div class="product-minimal">
				<div class="product-showcase">
					<app-single-product [title]="'Best Seller'" [skeleton]="!productBestSellerLodade"
						[product]="productBestSeller"></app-single-product>
				</div>
				<div class="product-showcase">
					<app-single-product [title]="'Featured'" [skeleton]="productFeatured.length==0"
						[product]="productFeatured"></app-single-product>
				</div>
			</div>



			<!--
        - PRODUCT FEATURED
      -->

			<app-deal-of-the-day [title]="'Deal Of The Day'"></app-deal-of-the-day>



			<!--
        - PRODUCT GRID
      -->



		</div>

	</div>

</div>