import { Injectable } from "@angular/core";
import { Sitemap } from "./Sitemap";
import { NotificationService } from "../_services/ws/notification.service";

@Injectable({
    providedIn: 'root'
})
export class Common {
    constructor(
        private p: NotificationService
    ) {

    }
    eventListener(){
      return this.p.getMessage()
    }
    SitemapInit(fullUrl:any = null): void {
      if (typeof window !== 'undefined') {
        var dataNotify = {
            type: "sitemap",
            data: {
                url: (fullUrl==null)? window.location.href:fullUrl,
                priority: "1",
            }
        }

        this.p.sendMessageNotification(dataNotify);
      }
    }
}
