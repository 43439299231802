import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddressRjxService, addressRjx } from 'src/app/_services/rjx/address-rjx.service';
import { UserService } from 'src/app/_services/user.service';
import { SnackbarLayoutComponent } from 'src/app/layout/snackbar-layout/snackbar-layout.component';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-address-management',
  templateUrl: './address-management.component.html',
  styleUrls: ['./address-management.component.css'],
  animations: [
    trigger('slideUpDown', [
      state('up', style({ transform: 'translateY(-100%)', opacity: 0 })),
      state('down', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('up => down', [
        animate('0.3s ease-out')
      ]),
      transition('down => up', [
        animate('0.3s ease-in')
      ])
    ])
  ]
})
export class AddressManagementComponent implements OnInit{
  step = 1;
  subStep = 1;

  addedItems:any = [];
  isAddresedFromClosed: boolean = true;
  isDataLoaded:boolean = false;
  constructor(private _bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    private userService:UserService) { }
  ngOnInit(): void {
    this.userService.getUserAddresss().subscribe((data:any) => {
      this.addedItems = data.data
      this.isDataLoaded = true
    })

    
    
  }
  

  closeAddressForm(event: any) {
    this.isAddresedFromClosed = event;
  }

  EditAddressDilog(data:any) {
    this._bottomSheet.open(UpdateAddressForm, {
      data:{
        data:data,
        pageTitle: "Update address"
      }
    });
  }

  deleteFromArray(item:any){
    this.addedItems = this.addedItems.filter((obj:any) => {
      for (let key in item) {
          if (obj[key] !== item[key]) {
              return true;
          }
      }
      return false;
  });
  }
  deleteAddress(item:any) {
    
    Swal.fire({
      title: "Are you sure want to delete this address?",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.deleteFromArray(item)
        this.userService.deleteUserAddress(item?.id).subscribe((data:any) => {
          this.snackBar(data?.message,"success")
        },(error:any) => {
          this.snackBar("Something going wrong","error")
        })
      }
    });
  }

  
  getAddressData(event:any){
    //console.log(event);
    
  }
  snackBar(message: any, status: any) {
		this._snackBar.openFromComponent(SnackbarLayoutComponent, {
			duration: 3 * 1000,
			data: {
				message: message,
				status: status
			}
		});
	}

}

@Component({
  selector: 'update-address-form',
  templateUrl: 'update-address-form.component.html',
  styleUrls: ['./address-management.component.css']
})
export class UpdateAddressForm {


  constructor(private _bottomSheetRef: MatBottomSheetRef<UpdateAddressForm>,
    private addressRjx : AddressRjxService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {

      this.addressRjx.observer.subscribe((data:addressRjx) =>{
        if(data){
          
          if(data?.addingData==false){
            this._bottomSheetRef.dismiss()
            
          }
        }
          
      })
  }

  getAdddressData(event: any) {

  }
  closeAddress(event: any) {
    this._bottomSheetRef.dismiss()
  }
  ngOnInit(): void {

  }
  
}
