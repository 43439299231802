import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserRjxService {

  private currentUserSub = new BehaviorSubject<any>([]);
  private refreshSub = new BehaviorSubject<any>([]);
  user = this.currentUserSub.asObservable();
  refresh = this.refreshSub.asObservable();

  constructor() { }

  setUser(list: any) {
    this.currentUserSub.next(list);
  }
  setRefresh(list: any) {
    this.refreshSub.next(list);
  }
}
