<mat-progress-bar mode="indeterminate" *ngIf="GeoAddressPresent"></mat-progress-bar>
<div *ngIf="GeoAddressPresent">
  <h3>Address is loading...</h3>
</div>
<div *ngIf="!GeoAddressPresent">
  <h2 class="mt-3 mx-3">Select delivery address</h2>
  <mat-divider></mat-divider>
  <mat-selection-list #data [multiple]="false" (selectionChange)="onSelectionChange($event)">
    <mat-list-option
      *ngFor="let item of address"
      [value]="item"
      class="mb-2 mt-2 py-2"
    >
      <span class="fw-bold d-flex">{{ item.name }},{{ item.postal_code }}</span>
      <span class="text-secondary">{{ item.address }} {{ item.address2 }} , {{ item.city }} , {{ item.state }} - {{ item.country }}</span>
    </mat-list-option>
  </mat-selection-list>
  <div class="add-address" (click)="AddAddress()" *ngIf="showAddAddress">
    <p><mat-icon>add</mat-icon> add address</p>
  </div>
  <mat-divider> </mat-divider>
  <h2 class="mt-3 mx-3">Enter pincode to check delivery info</h2>

  <div class="pincode d-flex">
    <input type="text" appNumericOnly #pincode placeholder="Enter pincode" [value]="GeoAddress?.pincode" maxlength="6" />
    <button mat-raised-button class="mx-2" color="primary" (click)="getAddressUsingPinCode(pincode.value)">Submit</button>
  </div>
  <div class="location">
    <img
      src="https://rukminim1.flixcart.com/www/122/122/promos/21/06/2018/3bd082e2-af75-425a-834f-cb5100f35e3d.png?q=65"/>
      <span class="mt-3 text-primary" (click)="openGeoLocation()">Use my current location</span>
  </div>
</div>
