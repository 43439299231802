import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonRxjsService {
  private messageSource = new BehaviorSubject<any>(null);
  currentMessage = this.messageSource.asObservable();
  wishListChange = new Subject();

  constructor() { }

  changeMessage(message: any) {
    this.messageSource.next(message);
  }
  setWichListCount(wichListCount: any) {
    this.messageSource.next(wichListCount);
  }

}
