import { Component, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipListboxChange, MatChipOption } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { currency } from 'src/app/_services/constant';
import { ProductService } from 'src/app/_services/product.service';
import { StorageService } from 'src/app/_services/storage.service';
import { SnackbarLayoutComponent } from '../../snackbar-layout/snackbar-layout.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/_services/cart.service';
import Swal from 'sweetalert2';
import { CartRjxService } from 'src/app/_services/rjx/cart.service';
import { Router } from '@angular/router';

export interface Variant {
  product_id: number;
  variant: string;
  price: number;
  qty: number;
  in_stock: boolean;
}
export interface layoutPermition{
  permission?:"read" | "dml"
}
@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent implements OnInit {

  currency = currency
  size: any = [];
  colors: any = [];
  selectedsize: any;
  variant: Variant | undefined;
  validData: boolean = false;
  isOutOfStock: boolean = false;
  progress: boolean = false;
  alreadyAdded: boolean = false;
  sizeListData = new FormControl()
  price: any = "";
  @Input("isDeliveryCheck") isDeliveryCheck: boolean = false;

  @ViewChild("chipSizeAttr", { static: false }) chipSizeAttr: MatChipOption | undefined
  isImageLoaded: boolean = false;
  selectedOptions: any = null;
  totalCartItem: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ProductViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: StorageService,
    private router:Router,
    private productService: ProductService,
    private cartCount:CartRjxService,
    private cartService:CartService,
    private _snackBar: MatSnackBar,
  ) {
  }

  close(){
    this.dialogRef.close()
  }

  ngOnInit(): void {
    
  }
  dataEvent(event:any){
    if(event!=undefined){
      this.progress = event.progress
    }
  }
  closeDilogEvent(event:any){
    if(event!=undefined){
      if(event){
        this.close()
      }
    }
  }

  
}

