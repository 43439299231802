<div class="bg-success text-light heading d-flex justify-content-between">
  <p>{{data?.pageTitle}}</p>
  <mat-icon (click)="closeAddress($event)">close</mat-icon>

  
</div>
<div class="p-4">

  <app-address-form [showForm]="true" (data)="getAdddressData($event)" (close)="closeAddress($event)" [dataField]="data.data"></app-address-form>
</div>
