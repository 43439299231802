import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { Utils } from 'src/app/_shared/Uitls';
import { ColorListComponent } from 'src/app/layout/color-list/color-list.component';
import { ProductViewComponent } from 'src/app/layout/product-box/product-view/product-view.component';

@Component({
	selector: 'app-product-search',
	templateUrl: './product-search.component.html',
	styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent implements OnInit {
	product: any;
	currency: any = currency;

	constructor(
		public dialog: MatDialog,
		private _route: Router,

	) { }

	ngOnInit(): void {
		this.product = JSON.parse(localStorage.getItem("setImageProcess")!)
	}

	viewProducts(data: any, permission: any) {

		const dialogRef = this.dialog.open(ProductViewComponent, {
			data: {
				data: data,
				permission: permission
			}
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}
	sort(data: any) {
		// Assuming products is your array of objects
		return data.sort((a: any, b: any) => {
			const similarityA = a.similarity_scores ? a.similarity_scores.color_similarity : 0;
			const similarityB = b.similarity_scores ? b.similarity_scores.color_similarity : 0;
			return similarityB - similarityA; // Sort in descending order
		});


	}
	alreadyWishlist(obj: any) {
		var stWil = localStorage.getItem('wishlist');
		if (stWil) {
			var arr = JSON.parse(stWil);
			if (arr.some((item: any) => item.product.id == obj.id)) {
				return true;
			}
		}
		return false;
	}
	goto(arg0: string) {
		this.scrollToTop()
		this._route.navigate([arg0])
	}
	addOnWishlist(o: any) {

	}
	setDefaultImage(i: any) {
		i.target.src = Utils.NO_IMAGE;
	}
	productImage(url: any) {
		return ImageUrlService.getImageUrl(url);
	}
	scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
	removeWishList(o: any) {

	}
	colorList(color:any){
		const dialogRef = this.dialog.open(ColorListComponent,{
			data:{
				color:color,
				title:"Product matching colour"
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log(`Dialog result: ${result}`);
		});
	}
}
