import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberDirective } from '../_shared/directive/only-number.directive';
import { SingleSpaceDirective } from '../_shared/directive/single-space.directive';



@NgModule({
  declarations: [
    SingleSpaceDirective,
  OnlyNumberDirective
  ],
  exports:[
    SingleSpaceDirective,
  OnlyNumberDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
