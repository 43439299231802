import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootComponent } from './root/root.component';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { CartComponent } from './cart/cart.component';
import { OrderReviewComponent } from './order-review/order-review.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { TrackYourOrderComponent } from './track-your-order/track-your-order.component';
import { Utils } from '../_shared/Uitls';
import { ProductSearchComponent } from './category/product-search/product-search.component';

const routes: Routes = [
  {
    path:'',
    component:RootComponent,
    title: Utils.AppName(),
    children: [
      {
        path: '',
        component:HomeComponent
      },
      {
        path: 'products/:products',
        component:ProductsComponent,
        title:"Products page"
      },
      {
        path:'cart',
        component:CartComponent,
        title:"Cart management"
      },

      {
        path:'order-review',
        component:OrderReviewComponent,
        title:"Order Review"
      },
      {
        path:'order-confirmation/:orderId',
        component:OrderConfirmationComponent,
        title:"Order Confirmation"
      },
      {
        path:'track-your-order',
        component:TrackYourOrderComponent,
        title:"Track maniyar bangles order"
      },
      {
        path:'track-your-order/:orderId',
        component:TrackYourOrderComponent,
        title:"Track maniyar bangles order"
      },
      {
        path:'image-processing-product',
        component:ProductSearchComponent
      },
      {
        path:'',
        loadChildren: () => import("./footer-section/footer-section.module").then(m => m.FooterSectionModule)
      },
      {
        path:'',
        loadChildren: () => import("./category/category.module").then(m => m.CategoryModule)
      },
      {
        path:'',
        loadChildren: () => import("./profile/profile.module").then(m => m.ProfileModule)
      },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuleRoutingModule {

 }
