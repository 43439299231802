import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { Observable, map } from 'rxjs';


export class ImageUrlService {
  constructor(private http: HttpClient) {}

  getImageUrl(imageLink: string): Observable<string> {
    // Assuming 'imageLink' is the path to the image on the server
    const imageUrl = "https://s3-resource.maniyarbangles.com/public/" + imageLink;
    return this.http.get(imageUrl, { responseType: 'blob' }).pipe(
      map((data: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        return reader.result as string;
      })
    );
  }
  public static getImageUrl(imageLink:any,isNgImage:boolean = false){
    if(isNgImage){
      return "/public/"+imageLink;
    }else{
      return "https://s3-resource.maniyarbangles.com/public/"+imageLink;
    }
  }
}
