import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorRjxService {

  private colorSub = new BehaviorSubject<any>([]);
  colorList = this.colorSub.asObservable();

  constructor() { }

  setColor(list: any) {
    this.colorSub.next(list);
  }
}
