import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {
  fieldPromocde: string = "promocode";
  pas: string = "password";

  constructor() { }
  setPromocde(data: any) {
    localStorage.setItem(this.fieldPromocde, this.encrypt(JSON.stringify(data), this.pas))
  }
  getPrmocode() {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem(this.fieldPromocde)) {
        var data = this.decrypt(localStorage.getItem(this.fieldPromocde)!, this.pas)
        return JSON.parse(data)
      }
    }
    return {}
  }
  removePrmocode() {
    if (typeof window !== 'undefined') {

      if (localStorage.getItem(this.fieldPromocde)) {
        localStorage.removeItem(this.fieldPromocde)
      }
    }
  }
  encrypt(text: string, password: string): string {
    return CryptoJS.AES.encrypt(text, password).toString();
  }
  decrypt(ciphertext: string, password: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, password);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
