import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { Subscription, interval } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { CartService } from 'src/app/_services/cart.service';
import { StorageService } from 'src/app/_services/storage.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-otp',
	templateUrl: './otp-verification.component.html',
	styleUrls: ['./otp-verification.component.css'],
})
export class OtpVerificationComponent {

	countdownMinutes: number = 3;
  	countdownSeconds: number = 0;
  	countdownSubscription!: Subscription;

	@ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput:NgOtpInputComponent | undefined;


	desableBtn: boolean = true;
	@Output() isMatch: EventEmitter<any> = new EventEmitter();
	otpConfig: NgOtpInputConfig = {
		allowNumbersOnly: true,
		length: 6,
		isPasswordInput: false,
		disableAutoFocus: false,
		placeholder: '',
		inputStyles: {
			'display': 'flex'
		},
		containerStyles: {
			'display': 'flex'
		},
		inputClass: 'each_input',
		containerClass: 'all_inputs'
	};
	otp: string = "";
	desableRecentBtn: boolean = true;
	constructor(private elementRef: ElementRef,
		private router: Router,
		private cartService:CartService,
		private storage: StorageService,
		public dialogRef: MatDialogRef<OtpVerificationComponent>,
		private authService: AuthService,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit():void {
		this.startCountdown()
	}
	ngOnDestroy(): void {
		if (this.countdownSubscription) {
		  this.countdownSubscription.unsubscribe();
		}
	  }


	onOtpChange(event: any) {
		this.otp = event;
		if (this.otp.length == 6) {
			this.desableBtn = false;
		} else {
			this.desableBtn = true;
		}

	}
	verifyOtp() {
		this.desableBtn = true;
		if(!this.storage.isLoggedIn()){
			if (this.otp.length == 6) {

				const dataa = {
					"name": this.data.data?.firstFormControl + " " + this.data.data?.firstFormControl,
					"email": this.data.data?.email,
					"phone": this.data.data?.phoneFormControl,
					"otp": this.otp,
					"plateform": "web",
					"provider": "",
					"personPhoto": "",
					"device_token": "own-auth/jwt-auth",
					"password": this.data.data?.password
				}
	
	
	
	
				this.authService.verifyAccount(dataa).subscribe((data: any) => {
					this.desableBtn = true;
					if (data?.error == true) {
						Swal.fire({
							position: "top-start",
							icon: "error",
							title: data?.message,
							showConfirmButton: false,
							timer: 1500
						});
						return;
					}
					Swal.fire({
						position: "top-start",
						icon: "success",
						title: data?.message,
						showConfirmButton: false,
						timer: 1500
					});
					this.dialogRef.close({ type: "cancel" })
					this.router.navigate(['/login'])
				})
			} else {
				Swal.fire({
					position: "top-start",
					icon: "warning",
					title: "Enter correct OTP?",
					showConfirmButton: false,
					timer: 1500
				});
			}
		}else{
			this.authService.verifyOtp(this.otp).subscribe((data: any) => {
				this.desableBtn = true;
				if (data?.success == false) {
					Swal.fire({
						icon: "error",
						title: data?.message,
						showConfirmButton: true,
					});
				}else{
					Swal.fire({
						icon: "success",
						title: data?.message,
						showConfirmButton: true,
						timer: 1500
					});
					this.dialogRef.close({ type: "verified" })
				}
				
			})
		}
		


	}

	startCountdown(): void {
		this.countdownSubscription = interval(1000).subscribe(() => {
		  if (this.countdownSeconds === 0) {
			if (this.countdownMinutes === 0) {
			  // Countdown finished, handle accordingly
			  this.countdownSubscription.unsubscribe();
			  return;
			} else {
			  this.countdownMinutes--;
			  this.countdownSeconds = 59;
			}
		  } else {
			this.countdownSeconds--;
		  }
		  if(this.countdownMinutes==0 && this.countdownSeconds<3){
			this.desableRecentBtn = false
		  }
		});
	  }
	close() {
		Swal.fire({
			title: "Do you want to close the otp?",
			text: "After cancel this form will also reset!",
			showCancelButton: true,
			confirmButtonText: "Yes",
			showClass: {
				popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
			},
			hideClass: {
				popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
			}
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				this.dialogRef.close({ type: "cancel" })
			}
		});
	}

	resend() {
		if(this.countdownMinutes==0 && this.countdownSeconds<3){
			this.ngOtpInput?.setValue("")
			this.desableBtn = true;
			if(!this.storage.isLoggedIn()){
				this.authService.signup(this.data.data?.email).subscribe((data:any) =>{
					Swal.fire({
						position: "top-start",
						icon: "success",
						title: data?.message,
						showConfirmButton: false,
						timer: 1500
					});
				})
			}else{
				this.cartService.sendOtp().subscribe((data:any) => {
					this.countdownMinutes = 3
					this.desableRecentBtn = true
					this.startCountdown()
				})
			}
		}
		
			
	}
}
