<div class="product-box" *ngIf="!skeleton">
  <div class="product-grid">
    <div class="showcase" *ngFor="let item of skeletonData">
      <div class="showcase-banner">
        <div class="skeleton skeleton-p-img">

        </div>

        <p class="showcase-badge skeleton"></p>

        <div class="showcase-actions skeleton">
          <button class="btn-action" aria-label="favorite" >
            <mat-icon class="heart-icon">favorite</mat-icon>
          </button>
          <button class="btn-action" aria-label="favorite" >
            <mat-icon>favorite_border</mat-icon>
          </button>

          <button class="btn-action" >
            <ion-icon name="eye-outline"></ion-icon>
          </button>

          <button class="btn-action">
            <ion-icon name="repeat-outline"></ion-icon>
          </button>

          <button class="btn-action" >
            <ion-icon name="bag-add-outline"></ion-icon>
          </button>
        </div>
      </div>

      <div class="showcase-content">
        <div>

          <a class="skeleton skeleton-str">
          </a>

          <!-- <a routerLink="/products">
              <h3 class="showcase-title">Mens Winter Leathers Jackets</h3>
            </a> -->

          <div class="showcase-rating skeleton skeleton-str mt-1">

          </div>
        </div>

        <div class="price-box skeleton skeleton-str">

        </div>
      </div>

    </div>
  </div>
</div>
<div class="product-box" infinite-scroll [infiniteScrollDistance]="distance" [infiniteScrollThrottle]="throttle"
  (scrolled)="onScroll()" *ngIf="productList.length>0 && skeleton">
  <!--
        - PRODUCT GRID
      -->
  <div class="product-main">
    <section *ngIf="isSort" class="mobile mb-3">
      <div class="example-button-row">
        <button mat-stroked-button color="primary" class="mr-2 filter-btn" (click)="openFilter()">Filter <mat-icon>keyboard_arrow_down</mat-icon></button>
        <button mat-stroked-button color="primary" (click)="sortFilter()" class="mr-2 filter-btn">Sort By <mat-icon>keyboard_arrow_down</mat-icon></button>
      </div>
    </section>
    <div class="d-flex web " *ngIf="isSort">
      <h4 class="mx-2"><b>Sort by</b></h4>
      <div class="d-flex sort-label">
        <a class="product-sort" (click)="filter('popularity')" [ngClass]="{'product-sort-active': sortType=='popularity'}">Popularity</a>
        <a class="product-sort" (click)="filter('price_low_to_high')" [ngClass]="{'product-sort-active': sortType=='price_low_to_high'}">Price - Low to High</a>
        <a class="product-sort" (click)="filter('price_high_to_low')"  [ngClass]="{'product-sort-active': sortType=='price_high_to_low'}">Price - High to Low</a>
        <a class="product-sort" (click)="filter('new_arrival')"  [ngClass]="{'product-sort-active': sortType=='new_arrival'}">Newest First</a>
      </div>
    </div>
    <div class="product-grid">
      <div class="showcase" *ngFor="let data of productList;index as i">
        <div class="showcase-banner">
          <img [ngSrc]="productImage(data?.thumbnail_image)" alt="{{data.name}}" appDynamicAspectRatio width="300" height="300" (error)="setDefaultImage($event)"
            class="product-img default" />
          <img [ngSrc]="productImage(data?.thumbnail_image)" alt="{{data.name}}" appDynamicAspectRatio width="300" height="300" (error)="setDefaultImage($event)"
            class="product-img hover" />

          <p class="showcase-badge"><span *ngIf="data.discount_type=='amount'">{{currency}}</span> {{data.discount}} Off</p>

          <div class="showcase-actions">
            <button class="btn-action" (click)="removeWishList(data)" *ngIf="alreadyWishlist(data)">
              <mat-icon class="heart-icon">favorite</mat-icon>
            </button>
            <button class="btn-action" (click)="addOnWishlist(data)" *ngIf="!alreadyWishlist(data)" aria-label="favorite" >
              <mat-icon>favorite_border</mat-icon>
            </button>

            <button class="btn-action" (click)="viewProducts(data,'view')">
              <ion-icon name="eye-outline"></ion-icon>
            </button>

            <button class="btn-action">
              <ion-icon name="repeat-outline"></ion-icon>
            </button>

            <button class="btn-action" (click)="viewProducts(data,'dml')">
              <ion-icon name="bag-add-outline"></ion-icon>
            </button>
          </div>
        </div>
        <!--  -->
        <div class="showcase-content">
          <div>

            <a [routerLink]="['/products/'+data?.slug]" routerLinkActive="router-link-active" (click)="this.scrollToTop()" class="showcase-category  c-p" style="min-height: 6vh;">{{data.name}}
            </a>

            <!-- <a routerLink="/products">
                <h3 class="showcase-title">Mens Winter Leathers Jackets</h3>
              </a> -->

            <app-rating [rating]="data?.rating" [maxRating]="5"></app-rating>
          </div>

          <div class="price-box">
            <p class="price">{{currency}}{{data.base_discounted_price}}</p>

            <del>{{currency}}{{data.base_price}}</del>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="skeleton">
  <app-not-found *ngIf="productList.length==0"></app-not-found>
</ng-container>

