<header class="header">
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container">
      <!-- Navbar Brand -->
      <a routerLink="/" class="navbar-brand">
        <img src="/assets/images/logo/logo.webp" alt="logo" width="150">
      </a>
    </div>
  </nav>
</header>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css" rel="stylesheet">

<div class="form-gap"></div>
<div class="container">
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="text-center">
            <h3><i class="fa fa-lock fa-4x"></i></h3>
            <h2 class="text-center">Forgot Password?</h2>
            <p>You can reset your password here.</p>
            <div class="panel-body">
              <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                <div class="col-lg-12 mb-2">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Email</mat-label>
                    <input matInput id="email" autocomplete="off" formControlName="email" noSpace tbSingleSpace
                      type="email" name="email" placeholder="Enter Email Address">
                    <mat-icon matPrefix>email</mat-icon>
                    <mat-error
                      *ngIf="resetPasswordForm.get('email')?.hasError('required') && resetPasswordForm.get('email')?.touched">
                      Email is required
                    </mat-error>
                    <mat-error
                      *ngIf="resetPasswordForm.get('email')?.hasError('email') && resetPasswordForm.get('email')?.touched">
                      Please enter a valid email.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-lg btn-primary btn-block" [disabled]="!resetPasswordForm.valid"
                    *ngIf="progressSatus==0">
                    Reset Password
                  </button>
                  <div class="text-right w-100 pt-3 mb-3">
                    <span class="text-muted font-weight-bold d-inline-flex">Back to<button (click)="backtoLogin()"
                        class="text-primary">&nbsp;Login</button></span>
                  </div>
                  <button type="button"
                    class="btn btn-lg btn-primary btn-block text-center d-flex justify-content-center align-items-center"
                    [disabled]="!resetPasswordForm.valid" *ngIf="progressSatus==1">
                    <mat-spinner color="white" class="mat-spinner" [diameter]="20"></mat-spinner>
                  </button>
                  <!-- <button type="button" class="btn btn-lg btn-primary btn-block" 
                  [disabled]="!resetPasswordForm.valid" *ngIf="progressSatus==2">
                  <mat-icon>check</mat-icon> Check Email
                </button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>