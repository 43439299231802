import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { StorageService } from './_services/storage.service';
import { AuthService } from './_services/auth.service';
import { EventBusService } from './_shared/event-bus.service';
import { LoadingService } from './_helpers/loading.service';
import { BackgroundService } from './_services/background.service';
import {SycnType} from './_services/background.service'
import { ProductService } from './_services/product.service';
import { Utils } from './_shared/Uitls';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DOCUMENT, isPlatformServer, LocationStrategy } from '@angular/common';
import { Common } from './_shared/Common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  template:'<router-outlet (activate)="onActivateComponent($event)"></router-outlet>',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  isprogress:boolean = false;
  username?: string;

  eventBusSub?: Subscription;
  fullUrl: any;

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    public loadingService: LoadingService,
    private eventBusService: EventBusService,
    private backgroundService:BackgroundService,
    private productService:ProductService,
    private router: Router,

    private meta: Meta,
    private locationStrategy: LocationStrategy,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    // private common:Common

  ) {
    if (isPlatformServer(this.platformId)) {

      const baseUrl = Utils.BASE_URL;
      const url = `${baseUrl}${this.doc.location.pathname}`;
      this.setCanonicalURL(url);
    }
  }
  setCanonicalURL(url: string) {
    let link: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`);
    if (link) {
      link.href = url;
    } else {
      link = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);
      this.doc.head.appendChild(link);
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Update meta tags based on the route
        this.setTwitterCardMetaTags({
          card: 'summary_large_image',
          site: Utils.BASE_URL,
          title: 'Get Quality Handmade Bangle Designs At Very Affordable Prices',
          description: 'Buy super quality handmade bangle designs online at good prices, give support to local handmade businesses. Starting from only Rs.149.',
          image: 'https://www.maniyarbangles.com/assets/images/logo/logo.webp'
        });
      }
    });
  }

  setTwitterCardMetaTags(tags: { card: string, site: string, title: string, description: string, image: string }) {
    this.meta.updateTag({ name: 'twitter:card', content: tags.card });
    this.meta.updateTag({ name: 'twitter:site', content: tags.site });
    this.meta.updateTag({ name: 'twitter:title', content: tags.title });
    this.meta.updateTag({ name: 'twitter:description', content: tags.description });
    this.meta.updateTag({ name: 'twitter:image', content: tags.image });
  }

  onActivateComponent($event: any) {
    if (typeof document !== 'undefined') {
      const loadingElement = document.getElementById('tb-loading-spinner');
      loadingElement?.remove();
    }

  }
  ngOnInit(): void {
    this.loadingService.loading$.subscribe((data:any) => {
      this.isprogress = data
    })
    this.eventBusService.on("logout").subscribe((data:any) => {
      this.logout()

    })
    this.backgroundServiceWorker()
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.getFullUrl();
    });


  }
  getFullUrl(): void {
    if (typeof window !== 'undefined') {
      // const protocol = window.location.protocol;
      // const host = window.location.host;
      // const path = this.locationStrategy.prepareExternalUrl(this.router.url);
      // this.fullUrl = `${protocol}//${host}${path}`;
      // this.common.SitemapInit(this.fullUrl)
    }
  }
  backgroundServiceWorker() {
    this.backgroundService.syncMeta.subscribe((data) => {
      if(data?.wishlist.permission == "add"){
        this.productService.wishlistsAdd(data?.wishlist.data.id).subscribe((data:any) => {
          this.productService.wishlistsGet();
        })
      }
      if(data?.wishlist.permission == "list"){
        this.productService.wishlistsGet();
      }
      if(data?.wishlist.permission == "remove"){
        // if(data?.wishlist.byproducts==true){
        //   this.productService.wishlistsDeleteByProducts(data.wishlist.data.product.id);
        // }else{
        // }
        this.productService.wishlistsDelete(data.wishlist.data.id).subscribe((data:any) => {

          this.productService.wishlistsGet();
        })

      }
    })
  }

  logout(): void {
    this.storageService.logout()
    // this.authService.logout();
    window.location.reload();
  }
}

