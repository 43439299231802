import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/_services/category.service';
import { CommonRxjsService } from 'src/app/_services/common-rxjs.service';
import { CategoryListRjxService } from 'src/app/_services/rjx/category-list-rjx.service';
import { Utils } from 'src/app/_shared/Uitls';
import { EncriptOrDescipt } from 'src/app/_shared/encrypt-decript-syc';

@Component({
  selector: 'app-category-vertical-layout',
  templateUrl: './category-vertical-layout.component.html',
  styleUrls: ['./category-vertical-layout.component.css']
})
export class CategoryVerticalLayoutComponent {

  @Input("isMobile") isMobile:boolean = false;
  @Input("filterByCategory") filterByCategory:boolean = false;
  // @Output() isMatch: EventEmitter<any> = new EventEmitter();
  @Output("selectedCategory") selectedCategory : EventEmitter<any> = new EventEmitter();
  subsubcatNmae: any = "";
  subCategoryName: any = "";
  selectedCat: any = {};
constructor(
  private ser:CategoryService,
  private categoryListRjx:CategoryListRjxService,
  private _du: CommonRxjsService,
  private router:Router
){

}
categories:any=[];
skeletonList:any=[1,2,4,5,5,6,6,6,6,6];

  ngOnInit(): void {
    this.ser.getCateorys().subscribe((data:any) => {
      this.categories = data?.data
      this.skeletonList = []
      this.categoryListRjx.setFilter(data?.data)
    })
  }

  hideSideBar(path:any,subsubcatNmae:any){
    if(!this.filterByCategory){
      this.router.navigate([path])
      this._du.changeMessage({ type: 'sidebar', status: false });
    }else{
      this.selectedCat.subsubcatNmae = {
        name:subsubcatNmae,
        path:path
      };
      this.selectedCat.subCategoryName = null;
      this.subsubcatNmae = subsubcatNmae;
      this.subCategoryName = "";
      this.emitSelectedCategory()
    }

  }
  toggleAccordion(event: MouseEvent,item:any) {
    if(item?.subSubCategories.data.length==0){
      if(this.filterByCategory){
        this.subCategoryName = item?.name;
        this.selectedCat.subCategoryName = {
          name:item?.name,
          path:this.slug_modify(item.name)+"-"+item.id
        };
        this.selectedCat.subsubcatNmae = null;
        this.subsubcatNmae = "";
        this.emitSelectedCategory()
      }else{
        this.router.navigate([this.slug_modify(item.name)+"-"+item.id ])
        this._du.changeMessage({ type: 'sidebar', status: false });
      }
      return;
    }
    const target = event.target as HTMLElement;
    // const accordionBtn = target.closest('[data-accordion-btn]');

    const accordionBtn = target.closest('.sidebar-accordion-menu');

    if (accordionBtn) {
      const accordion = accordionBtn.nextElementSibling as HTMLElement;
      if (accordion && accordion.hasAttribute('data-accordion')) {
        accordion.classList.toggle('active');
      }
    }
    
  }
  emitSelectedCategory(){
    this.selectedCategory.emit(this.selectedCat)
  }

  slug_modify(text:any){
    return Utils.slug_modify(text);
  }
}
