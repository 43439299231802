import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-no-internet',
    templateUrl: './no-internet.component.html',
    styleUrls: ['./no-internet.component.css']
})
export class NoInternetComponent {

    isInternetPresnt: any = false;
    ngOnInit(): void {
        this.checkInternet()
    }

    checkInternet() {

        // fetch(environment.apiUrl)
        //     .then(response => {
        //         if (!response.ok) {
        //             this.isInternetPresnt = true;
        //         } else {
        //             this.isInternetPresnt = false;
        //         }
        //     })
        //     .catch(error => {
        //         this.isInternetPresnt = false;
        //     });
    }

}
