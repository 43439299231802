import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Version } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient) { }

  addTocartCateorys(body:any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"cart/add",body);
  }
  removeTocartCateorys(cartId:any): Observable<any> {
    return this.http.delete(environment.apiUrl+Version.V2+"cart/remove/"+cartId);
  }
  updateCartCategory(data:any): Observable<any> {
    return this.http.put(environment.apiUrl+Version.V2+"cart/change-quantity",data);
  }
  getCartCateorys(): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"cart/get",{});
  }

  saveForLater(cartId : any): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"products-for-later/save/"+cartId, null );
  }

  getsaveForLater(): Observable<any> {
    return this.http.get(environment.apiUrl+Version.V2+"products-for-later/list");
  }
  deleteSaveForLater(id:any): Observable<any> {
    return this.http.delete(environment.apiUrl+Version.V2+"products-for-later/delete/"+id);
  }
  sendOtp(): Observable<any> {
    return this.http.post(environment.apiUrl+Version.V2+"order/cod-verification",null);
  }

  
}
