import { SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/_helpers/loading.service';
import { AuthService } from 'src/app/_services/auth.service';
import { StorageService } from 'src/app/_services/storage.service';
import { Utils } from 'src/app/_shared/Uitls';
import { OtpVerificationComponent } from 'src/app/layout/otp-verification/otp-verification.component';
import Swal from 'sweetalert2';

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function PasswordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (!control || !matchingControl) {
      return null;
    }

    if (matchingControl.errors && !matchingControl.errors['passwordMismatch']) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class LoginV2Component {
  signUpForm: any = FormGroup;
  signupBtnDisabled: boolean = false;
  isPasswordVisible = false;
  isPasswordCnfVisible = false;

  isSuccessful = false;
  isprogress: boolean = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    public loadingService: LoadingService,
    private _auth: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.loadingService.loading$.subscribe((data: any) => {
      this.isprogress = data
    })
    this.signUpForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstFormControl: new FormControl(null, [Validators.required]),
      lastFormControl: new FormControl(null, [Validators.required]),
      phoneFormControl: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(7),
      ]),
      passwordConfirmation: new FormControl(null, [
        Validators.required,
        Validators.minLength(7),
      ])
    },
    {
      validators: PasswordMatchValidator('password', 'passwordConfirmation'),
    });
  }
  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  togglePasswordCnfVisibility(): void {
    this.isPasswordCnfVisible = !this.isPasswordCnfVisible;
  }
  onSubmit(): void {
  }
  gotoSignUp() {
    this.router.navigate(['login'])
  }
  gotoHome() {
    this.router.navigate(['/'])
  }
  onSignUp() {
    const name = this.signUpForm.value.firstFormControl + this.signUpForm.value.lastFormControl;
    const email = this.signUpForm.value.email;
    const password = this.signUpForm.value.password;

    if (this.signUpForm.valid) {
      this.signupBtnDisabled = true;
      var formData = this.signUpForm.value;
      this.signUpForm.disable()
      this.authService.signup(email).subscribe((data: any) => {
        this.signupBtnDisabled = false;
        if (data?.success == false) {
          Swal.fire({
            position: "top-start",
            icon: "warning",
            title: data?.message,
            showConfirmButton: false,
            timer: 1500
          });

        } else {
          var dialogRef = this.dialog.open(OtpVerificationComponent, {
            disableClose: true,
            data: {
              title: "OTP se sent to over " + Utils.maskEmail(email),
              data: formData
            }
          })
          dialogRef.afterClosed().subscribe(result => {
            if (result?.type == 'cancel') {
              this.signUpForm.reset()
              this.signUpForm.enable()

            }
          });
        }

      })

    }
  }
}
