import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryFilterRjxService {

  private categoryFilter = new BehaviorSubject<any>([]);
  categoryFilterData = this.categoryFilter.asObservable();

  constructor() { }

  setFilter(list: any) {
    this.categoryFilter.next(list);
  }
}
