import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  // constructor(private socket: Socket) {}

  // sendMessageNotification(msg: any) {
  //   this.socket.emit('notifications', msg);
  // }
  // getMessage() {
  //   return this.socket.fromEvent('notifications');
  // }

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  public socket: any;

  constructor() {
    if (typeof window !== 'undefined') {
      this.socket = io(environment.wsApiUrl + 'notifications');

    }
  }

  public sendMessageNotification(message: any) {
    // //console.log('notifications: ', message)
    this.socket.emit('notifications', message);
  }

  public getMessage = (event:any = 'notifications') => {
    this.socket.on(event, (message: any) =>{
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };
}
