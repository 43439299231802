import { NgModule } from '@angular/core';
import { CommonModule, provideImgixLoader } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NoDataFoundLayoutComponent } from './no-data-found-layout/no-data-found-layout.component';
import { ModalComponent } from './modal/modal.component';
import { NotificationTostComponent } from './notification-tost/notification-tost.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { IoniconComponent } from './ionicon/ionicon.component';
import {MatMenuModule} from '@angular/material/menu';
import { HorizontalProductViewComponent } from './product-layout/horizontal-product-view/horizontal-product-view.component';
import { VerticalProductViewComponent } from './product-layout/vertical-product-view/vertical-product-view.component';
import { ProfileSidebarLayoutComponent } from './profile-sidebar-layout/profile-sidebar-layout.component';
import { MatChipsModule } from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { CategoryVerticalLayoutComponent } from './category-vertical-layout/category-vertical-layout.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { ProductBoxComponent } from './product-box/product-box.component';
import { NoInternetComponent } from './no-internet/no-internet.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SnackbarLayoutComponent } from './snackbar-layout/snackbar-layout.component';
import { DealOfTheDayComponent } from './deal-of-the-day/deal-of-the-day.component';
import { HorizontalProductsLayoutComponent } from './horizontal-products-layout/horizontal-products-layout.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductViewComponent } from './product-box/product-view/product-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PriceDetailsComponent } from './price-details/price-details.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { SingleProductComponent } from './product-layout/single-product/single-product.component';
import { ProductDetailsComponent } from './product-layout/product-details/product-details.component';
import { NumericOnlyDirective } from '../_shared/directive/numeric-only.directive';
import {MatSidenavModule} from '@angular/material/sidenav';
import { NoSpaceDirective } from '../_shared/directive/no-space.directive';
import { NoWhiteSpaceDirective } from '../_shared/directive/no-white-space-directive.directive';
import { FlashDealsComponent } from './flash-deals/flash-deals.component';
import { RatingComponent } from './rating/rating.component';
import { ProductRatingComponent } from './rating/product-rating/product-rating.component';
import { ProcessingComponent } from './loader/processing/processing.component';
import { ImageProcessingSearchComponent } from './header/image-processing-search/image-processing-search.component';
import { UploadFileComponent } from './header/image-processing-search/upload-file/upload-file.component';
import { ColorListComponent } from './color-list/color-list.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ImageCroperComponent } from './header/image-croper/image-croper.component';
import { NgOptimizedImage } from '@angular/common'
import { DynamicAspectRatioDirective } from '../_shared/directive/dynamic-aspect-ratio-directive .directive';
import { SharedModule } from '../shared/shared.module';

const COMPONENTS = [
  SidebarComponent,
  NavbarComponent,
  DashboardHomeComponent,
  FooterComponent,
  NoDataFoundLayoutComponent,
  ModalComponent,
  NotificationTostComponent,
  HeaderComponent,
  MainComponent,
  IoniconComponent,
  HorizontalProductViewComponent,
  VerticalProductViewComponent,
  ProfileSidebarLayoutComponent,
  CategoryVerticalLayoutComponent,
  ProductBoxComponent,
  NoInternetComponent,
  SnackbarLayoutComponent,
  DealOfTheDayComponent,
  HorizontalProductsLayoutComponent,
  NotFoundComponent,
  PriceDetailsComponent,
  SingleProductComponent,
  ProductViewComponent,
  ProductDetailsComponent,
  NumericOnlyDirective,
  NoSpaceDirective,
  NoWhiteSpaceDirective,
  RatingComponent,
  ProcessingComponent,
  ColorListComponent,
  DynamicAspectRatioDirective

]

@NgModule({
  declarations: [
    ...COMPONENTS,
    OtpVerificationComponent,
    FlashDealsComponent,
  ProductRatingComponent,
  ImageProcessingSearchComponent,
  UploadFileComponent,
  ImageCroperComponent,



  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatExpansionModule,
    MatListModule,
    MatDividerModule,
    MatSliderModule,
    MatCardModule,
    FormsModule,
    NgOtpInputModule,
    InfiniteScrollModule,
    MatDialogModule,
    NgxImageZoomModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatSidenavModule,
    ImageCropperComponent,
    NgOptimizedImage,
    SharedModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers:[
    provideImgixLoader('https://s3-resource.maniyarbangles.com'),
  ]
})
export class LayoutModule { }
