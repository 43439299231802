import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface addressRjx{
  addingData?:boolean,
  data?:any
}
@Injectable({
  providedIn: 'root'
})
export class AddressRjxService {

  private messageSource = new BehaviorSubject<addressRjx>({});
  observer = this.messageSource.asObservable();

  constructor() { }

  changeMessage(addressRjx: any) {
    this.messageSource.next(addressRjx);
  }
  
}
