import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.css']
})
export class ProcessingComponent implements OnInit {
  title: any = "PROCESSING YOUR ORDER";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}
  ngOnInit(): void {
    this.title = this.data?.title
  }
}
