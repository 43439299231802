import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {

  constructor() { }

  getProductJsonLd(postUrl: string,data:any,postImg:any,meta_description:any): any {
    // Replace with actual product data fetching or generation logic
    return {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": data.data.name,
      "image": postImg,
      "description": meta_description,
      "brand": {
        "@type": "Brand",
        "name": "Maniyar Bangles"
      },
      "offers": {
        "@type": "Offer",
        "url": `${postUrl}`,
        "priceCurrency": "INR",
        "price": data.data.base_discounted_price,
        "availability": "https://schema.org/InStock"
      }
    };
  }
}
