import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  version:any = "v1/";
  constructor(private http: HttpClient) { }

  getCateorys(sub_categories_id:any = 5): Observable<any> {
    return this.http.get<any>(environment.apiUrl+this.version+"sub-categories/"+sub_categories_id);
  }
  getMainCategories(): Observable<any> {
    return this.http.get<any>(environment.apiUrl+this.version+"categories");
  }
}
