import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { ProductService } from 'src/app/_services/product.service';
import { ProductViewComponent } from '../product-box/product-view/product-view.component';

@Component({
  selector: 'app-deal-of-the-day',
  templateUrl: './deal-of-the-day.component.html',
  styleUrls: ['./deal-of-the-day.component.css']
})
export class DealOfTheDayComponent {
  @Input("title") title: any = "";

  currency: any = currency
  toDayDealsList: any = [];

  constructor(
    private _serv: ProductService,
		public dialog: MatDialog,

  ) {

  }
  ngOnInit(): void {
    this._serv.todaysDealNg().subscribe((data) => {
      this.toDayDealsList = data.data;
    });
  }
  obj(obj: any) {
    var data = JSON.parse(obj)

    return data[0].values;
  }

  productImage(url:any){
		return ImageUrlService.getImageUrl(url,true);
	}
  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  viewProducts(data: any,permission:any) {

		const dialogRef = this.dialog.open(ProductViewComponent, {
			data: {
				data:data,
				permission:permission
			}
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}
}
