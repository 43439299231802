import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/_services/order.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.css']
})
export class ProductRatingComponent {
comment: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private route:Router,
  private dialogRef: MatDialogRef<ProductRatingComponent>,

private order:OrderService) {}

  rating: any = 0;
  setRating(arg0: number) {
    this.rating = arg0;
  }

  rateMe() {
    var dataToServer = {
      rating:this.rating,
      rateComment:this.comment,
      productId:this.data?.productIdCollection,
      orderId:this.data?.orderId
    }
    this.order.rating(dataToServer).subscribe((data:any) => {
      Swal.fire({
        icon: "success",
        title: data?.message,
        showConfirmButton: true,
        allowOutsideClick:false,
      }).then((result) => {
        if(result.isConfirmed){
          // this.route.navigate([''])
          this.dialogRef.close()
        }
      })
    })
    
  }

}
