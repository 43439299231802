import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartRjxService {

  private cartSource = new BehaviorSubject<any>(null);
  cart = this.cartSource.asObservable();

  constructor() { }

  setCartCount(cart: any) {
    this.cartSource.next(cart);
  }
}
