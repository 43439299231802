<!--
    - HEADER
  -->

    <div class="header-top ">

      <div class="container">

        <ul class="header-social-container">

          <li>
            <a href="https://www.facebook.com/barketali.maniyar/" target="_blank" class="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/maniyar__bangles/" target="_blank" class="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://www.linkedin.com/company/maniyar-bangles/"  target="_blank" class="social-link">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

        </ul>

        <div class="header-alert-news">
          <p>
            <b>Free Shopping
            This Week Order Over {{currency}} {{min_order}}</b>
          </p>
        </div>

        <div class="header-top-actions">



          <select name="language" class="d-none">

            <option value="en-US">English</option>
            <option value="hi-IN">Hindi</option>
          </select>



        </div>

      </div>

    </div>

    <div class="header-main sticky">

      <div class="container">

        <a [routerLink]="['/']" routerLinkActive="router-link-active"  class="header-logo">
          <img src="./assets/images/logo/logo.webp" alt="Maniyar Bangles" width="120" height="36">
        </a>

        <div class="header-search-container">

          <div class="input-group mt-2">
            <button class="camera-btn"  aria-label="Search Button" (click)="openDialog()">
              <mat-icon>camera_alt</mat-icon>
            </button>
            <button class="search-btn" *ngIf="searchValue.value.length<1" aria-label="Search Button">
              <mat-icon class="search-icon">search</mat-icon>
            </button>

            <button class="search-btn" *ngIf="searchValue.value.length>1" aria-label="Clear Button">
              <mat-icon class="search-icon" (click)="crossbtn()">clear</mat-icon>
            </button>


            <div class="form-floating w-100">
              <input type="search" noWhiteSpace [(ngModel)]="searchTrm" #searchValue name="search" (keyup)="searchKeyUp($event)" autocomplete="off"
               (keyup.enter)="search($event)" class="search-field"
               [ngClass]="{'border-bottom-0': isActiveAutocomplete}" placeholder="I am shopping for...">
            </div>
          </div>


          <div class="autocomplete border-top-0 c-p" *ngIf="!isSkeleton && isActiveAutocomplete">
            <div *ngFor="let item of isSkeletonData">
              <span class="skeleton ske-img" ></span>
              <div class="mt-1 p-d-s w-100">
                <p class="m-0 p-0 skeleton sket-par w-100 mr-3">  </p>
                <p class="m-0 p-0 skeleton sket-par w-100 mt-1 mr-3"></p>
              </div>

            </div>
          </div>
          <div class="autocomplete border-top-0 c-p" *ngIf="isActiveAutocomplete && isSkeleton" style="height: 300px; overflow-y: scroll;">
            <div class="autocomplete-notfound">
            <app-not-found *ngIf="searchData.length==0"></app-not-found>

            </div>
            <div *ngFor="let item of searchData" (click)="ViewProduct(item)" class="autocomplete-flex">
              <img src="/assets/images/icons/bag.svg"  height="auto" width="auto" alt="{{item?.name}}" >
              <div class="mt-1 p-d-s">
                <p class="m-0 p-0"> {{item?.name}} </p>
                <p class="m-0 p-0">{{currency}}{{item?.base_discounted_price}} <del>{{currency}}{{item?.base_price}}</del> </p>
              </div>

            </div>
          </div>

        </div>

        <div class="header-user-actions">

          <button class="action-btn" [matMenuTriggerFor]="animals">
            <!-- <mat-icon class="header-icon">person_outline</mat-icon> -->
            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#FF6666"><path d="M480-518q-50.01 0-83.01-32.99-32.99-33-32.99-83.51 0-50.51 32.99-83.01Q429.99-750 480-750q50.01 0 83.01 32.49Q596-685.01 596-634.5q0 50.51-32.99 83.51Q530.01-518 480-518ZM197-202v-62q0-26 15.09-47.38Q227.19-332.75 252-345q59-27 115.92-40.5 56.93-13.5 112-13.5Q535-399 592-385.5t115.48 40.9q25.5 11.96 40.51 33.28Q763-290 763-264v62H197Zm35-35h496v-27q0-15-9.33-28.17Q709.35-305.35 692-314q-52-25-105.19-37.5Q533.63-364 480-364t-106.81 12.5Q320-339 269-314q-18 9-27.5 21.81Q232-279.38 232-264v27Zm248-316q34 0 57.5-23.5T561-634q0-34-23.5-57.5T480-715q-34 0-57.5 23.5T399-634q0 34 23.5 57.5T480-553Zm0-81Zm0 397Z"/></svg>
          </button>
          <mat-menu #animals="matMenu" class="custom-menu" xPosition="before">
            <button mat-menu-item (click)="GotoLogin();scrollToTop()" *ngIf="!isLoginUser"><mat-icon> login</mat-icon>Login</button>
            <button mat-menu-item routerLink="/register" *ngIf="!isLoginUser" (click)="scrollToTop()"><mat-icon> how_to_reg</mat-icon>Signup</button>
            <button mat-menu-item routerLink="/account/profile/details" *ngIf="isLoginUser" (click)="scrollToTop()"><mat-icon> person_pin</mat-icon>Profile</button>
            <button mat-menu-item routerLink="/account/profile/saveforlatter" *ngIf="isLoginUser" (click)="scrollToTop()"><mat-icon> watch_later</mat-icon>Save For Latter</button>
            <button mat-menu-item routerLink="/account/profile/order/order-list/1" *ngIf="isLoginUser" (click)="scrollToTop()"><mat-icon> shopping_cart</mat-icon>My order</button>
            <button mat-menu-item (click)="openWallet()" *ngIf="isLoginUser" ><mat-icon>account_balance_wallet</mat-icon>My Wallet</button>
            <button mat-menu-item routerLink="/account/my-coupan/list" *ngIf="isLoginUser" (click)="scrollToTop()"><mat-icon> local_offer</mat-icon>My coupan</button>
            <button mat-menu-item (click)="logout()" *ngIf="isLoginUser" ><mat-icon> power_settings_new</mat-icon>Logout</button>
          </mat-menu>



          <button class="action-btn" routerLink="/account/profile/wishlist" (click)="scrollToTop()">
            <svg xmlns="http://www.w3.org/2000/svg" class="mt-1" height="38px" viewBox="0 -960 960 960" width="38px" fill="#FF6666"><path d="m480-156.08-30.54-28.84q-104.38-96.74-171.65-164.56-67.27-67.83-106.08-118.94-38.81-51.12-53.27-92.25T104-642.38q0-79.46 55.2-134.54Q214.41-832 294.38-832q51.7 0 99.66 27.81Q442-776.38 480-722q39.92-54.85 86.66-82.42Q613.39-832 665.62-832q79.97 0 135.18 55.08Q856-721.84 856-642.38q0 40.58-14.46 81.71-14.46 41.13-53.18 92.08-38.72 50.95-106.08 118.94T510.54-184.92L480-156.08Zm0-59.3q99.2-90.58 163.62-154.97 64.43-64.39 101.79-112.67 37.36-48.29 52.09-85.74 14.73-37.44 14.73-73.74 0-62.04-41.73-103.88-41.73-41.85-104.66-41.85-49.75 0-92.18 30.08-42.43 30.07-74.43 87.46h-38.69q-32.54-57.16-75.45-87.35-42.91-30.19-90.93-30.19-62.16 0-104.28 41.56-42.11 41.56-42.11 104.86 0 35.82 14.85 73.31 14.86 37.49 52.2 85.26 37.33 47.78 101.41 112.47T480-215.38Zm0-286.93Z"/></svg>
            <span class="count">{{cartCount}}</span>
          </button>

          <button class="action-btn" routerLink="/cart" (click)="scrollToTop()">
            <svg xmlns="http://www.w3.org/2000/svg" class="mt-1"  height="38px" viewBox="0 -960 960 960" width="38px" fill="#FF6666"><path d="M252.38-104q-27.78 0-48.08-20.3-20.3-20.3-20.3-48.08v-452.24q0-27.78 20.3-48.08 20.3-20.3 48.08-20.3h86.24v-24.62q0-58.84 41-98.61Q420.63-856 479.92-856t100.88 39.77q41.58 39.77 41.58 98.61V-693h85.24q27.78 0 48.08 20.3 20.3 20.3 20.3 48.08v452.24q0 27.78-20.3 48.08-20.3 20.3-48.08 20.3H252.38Zm0-43.77h455.24q9.23 0 16.92-7.69 7.69-7.69 7.69-16.92v-452.24q0-9.23-7.69-16.92-7.69-7.69-16.92-7.69h-85.24v97.61q0 9.58-6.73 15.99-6.73 6.4-15.84 6.4-8.68 0-14.94-6.4-6.25-6.41-6.25-15.99v-97.61H382.38v97.61q0 9.58-6.73 15.99-6.73 6.4-15.84 6.4-8.68 0-14.94-6.4-6.25-6.41-6.25-15.99v-97.61h-86.24q-9.23 0-16.92 7.69-7.69 7.69-7.69 16.92v452.24q0 9.23 7.69 16.92 7.69 7.69 16.92 7.69Zm130-545.23h196.24v-24.62q0-40.15-28.82-67.38-28.81-27.23-69.88-27.23-41.07 0-69.3 27.23-28.24 27.23-28.24 67.38V-693ZM227.77-147.77V-649.23-147.77Z"/></svg>
            <span class="count">{{cartCountItem}}</span>
          </button>

        </div>

      </div>

    </div>

    <nav class="desktop-navigation-menu">

      <div class="container">

        <ul class="desktop-menu-category-list">

          <li class="menu-category">
            <a routerLink="" class="menu-title">Home</a>
          </li>
          <ng-container *ngFor="let manCat of mainCategoriesList">
            <li class="menu-category">
              <a href="#" class="menu-title">{{manCat?.name}}</a>

              <div class="dropdown-panel"  style="grid-template-columns: repeat(1, 1fr) !important;">



                <ul class="dropdown-panel-list">
                  <li class="panel-list-item">
                    <app-product-box #productBox ></app-product-box>
                  </li>

                </ul>

              </div>
            </li>
          </ng-container>



          <ng-container *ngFor="let cat of categoryList">
            <li class="menu-category" >
              <span  class="menu-title c-p" *ngIf="cat?.subSubCategories.data.length>0">{{cat?.name}} </span>
              <button (click)="GotoProductPage(cat,null)" class="menu-title" *ngIf="cat?.subSubCategories.data.length==0">{{cat?.name}} </button>

              <ul class="dropdown-list" *ngIf="cat?.subSubCategories.data.length>0">

                <li class="dropdown-item" *ngFor="let subsubcat of cat?.subSubCategories.data">
                  <button (click)="GotoProductPage(cat,subsubcat)">{{ subsubcat?.name }}</button>
                </li>

              </ul>
            </li>
          </ng-container>

        </ul>

      </div>

    </nav>

    <div class="mobile-bottom-navigation">

      <button class="action-btn" data-mobile-menu-open-btn (click)="OpenMenu()">
        <ion-icon name="menu-outline"></ion-icon>
      </button>

      <button class="action-btn" routerLink="/cart" (click)="scrollToTop()">
        <ion-icon name="bag-handle-outline"></ion-icon>

        <span class="count">{{cartCountItem}}</span>
      </button>

      <button class="action-btn" [routerLink]="'/'" (click)="scrollToTop()">
        <ion-icon name="home-outline"></ion-icon>
      </button>

      <button class="action-btn" routerLink="/account/profile/wishlist" (click)="scrollToTop()">
        <ion-icon name="heart-outline"></ion-icon>

        <span class="count">{{cartCount}}</span>
      </button>

      <button class="action-btn" data-mobile-menu-open-btn (click)="OpenMenuAccount()">
        <ion-icon name="grid-outline"></ion-icon>
      </button>

    </div>

    <nav class="mobile-navigation-menu  p-0 has-scrollbar active z-index" *ngIf="!isOpenProductFilter">
      <div class="menu-top menu-t">
        <section class="filter-button-section mt-3">
          <div class="example-button-row filter-button-section-d">
            <button mat-raised-button color="primary" [disabled]="filterObject.length==0" class="w-100" (click)="apply()">Apply ({{filterObject.length}}) </button>
            <button mat-raised-button color="warn" class="w-100" (click)="filterObject.length = 0">Clear</button>
          </div>
        </section>
        <!-- <h2 class="menu-title">Product Filter</h2>

        <button class="menu-close-btn"  (click)="hideProductFilter()">
          <mat-icon>close</mat-icon>
        </button> -->
      </div>
      <div class="menu-bottom p-2">

        <ul class="menu-category-list">

          <li class="menu-category" >
            <p class="p-0 m-0 p-filter">Filter by Price</p>

            <div class="pr-3">
              <mat-slider [max]="max" [min]="min" class="w-100">
                <input matSliderStartThumb [(ngModel)]="value" #slider (change)="onSliderChange($event,'start')" />
                <input matSliderEndThumb [(ngModel)]="maxValue" #slider (change)="onSliderChange($event,'end')" />
              </mat-slider>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <input matInput type="text" appNumericOnly [(ngModel)]="value" style="width: 25%;"
                (change)="onSliderChange($event,'start')" />
              <input matInput type="text" appNumericOnly [(ngModel)]="maxValue" style="width: 25%;"
                (change)="onSliderChange($event,'start')" />
            </div>
          </li>

          <li class="menu-category mt-2">

          </li>
          <li class="menu-category mt-2 d-none">
            <div class="product-showcase mb-2">
              <mat-divider class="mt-2"></mat-divider>
              <p class="p-0 m-0 p-filter">Filter by Category</p>
              <app-category-vertical-layout [isMobile]="true" [filterByCategory]="true" (selectedCategory)="selectedCategory($event)"></app-category-vertical-layout>
            </div>

          </li>
          <li class="menu-category" >
            <div class="product-showcase mb-2" *ngIf="colors!=null">
              <h2 class="m-0">Filter by color</h2>
                <mat-divider class="m-2"></mat-divider>
                <div class="row px-2 color-list" >
                  <div class="mb-1 p-2" *ngFor="let item of colors" >
                    <div class="circle circle-dim mr-1" [style.background-color]="item" (click)="selectColor(item)" >
                      <p class="circle-check" *ngIf="colorModify(item)==selectedColor">
                        <mat-icon>check</mat-icon>
                      </p>
                    </div>
                  </div>

                </div>
            </div>
          </li>



        </ul>


      </div>
      <div class=" menu-b-t">
        <section class="filter-button-section mt-3 width-319">
          <div class="example-button-row filter-button-section-d">
            <button mat-raised-button color="warn" class="w-100" (click)="filterObject.length = 0; hideProductFilter()">Close</button>
          </div>
        </section>
        <!-- <h2 class="menu-title">Product Filter</h2>

        <button class="menu-close-btn"  (click)="hideProductFilter()">
          <mat-icon>close</mat-icon>
        </button> -->
      </div>

    </nav>
    <nav class="mobile-navigation-menu  has-scrollbar active z-index" *ngIf="!openSideMenuForMobileVersionAccount">
      <div class="menu-top">
        <h2 class="menu-title">Account</h2>

        <button class="menu-close-btn"  (click)="openSideMenuForMobileVersionAccount=true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="menu-bottom">

        <ul class="menu-category-list">

          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true">
            <button class="accordion-menu" data-accordion-btn routerLink="/register" *ngIf="!isLoginUser">
              <p class="menu-title">Signup</p>
            </button>
          </li>

          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true">
            <button class="accordion-menu" (click)="GotoLogin()" *ngIf="!isLoginUser" data-accordion-btn>
              <p class="menu-title">Login</p>
              <mat-icon> login</mat-icon>
            </button>
          </li>
          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true; scrollToTop()">
            <button class="accordion-menu" data-accordion-btn routerLink="/account/profile/details" *ngIf="isLoginUser">
              <p class="menu-title">Profile</p>
              <mat-icon> person_pin</mat-icon>
            </button>
          </li>

          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true; scrollToTop()">
            <button class="accordion-menu" routerLink="/account/profile/order/order-list/1" *ngIf="isLoginUser" data-accordion-btn>
              <p class="menu-title">My Order</p>
              <mat-icon>shopping_cart</mat-icon>
            </button>
          </li>
          <li class="menu-category" (click)="openWallet()">
            <button class="accordion-menu" (click)="openWallet()" *ngIf="isLoginUser" data-accordion-btn>
              <p class="menu-title">My Wallet</p>
              <mat-icon>account_balance_wallet</mat-icon>
            </button>
          </li>
          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true; scrollToTop()">
            <button class="accordion-menu" routerLink="/account/my-coupan/list" *ngIf="isLoginUser" data-accordion-btn>
              <p class="menu-title">My coupan</p>
              <mat-icon>local_offer</mat-icon>
            </button>
          </li>
          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true; scrollToTop()">
            <button class="accordion-menu" routerLink="/account/profile/saveforlatter" *ngIf="isLoginUser" data-accordion-btn>
              <p class="menu-title">Save For Latter</p>
              <mat-icon>watch_later</mat-icon>
            </button>
          </li>
          <li class="menu-category" (click)="openSideMenuForMobileVersionAccount=true">
            <button class="accordion-menu" (click)="logout()" *ngIf="isLoginUser" data-accordion-btn>
              <p class="menu-title">Logout</p>
              <mat-icon> power_settings_new</mat-icon>
            </button>
          </li>

        </ul>

        <ul class="menu-social-container">

          <li>
            <a href="https://www.facebook.com/barketali.maniyar/" class="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>


          <li>
            <a href="https://www.instagram.com/maniyar__bangles/" class="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://www.linkedin.com/company/maniyar-bangles/" class="social-link">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

        </ul>

      </div>
    </nav>
    <nav class="mobile-navigation-menu  has-scrollbar active z-index" *ngIf="!openSideMenuForMobileVersion">

      <div class="menu-top">
        <h2 class="menu-title">Menu</h2>

        <button class="menu-close-btn"  (click)="OpenMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <app-category-vertical-layout [isMobile]="true"></app-category-vertical-layout>


      <div class="menu-bottom">

        <ul class="menu-category-list">

          <li class="menu-category">

            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title">Language</p>

              En
            </button>

          </li>

          <li class="menu-category">
            <button class="accordion-menu" data-accordion-btn>
              <p class="menu-title">Default Currency</p>
              {{currency}}
            </button>
          </li>

        </ul>

        <ul class="menu-social-container">

          <li>
            <a href="https://www.facebook.com/barketali.maniyar/" class="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/maniyar__bangles/" class="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="https://www.linkedin.com/company/maniyar-bangles/" class="social-link">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

        </ul>

      </div>

    </nav>
