<!-- <div class="mobile image-layout-p">
  <img [src]="product.image" width="200" >
</div> -->
<div class="product-container mt-4 ">
  
  <div class="product-box" >
    
  
  <div class="container">
    <!--
        - SIDEBAR
      -->

    <div class="sidebar has-scrollbar" data-mobile-menu>
      <div class="image-layout w-100" style="width: 200px; height: 200px;">
        <img [src]="product.image" >
        
      </div>
      <div>
        <p>Total product</p>
      </div>
    </div>

    <div class="product-box" >
      <!--
        - PRODUCT GRID
      -->
      <div class="product-main">
        
        <div class="product-grid">
          <div class="showcase" *ngFor="let data of sort(product.data?.data);index as i">
            <div class="showcase-banner">
              <img [src]="productImage(data?.thumbnail_image)" alt="{{data.name}}" width="300" height="auto"
                (error)="setDefaultImage($event)" class="product-img default" />
              <img [src]="productImage(data?.thumbnail_image)" alt="{{data.name}}" width="300" height="auto"
                (error)="setDefaultImage($event)" class="product-img hover" />

              <p class="showcase-badge"><span *ngIf="data.discount_type=='amount'">{{currency}}</span> {{data.discount}}
                Off</p>

              <div class="showcase-actions">
                <button class="btn-action" (click)="removeWishList(data)" *ngIf="alreadyWishlist(data)">
                  <mat-icon class="heart-icon">favorite</mat-icon>
                </button>
                <button class="btn-action" aria-label="favorite"  (click)="addOnWishlist(data)" *ngIf="!alreadyWishlist(data)">
                  <mat-icon>favorite_border</mat-icon>
                </button>

                <button class="btn-action" (click)="viewProducts(data,'view')">
                  <ion-icon name="eye-outline"></ion-icon>
                </button>

                <button class="btn-action">
                  <ion-icon name="repeat-outline"></ion-icon>
                </button>

                <button class="btn-action" (click)="viewProducts(data,'dml')">
                  <ion-icon name="bag-add-outline"></ion-icon>
                </button>
              </div>

            </div>
            <!--  -->
            <div class="showcase-content">
              <div>

                <a [routerLink]="['/products/'+data?.slug]" routerLinkActive="router-link-active" (click)="scrollToTop()" class="showcase-category  c-p min-height p-desc-p">{{data.name}}
                </a>

                <!-- <a routerLink="/products">
                <h3 class="showcase-title">Mens Winter Leathers Jackets</h3>
              </a> -->

                <app-rating [rating]="data?.rating" [maxRating]="5"></app-rating>
              </div>

              <div class="price-box">
                <p class="price ">{{currency}}{{data.base_discounted_price}}</p>

                <del>{{currency}}{{data.base_price}}</del>
              </div>

            </div>
            <div class="p-footer">
              <div class="mattch">
                <p class="showcase-category text-success">{{data?.similarity_scores?.color_similarity}}% Matching</p>
                <img src="/assets/images/icons/color.png" width="24px" height="24px" (click)="colorList(data?.similarity_scores.matching_colors)">
              </div>
              <div>
                <!-- <p class="badge-modify">{{data?.similarity_scores?.color_similarity}}%</p> -->
              </div>
            </div>
            <!-- <p class="showcase-badge badge-modify">{{data?.similarity_scores?.color_similarity}}%</p> -->

          </div>
        </div>
      </div>
    </div>

  </div>
</div>