import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { currency } from 'src/app/_services/constant';
import { ImageUrlService } from 'src/app/_services/image-url.service';
import { OrderService } from 'src/app/_services/order.service';
import { ProductRatingComponent } from 'src/app/layout/rating/product-rating/product-rating.component';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit{
  currency:any = currency
  orderId: string | null | undefined;
  orderDetails: any = {};
  order_details:any = []
  shipping_address:any = []
  totalPrice: number = 0;
  tax: number = 0;
  shipping_cost: number = 0;
  constructor(
    private route: ActivatedRoute,
    private order:OrderService,
    private _router: Router,
  ){

  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.orderId = params.get('orderId');
    });
    this.getOrderSummary()
  }
  
  getOrderSummary(){
    this.order.orderSummary(this.orderId).subscribe((data:any) => {
      this.orderDetails = data
      this.order_details = this.orderDetails.order_details
      this.shipping_address = JSON.parse(data?.shipping_address)
      const qty = 1;//current.quantity;
      this.totalPrice = this.order_details.reduce((acc: any, current: any) => acc + (current.price * qty), 0);
      this.tax = this.order_details.reduce((acc: any, current: any) => acc + current.tax, 0);
      this.shipping_cost = this.order_details.reduce((acc: any, current: any) => acc + current.shipping_cost, 0);
    })
  }


  timestampToDate(timestamp: number) {
    if(timestamp==null || undefined) return "";
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.getDate()+"-"+date.getMonth()+"-"+date.getFullYear();
  }
  
  gotoOrderHistory(){
    this._router.navigate(['/account/profile/order/order-list/1'])
  }

  getImage(img:any){
    return ImageUrlService.getImageUrl(img);
  }
  
}
