<h1 mat-dialog-title class="title">Thank you for your order! We're thrilled to hear that your delivery was successful.</h1>
<div mat-dialog-content>
    <div id="full-stars-example">

        <div id="full-stars-example-two">
            <div class="rating-group">
                <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none"
                    value="0" type="radio" >
                <label aria-label="1 star" class="rating__label" for="rating3-1" (click)="setRating(1)"><i
                        class="rating__icon rating__icon--star fa fa-star"></i></label>

                <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio">
                <label aria-label="2 stars" class="rating__label" for="rating3-2" (click)="setRating(2)"><i
                        class="rating__icon rating__icon--star fa fa-star"></i></label>

                <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio">
                <label aria-label="3 stars" class="rating__label" for="rating3-3" (click)="setRating(3)"><i
                        class="rating__icon rating__icon--star fa fa-star"></i></label>

                <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio">
                <label aria-label="4 stars" class="rating__label" for="rating3-4" (click)="setRating(4)"><i
                        class="rating__icon rating__icon--star fa fa-star"></i></label>
                        
                <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio">
                <label aria-label="5 stars" class="rating__label" for="rating3-5" (click)="setRating(5)"><i
                        class="rating__icon rating__icon--star fa fa-star"></i></label>
                <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio">
            </div>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Leave a comment (Optional)</mat-label>
                <textarea matInput placeholder="Ex. It makes me feel..." [(ngModel)]="comment"></textarea>
              </mat-form-field>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="primary" [disabled]="rating==0" (click)="rateMe()" >Give me rating </button>
    </mat-dialog-actions>