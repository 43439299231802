import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noSpace]'
})
export class NoSpaceDirective {

  // @HostListener('keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent): void {
  //   // Prevent the space key (key code 32) from having any effect
  //   if (event.keyCode === 32) {
  //     event.preventDefault();
  //   }
  // }
  timeout: any = null;
  constructor(private ngControl: NgControl) {
   }
  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement) {
    // Get the current input value
    let value = target.value;
    value = value.replace(/\s+/g, ' ');

    clearTimeout(this.timeout);
    // var data = event.target.value;
    var $this = this;
    if (value.length > 0) {
      this.timeout = setTimeout(function () {
        value = value.trim()
        target.value = value
      }, 1000);
    }
    // Update the input value
    this.ngControl.control!.setValue(value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    // //console.log(this.ngControl.control!.value);
    const inputValue = this.ngControl.control!.value;
    const selectionStart = event.target['selectionStart'];

    // If space is pressed and the cursor is at the beginning of the input, prevent default action
    if (event.key === ' ' && selectionStart === 0) {
      event.preventDefault();
    }
  }

}
