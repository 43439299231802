
export class Utils {
  static readonly APP_NAME: string = "Maniyar Bangles"
  static readonly NO_IMAGE: string = "/assets/images/icons/no-image.svg"
  static BASE_URL: string = "https://www.maniyarbangles.com";


  static slug_modify(text: any) {
    let replacedStr = text.replace(/ /g, "-");
    replacedStr = replacedStr.toLowerCase();
    return replacedStr;
  }
  static generateRandomNumber(max: any) {
    let randomNumber = Math.random();
    randomNumber *= Math.pow(10, max);
    randomNumber = Math.floor(randomNumber);
    let randomString = randomNumber.toString();
    while (randomString.length < max) {
      randomString = '0' + randomString;
    }
    return randomString;
  }
  static maskEmail(email: any) {
    const [localPart, domainPart] = email.split('@');
    const maskedLocalPart = localPart.charAt(0) + '*'.repeat(localPart.length - 2) + localPart.charAt(localPart.length - 1);
    const maskedDomainPart = domainPart.charAt(0) + '*'.repeat(domainPart.length - 2) + domainPart.charAt(domainPart.length - 1);
    return maskedLocalPart + '@' + maskedDomainPart;
  }
  static AppName(): string {
    return "Maniyar Bangles";
  }

  static getSubdomain() {
    if (typeof window !== 'undefined') {
      var hostname = window.location.hostname;
      var parts = hostname.split('.');
      if (parts.length > 2) {
        var subdomain = parts.slice(0, parts.length - 2).join('.');
        return subdomain;
      } else {
        return null; // No subdomain present
      }
    }
    return null;
  }
  static isLive() {
    if (this.isLocalhost()) return false;
    if (this.getSubdomain() == 'www' || this.getSubdomain() == null) {
      return true;
    }
    return false;
  }
  static isLocalhost() {
    if (typeof window !== 'undefined') {
      var hostname = window.location.hostname;
      return hostname === 'localhost';
    }
    return false;

  }
  static rzpKey() {
    if (this.isLive()) {
      return "rzp_live_QhHQwSrrpAG81l";
    } else {
      return "rzp_test_WoHaROjSWweiin";
    }
  }

  static timeAgo(timestamp: number): string {
    const now = new Date().getTime();
    const timeDifference = now - new Date(timestamp).getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
        return years + (years === 1 ? " year" : " years");
    }
    if (months > 0) {
        return months + (months === 1 ? " month" : " months");
    }
    if (weeks > 0) {
        return weeks + (weeks === 1 ? " week" : " weeks");
    }
    if (days > 0) {
        return days + (days === 1 ? " day" : " days");
    }
    if (hours > 0) {
        return hours + (hours === 1 ? " hour" : " hours");
    }
    if (minutes > 0) {
        return minutes + (minutes === 1 ? " minute" : " minutes");
    }
    return seconds + (seconds === 1 ? " second" : " seconds");
}
}
