<div class="container">
  <div class="row border my-3  justify-content-end">
    <div class="cancel center w-100" *ngIf="orderDetails?.payment_status=='cancel'">
      <svg id="SvgjsSvg1042" width="360" height="360" xmlns="http://www.w3.org/2000/svg" version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
        <defs id="SvgjsDefs1043"></defs>
        <g id="SvgjsG1044" transform="matrix(1,0,0,1,0,0)"><svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1080 1080" width="360" height="360">
            <path fill="#f5eded"
              d="M714.698 830.226c-109.35 89.563-139.625 25.908-259.431-56.666-50.121-34.545-163.139-46.296-246.993-94.2C34.198 579.915 99.58 395.603 254.677 268.571s383.807-104.28 510.838 50.817 104.28 383.807-50.817 510.838zM1041.302 1031H57.548c-5.334 0-9.697-4.364-9.697-9.698v-27.104c0-5.334 4.364-9.698 9.697-9.698h983.754c5.334 0 9.698 4.364 9.698 9.698v27.104c0 5.334-4.364 9.698-9.698 9.698z"
              class="colorF7F7F7 svgShape"></path>
            <path fill="#d81a1a"
              d="M896.19 909.349c6.533-16.61 13.121-35.069 7-51.835-1.487-4.073-3.727-8.385-2.385-12.508 1.813-5.567 9.138-7.555 11.617-12.86 2.726-5.832-1.546-12.706-.437-19.047 1.128-6.447 7.436-10.626 13.554-12.952 6.118-2.326 12.844-3.73 17.776-8.032 9.486-8.276 8.345-23.092 6.257-35.507-2.088-12.415-3.648-27.066 5.426-35.792-16.693 3.191-33.792 6.535-48.309 15.373s-26.135 24.478-25.281 41.452c.428 8.515 3.798 17.595-.1 25.178-4.209 8.187-15.537 11.369-18.395 20.12-3.284 10.056 6.519 19.162 10.39 29.007 3.635 9.247 1.914 19.618 2.729 29.52s6.009 21.103 15.833 22.583l4.325 5.3z"
              class="color1A87D8 svgShape"></path>
            <path fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"
              d="M889.426 887.514c-.161-48.31 9.152-149.414 64.969-166.29" class="colorStrokeFFF svgStroke"></path>
            <path fill="#f42a2a"
              d="M857.278 905.3c-9.935-18.549-20.189-39.256-15.33-59.729 1.18-4.974 3.21-10.331 1.071-14.973-2.889-6.269-11.74-7.589-15.372-13.458-3.994-6.454.061-15.089-2.11-22.362-2.207-7.394-10.169-11.418-17.652-13.3s-15.552-2.6-21.919-6.959c-12.246-8.384-12.949-25.889-12.213-40.713.737-14.823.546-32.193-11.279-41.162 19.985 1.439 40.466 3.001 58.679 11.352s33.97 25.064 35.306 45.056c.67 10.029-2.026 21.124 3.583 29.466 6.055 9.007 19.756 11.173 24.307 21.026 5.229 11.322-4.996 23.333-8.173 35.393-2.984 11.327.459 23.233.867 34.94.408 11.706-4.131 25.536-15.431 28.621l-4.334 6.802z"
              class="color2A94F4 svgShape"></path>
            <path fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"
              d="M862.194 878.803c-6.46-56.587-31.279-173.686-98.957-185.763" class="colorStrokeFFF svgStroke"></path>
            <path fill="#ffcccc"
              d="M920.03 1014.113h-87.247c-6.061 0-11.021-4.959-11.021-11.021l-7.347-125.819c0-6.061 4.959-11.021 11.021-11.021h101.941c6.061 0 11.021 4.959 11.021 11.021l-7.347 125.819c-.001 6.062-4.96 11.021-11.021 11.021z"
              class="colorCCE9FF svgShape"></path>
            <path fill="#d81a1a"
              d="M151.557 942.706c-4.453-11.321-8.943-23.903-4.771-35.331 1.013-2.776 2.541-5.715 1.625-8.525-1.236-3.795-6.229-5.15-7.918-8.765-1.858-3.975 1.054-8.66.298-12.983-.769-4.394-5.068-7.242-9.238-8.828-4.17-1.586-8.755-2.542-12.116-5.475-6.466-5.641-5.688-15.74-4.265-24.201 1.423-8.462 2.486-18.448-3.698-24.396 11.378 2.175 23.033 4.454 32.927 10.479 9.894 6.024 17.814 16.684 17.231 28.254-.292 5.804-2.589 11.993.068 17.161 2.869 5.58 10.59 7.749 12.538 13.714 2.238 6.854-4.444 13.061-7.082 19.771-2.478 6.302-1.304 13.372-1.86 20.121-.556 6.749-4.095 14.384-10.792 15.392l-2.947 3.612z"
              class="color1A87D8 svgShape"></path>
            <path fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"
              d="M156.168 927.823c.11-32.928-6.238-101.841-44.283-113.343" class="colorStrokeFFF svgStroke"></path>
            <path fill="#f42a2a"
              d="M178.08 939.946c6.772-12.643 13.761-26.757 10.449-40.712-.805-3.39-2.188-7.042-.73-10.206 1.969-4.273 8.002-5.172 10.478-9.173 2.722-4.399-.042-10.285 1.438-15.242 1.505-5.04 6.931-7.782 12.032-9.065 5.101-1.283 10.6-1.772 14.94-4.743 8.347-5.715 8.826-17.646 8.324-27.75-.502-10.104-.372-21.943 7.688-28.056-13.621.981-27.582 2.045-39.996 7.738-12.414 5.692-23.154 17.084-24.064 30.71-.457 6.836 1.381 14.398-2.442 20.084-4.127 6.139-13.466 7.616-16.568 14.331-3.564 7.717 3.405 15.904 5.571 24.124 2.034 7.72-.313 15.836-.591 23.815-.278 7.979 2.816 17.405 10.518 19.508l2.953 4.637z"
              class="color2A94F4 svgShape"></path>
            <path fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"
              d="M174.729 921.886c4.403-38.57 21.32-118.384 67.449-126.616" class="colorStrokeFFF svgStroke"></path>
            <path fill="#ffcccc"
              d="M135.308 1014.113h59.467c4.131 0 7.512-3.38 7.512-7.512l5.008-85.758c0-4.131-3.38-7.512-7.512-7.512H130.3c-4.131 0-7.512 3.38-7.512 7.512l5.008 85.758c.001 4.132 3.381 7.512 7.512 7.512z"
              class="colorCCE9FF svgShape"></path>
            <path fill="none" stroke="#ffcccc" stroke-miterlimit="10" stroke-width="2"
              d="M918.37 225.88v422.05c0 9.31-7.61 16.93-16.92 16.93H230.73c-9.31 0-16.93-7.62-16.93-16.93V225.88c0-9.31 7.62-16.92 16.93-16.92h670.72c9.31 0 16.92 7.61 16.92 16.92z"
              class="colorStrokeCCE9FF svgStroke"></path>
            <path fill="#fff3f3"
              d="M940.37 203.88v422.05c0 5.08-2.27 9.66-5.84 12.76-.53.48-1.1.91-1.69 1.31a16.784 16.784 0 0 1-9.39 2.86H252.73c-3.46 0-6.7-1.05-9.39-2.86-4.54-3.05-7.54-8.23-7.54-14.07V203.88c0-9.31 7.62-16.92 16.93-16.92h670.72c9.31 0 16.92 7.61 16.92 16.92z"
              class="colorF3FAFF svgShape"></path>
            <circle cx="575.715" cy="420.715" r="131.715" fill="#ffcccc" class="colorCCE9FF svgShape"></circle>
            <circle cx="587.285" cy="432.285" r="131.715" fill="none" stroke="#ffcccc" stroke-miterlimit="10"
              class="colorStrokeCCE9FF svgStroke"></circle>
            <path fill="#ffcaca"
              d="m495.491 495.214 2.303-5.999s10.278-7.373 11.228-10.389c-.81-.587-6.172 1.703-7.569 1.927 1.264-3.148 4.362-9.983 6.525-12.883.629-.843.089-2.055-.962-2.091-2.853-.097-7.738 1.2-11.176 2.266-5.502 5.893-6.145 15.305-4.302 17.874-1.538 4.08-4.032 8.603-4.032 8.603l7.985.692z"
              class="colorFFE3CA svgShape"></path>
            <path fill="#ff5555" d="m489.5 488.5 8 6L479 539l-8.528-15.046z" class="colorFFBE55 svgShape"></path>
            <path fill="#d72323"
              d="m554.031 488.243-12.444 10.491a15.438 15.438 0 0 0 2.489-2.647l12.444-10.491a15.438 15.438 0 0 1-2.489 2.647z"
              class="colorFCAD00 svgShape"></path>
            <path fill="#ffc2c2" d="m579.804 447.464 12.444-10.49-35.728 48.622-12.444 10.49z"
              class="colorFFEBC2 svgShape"></path>
            <path fill="#ff5555"
              d="M528.83 325.933a27.308 27.308 0 0 0-2.181-.142l-1.52-.034c-6.094-.145-11.11 1.664-14.565 4.577l-12.444 10.491c3.455-2.913 8.471-4.722 14.565-4.577l1.52.034c.73.018 1.457.066 2.181.142 6.502.683 12.648 3.664 16.643 8.169l41.976 47.235 12.444-10.491-41.976-47.235c-3.994-4.506-10.14-7.486-16.643-8.169zM673.091 469.341l-55.972-59.889 41.274-53.727c8.178-10.645-2.174-25.438-18.128-27.114-.757-.08-1.527-.13-2.329-.151-5.729-.138-10.968 1.569-14.622 4.649L610.87 343.6c3.654-3.08 8.893-4.786 14.622-4.649a29.33 29.33 0 0 1 2.329.151c15.953 1.677 26.306 16.47 18.128 27.114l-41.274 53.727 55.972 59.89c7.013 7.507 5.903 16.5-.403 21.816l12.444-10.491c6.305-5.317 7.416-14.31.403-21.817z"
              opacity=".55" class="colorFFBE55 svgShape"></path>
            <path fill="#ff5555"
              d="M627.82 339.1c15.953 1.677 26.306 16.47 18.128 27.114l-41.274 53.727 55.972 59.889c10.857 11.621 2.245 26.802-14.969 26.393l-1.578-.04a27.095 27.095 0 0 1-2.191-.142c-6.442-.677-12.536-3.614-16.54-8.048l-45.564-50.53-35.728 48.622c-3.472 4.723-9.896 7.462-17.105 7.29a29.017 29.017 0 0 1-2.307-.148c-15.961-1.678-26.313-16.477-18.12-27.128l43.87-56.98-52.703-56.495c-10.842-11.619-2.231-26.786 14.976-26.377l1.52.034c.73.018 1.457.066 2.181.142 6.502.683 12.648 3.664 16.643 8.169l41.976 47.235 33.357-45.561c3.472-4.738 9.911-7.49 17.127-7.317a30.34 30.34 0 0 1 2.329.151zM420.5 715.5c-8 19-69 187-50 259h23s63-199 63-203c0 22 8 184 14 203 12 2 29 0 29 0s9-223 3-260c-32-4-82 1-82 1z"
              class="colorFFBE55 svgShape"></path>
            <path fill="#ff5555"
              d="m500.932 717.376-81.98 4.429c-2.838.153-4.198-3.429-1.974-5.198 1.196-.951 1.902-2.372 2.007-3.897.133-1.93.777-3.428 2.896-3.543l78.378-4.234c2.616-.141 2.482 1.665 2.543 4.145.013.541.241 1.058.633 1.431 1.427 1.359 1.811 3.597.68 5.211-.672.961-1.729 1.578-3.183 1.656z"
              class="colorFFBE55 svgShape"></path>
            <path fill="#000000"
              d="m500.932 717.376-81.98 4.429c-2.838.153-4.198-3.429-1.974-5.198 1.196-.951 1.902-2.372 2.007-3.897.133-1.93.777-3.428 2.896-3.543l78.378-4.234c2.616-.141 2.482 1.665 2.543 4.145.013.541.241 1.058.633 1.431 1.427 1.359 1.811 3.597.68 5.211-.672.961-1.729 1.578-3.183 1.656z"
              opacity=".09" class="color070707 svgShape"></path>
            <path fill="#ff5555"
              d="M487.319 572.354 539.5 494.5l10 6L490 610s6.959 31.94 8.5 43.5c1.101 8.256 5.837 15.603 9.873 22.208 4.824 7.895 4.635 17.882-.509 25.572-3.265 4.882-6.364 9.22-6.364 9.22-11 1-72 9-84 0s-3-19-3-19 14.295-23.608 13.147-41.304C426.5 632.5 423.5 584.5 423.5 584.5l63.819-12.146z"
              class="colorFFBE55 svgShape"></path>
            <path fill="#ffcaca"
              d="m541.227 495.536 3.273-5.036s-4-12-3-15c1 0 4 5 5 6 .823-3.291 2.323-10.644 2.271-14.261-.015-1.052 1.133-1.717 2.006-1.129 2.367 1.595 5.563 5.511 7.723 8.39 1 8-4 16-7 17-3 4-3.4 8.16-3.4 8.16l-6.873-4.124zM373.46 974.5l.04 9h14v-9zM476.46 974.5l.04 9h14v-9z"
              class="colorFFE3CA svgShape"></path>
            <path fill="#3e3636"
              d="M496.5 1004.5c0 .81-1.81 1.54-4.51 2.09-7.35 1.48-21.3 1.56-23.49-2.09-1.37-2.28.17-7.44 2.25-12.36 2.47-5.86 5.71-11.4 5.71-11.4h14.04c3 6.76 6 20.76 6 23.76zM395.5 1004.5c0 .93-2.7 1.74-6.51 2.27-8.46 1.17-22.42.94-24.49-2.51-1.29-2.15 0-6.83 1.89-11.47 2.5-6.17 6.07-12.29 6.07-12.29h14.04c3 6.76 9 21 9 24z"
              class="color0E538C svgShape"></path>
            <path fill="#a31414"
              d="M388.99 1006.77c-8.46 1.17-22.42.94-24.49-2.51-1.29-2.15 0-6.83 1.89-11.47 2.13-1.15 4.55-1.79 7.11-1.79 8.48 0 15.38 7.04 15.49 15.77zM491.99 1006.59c-7.35 1.48-21.3 1.56-23.49-2.09-1.37-2.28.17-7.44 2.25-12.36 1.78-.74 3.72-1.14 5.75-1.14 8.43 0 15.28 6.94 15.49 15.59z"
              class="color1469A3 svgShape"></path>
            <path fill="#ffcaca"
              d="M482 539.5c0 11.375-1.355 19.385-10.5 19.385s-21-8.414-21-19.789 7.413-20.596 16.558-20.596S482 528.125 482 539.5z"
              class="colorFFE3CA svgShape"></path>
            <path fill="#3e3636"
              d="M468.5 526.5c11-2 27-20 12-27s-43-9-55 10 3 34 5 44S424 571 412 581s-29.5 49.5.5 63.5 78 3 81-42-37-51-37-55 12-21 12-21z"
              class="color0E538C svgShape"></path>
            <ellipse cx="460.572" cy="534.78" fill="#ffcaca" rx="5.296" ry="7.061"
              transform="rotate(-15.961 460.528 534.735)" class="colorFFE3CA svgShape"></ellipse>
            <path fill="#ff5555"
              d="M940.37 203.88V225H235.8v-21.12c0-9.31 7.62-16.92 16.93-16.92h670.72c9.31 0 16.92 7.61 16.92 16.92zM483.538 275.562H269.462c-4.104 0-7.462-3.358-7.462-7.462v-4.638c0-4.104 3.358-7.462 7.462-7.462h214.077c4.104 0 7.462 3.358 7.462 7.462v4.638c-.001 4.104-3.359 7.462-7.463 7.462zM423.609 307H268.391c-3.515 0-6.391-2.876-6.391-6.391v-6.779c0-3.515 2.876-6.391 6.391-6.391h155.218c3.515 0 6.391 2.876 6.391 6.391v6.779c0 3.515-2.876 6.391-6.391 6.391zM440.293 338H268.707c-3.689 0-6.707-3.018-6.707-6.707v-6.148c0-3.689 3.018-6.707 6.707-6.707h171.587c3.689 0 6.707 3.018 6.707 6.707v6.148c-.001 3.689-3.019 6.707-6.708 6.707zM914 625H804c-6.6 0-12-5.4-12-12v-95c0-6.6 5.4-12 12-12h110c6.6 0 12 5.4 12 12v95c0 6.6-5.4 12-12 12z"
              class="colorFFBE55 svgShape"></path>
            <path fill="#ffffff"
              d="M483.538 275.562H269.462c-4.104 0-7.462-3.358-7.462-7.462v-4.638c0-4.104 3.358-7.462 7.462-7.462h214.077c4.104 0 7.462 3.358 7.462 7.462v4.638c-.001 4.104-3.359 7.462-7.463 7.462zM423.609 307H268.391c-3.515 0-6.391-2.876-6.391-6.391v-6.779c0-3.515 2.876-6.391 6.391-6.391h155.218c3.515 0 6.391 2.876 6.391 6.391v6.779c0 3.515-2.876 6.391-6.391 6.391zM440.293 338H268.707c-3.689 0-6.707-3.018-6.707-6.707v-6.148c0-3.689 3.018-6.707 6.707-6.707h171.587c3.689 0 6.707 3.018 6.707 6.707v6.148c-.001 3.689-3.019 6.707-6.708 6.707zM914 625H804c-6.6 0-12-5.4-12-12v-95c0-6.6 5.4-12 12-12h110c6.6 0 12 5.4 12 12v95c0 6.6-5.4 12-12 12z"
              opacity=".2" class="colorFFF svgShape"></path>
          </svg></g>
      </svg>
    </div>
    <div *ngIf="orderDetails?.payment_status!='cancel'">
      <img src="/assets/images/icons/paid_bill.gif" *ngIf="orderDetails?.payment_status=='paid'" width="100"
        height="auto">
      <img src="/assets/images/icons/unpaid.gif" *ngIf="orderDetails?.payment_status!='paid'" width="100" height="auto">
    </div>
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-1 text-center title">
      <div>
        <h1>Thank You for Your Order</h1>
        <h3>Order Code: {{orderDetails?.code}}</h3>
        <small>Acopy of your order summary has been sent: {{orderDetails?.user?.email}}</small>
      </div>
    </div>
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div>
        <h2>Order Summary</h2>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div>
        <h4><b>Order Code</b>: {{orderDetails?.code}}</h4>
        <h4><b>Name</b>: {{shipping_address?.name}}</h4>
        <h4><b>Email</b>: {{orderDetails?.user?.email}}</h4>
        <h4><b>Shipping address:</b> {{shipping_address?.address}} , {{shipping_address?.postal_code}} ,
          {{shipping_address?.city}} - {{shipping_address?.country}} </h4>
      </div>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div>
        <h4><b>Order date:</b> {{timestampToDate(orderDetails?.date)}}</h4>
        <h4><b>Order status:</b> {{order_details[0]?.delivery_status}}</h4>
        <h4><b>Total order amount:</b> {{orderDetails?.grand_total}}</h4>
        <!-- <h4>Shipping:</h4> -->
        <h4><b>Payment method:</b> {{orderDetails?.payment_type}}</h4>
        <h4><b>Payment status:</b> {{orderDetails?.payment_status}}</h4>
      </div>
    </div>

    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div>
        <h2>Order Details</h2>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">

      <div class="card mt-4">
        <div class="card-body p-0 table-responsive">
          <table class="table mb-0">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col"></th>
                <th scope="col">Variation</th>
                <th scope="col">Quantity</th>
                <th scope="col">Delivery Type</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of order_details">

                <th>
                  <img [src]="getImage(item?.product?.thumbnail_img)" alt="product" class="" width="80">
                </th>
                <td>{{item?.product?.name || 'This products no more'}}</td>
                <td>{{item?.variation}}</td>
                <td>{{item?.quantity}}</td>
                <td><span class="badge badge-warning">{{item?.shipping_type}}</span></td>
                <td><strong>{{currency}} {{item?.price}}</strong></td>
              </tr>
              <tr>
                <th colspan="6">
                  <span>Status:</span>
                  <span class="badge badge-success">{{orderDetails?.payment_status}}</span>
                </th>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="my-3 d-none">
        <table width="100%" class="table table-borderless">
          <thead>
            <tr>
              <th>Product</th>
              <th>Variation</th>
              <th>Quantity</th>
              <th>Delivery Type</th>
              <th>Price</th>
            </tr>
          </thead>
          <tr>
            <td colspan="5">
              <mat-divider></mat-divider>
            </td>

          </tr>
          <tr *ngFor="let item of order_details">
            <td>{{item?.product?.name || 'This products no more'}}</td>
            <td>{{item?.variation}}</td>
            <td>{{item?.quantity}}</td>
            <td>{{item?.shipping_type}}</td>
            <td>{{currency}} {{item?.price}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="my-3 ">
        <mat-card>
          <mat-card-content>
            <div class="title">
              <div class="d-flex justify-content-between">
                <span>
                  <h4>Subtotal</h4>
                </span>
                <span>
                  <h4>{{currency}} {{totalPrice}}</h4>
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <span>
                  <h4>Shipping cash_on_delivery</h4>
                </span>
                <span>
                  <h4 class="text-success">{{currency}} {{shipping_cost}}</h4>
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <span>
                  <h4>Tax</h4>
                </span>
                <span>
                  <h4 class="text-success">{{currency}} {{tax}}</h4>
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <span>
                  <h4>Coupan discount</h4>
                </span>
                <span>
                  <h4 class="text-success">{{currency}} {{orderDetails?.coupon_discount}}</h4>
                </span>
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-between">
                <span>
                  <h2>Total Amount</h2>
                </span>
                <span>
                  <h2>{{currency}} {{orderDetails?.grand_total}}</h2>
                </span>
              </div>
              <button (click)="gotoOrderHistory()" class=" btn btn-success w-100">Continue</button>

            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>